import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import React from 'react';
import {useNavigate} from "react-router-dom";
import {useImmer} from "use-immer";
import {
    HoverCard,
    createStyles,
    Text,
    Box, Divider, Stack,
    Group, useMantineTheme, Indicator, Select, Button, LoadingOverlay, Table,
} from "@mantine/core";
import AppContext from "../../config/AppContext";
import {humanizeSeconds} from "../../config/method";
import {useAssets} from "../../hooks/RustyRadeaHooks";

import {useLocations} from "../../hooks/useLocations";
import ENDPOINTS from '../../config/endpoint';

import floorplan_img from '../../assets/hospital_floor.avif';

const useStyles = createStyles(theme => ({
    headerWrapper: {
        border: 'thin solid',
        borderColor: theme.colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.13)' : 'rgba(0, 0, 0, 0.5)',
        padding: 3,
        boxSizing: 'border-box',
        height: 44,
        display: 'flex',
        alignItems: 'center',
        background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : '#ffffff',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%'
    },
    typeText: {
        marginLeft: 25,
        color: theme.colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
        cursor: 'default'
    },
    verticalSeparator: {
        height: 42,
        width: 1,
        background: theme.colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.13)' : 'rgba(0, 0, 0, 0.5)',
        margin: '0 15px'
    },
    headerText: {
        letterSpacing: 2,
        whiteSpace: 'nowrap',
        maxWidth: 'calc(100% - 220px)',
        overflow: 'hidden',
        
        ['@media(max-width: ' + theme.breakpoints.sm + 'px)']: {
            '&': {
                marginLeft: 25,
            }
        }
    },
    rightText: {
        flex: 1,
        textAlign: 'right',
        paddingRight: 5
    },
    
    
    fpNotiflist: {
        minWidth: 300,
        padding: 10,
        background: '#ffffff',
    },
    
    
    pageWrapper: {
        maxWidth: 1800,
        margin: 'auto'
    },
    dataWrapper: {
        paddingTop: 15,
        display: 'flex',
        flexDirection: 'row'
    },
    imageWrapper: {
        position: 'relative',
        flexGrow: 1
    },
    
    fpimg: {
        maxWidth: '100%',
        width: '100%',
        height: '100%'
    },
    fplocWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    fploc: {
        position: 'absolute',
        display: 'inline-block',
        background: 'blue',
        borderRadius: 200,
        zIndex: 10,
        width: 25,
        height: 25,
        fontSize: '0.7rem',
        textAlign: "center",
        lineHeight: 2.4,
        color: '#ffffff',
        border: '1px solid #000',
        boxShadow: '2px 3px 4px #000, -2px -2px 3px #fff',
        cursor: 'default',
        transform: 'translateX(-50%) translateY(-50%)'
    },
    fplocactive: {
        background: "#f0f069",
        color: '#000000'
    },
    fparea: {
        position: 'absolute',
        background: '#cb94cc21',
    },
    fpasset: {
        display: 'inline-block',
        borderRadius: 200,
        zIndex: 10,
        width: 15,
        height: 15,
        border: '1px solid #000',
        boxShadow: '2px 3px 4px #000, -2px -2px 3px #fff',
        cursor: 'default',
        transform: 'translateX(-50%) translateY(-50%)',
        textAlign: "center",
        lineHeight: 2.2,
        fontSize: '0.4rem',
        color: '#000'
    },
    fpassetActive: {
        border: '1px solid salmon',
        boxShadow: '2px 3px 4px #fff, -2px -2px 2px #000',
    }
}));

// <div style={{textShadow: "1px 0px 0 teal, -1px 0px 0 teal, 0px 1px 0 teal, 0px -1px 0 teal"}}>Healthcare</div>
const Healthcare = props => {
    dayjs.extend(duration);
    dayjs.extend(relativeTime);
    
    const theme = useMantineTheme();
    const config = React.useContext(AppContext);
    const navigate = useNavigate();
    const {classes, cx} = useStyles();
    const {locations, groups, update} = useLocations();
    const {history, fetch: assetFetch, loadAttempted} = useAssets();
    
    const [fplocations, setFpLocations] = useImmer([]);
    const [fpAssets, setFpAssets] = useImmer([]);
    
    const [selectedAsset, setSelectedAsset] = React.useState(null);
    const [selectEdit, setSelectEdit] = useImmer({
        new_location: null,
        loading: false
    });
    
    const [notifications, setNotifications] = useImmer([{
        time: dayjs(),
        type: "Notice",
        text: "Healthcare demo loaded"
    }])
    
    React.useEffect(() => {
        // Update locations to make sure counts are correct
        update();
        // Get websockets
        const inventory_socket = new WebSocket(ENDPOINTS('inventory_updates_ws').replace('https://', 'wss://').replace('http://', 'ws://'));
        inventory_socket.addEventListener("message", (event) => {
            const edata = JSON.parse(event.data);
            console.log('WS Inventory Event:', edata);
            if (edata.type === 'location' && edata.location) {
                setFpLocations(draft => {
                    let change_index = draft.findIndex(e => e.location_uuid === edata.location);
                    let changedName = draft[change_index]?.name;
                    if (change_index !== -1) {
                        if(draft[change_index].metadata?.max_people < edata.count) setNotifications(notidraft => {notidraft.push({
                            time: dayjs(),
                            type: "Warning",
                            text: 'Location ' + (changedName ?? 'Unnamed') + ' has more people than allowed (' + edata.count + ')!'
                        })})
                        if(draft[change_index].metadata?.max_people >= edata.count
                            && draft[change_index].metadata?.max_people < draft[change_index].count) setNotifications(notidraft => {notidraft.push({
                            time: dayjs(),
                            type: "Notice",
                            text: 'Location ' + (changedName ?? 'Unnamed') + ' has returned to allowed amounts (' + edata.count + ')!'
                        })})
                        draft[change_index].count = edata.count;
                    }
                });
            }
        });
        const tag_socket = new WebSocket(ENDPOINTS('tag_updates_ws').replace('https://', 'wss://').replace('http://', 'ws://'));
        tag_socket.addEventListener("message", (event) => {
            const edata = JSON.parse(event.data);
            console.log('WS Tag Event:', edata);
            if (edata.location) {
                setFpAssets(draft => {
                    let change_index = draft.findIndex(e => e.epc_code === edata.code);
                    let changedName = draft[change_index]?.name;
                    if (change_index !== -1) {
                        if(!draft[change_index].metadata?.allowed_locations?.includes(edata.location) && draft[change_index].metadata?.allowed_locations) setNotifications(notidraft => {notidraft.push({
                            time: dayjs(),
                            type: "Alarm",
                            text: "Person " + (changedName ?? 'Unnamed') + ' is outside of allowed areas!'
                        })})
                        if(draft[change_index].metadata?.allowed_locations?.includes(edata.location) && draft[change_index].metadata?.allowed_locations
                        && !draft[change_index].metadata?.allowed_locations?.includes(edata.previous_location)) setNotifications(notidraft => {notidraft.push({
                            time: dayjs(),
                            type: "Notice",
                            text: "Person " + (changedName ?? 'Unnamed') + ' returned to allowed areas!'
                        })})
                        draft[change_index].current_location = edata.location;
                    }
                });
            }
        });
        
        return () => {
            inventory_socket.close();
            tag_socket.close();
        }
    }, []);
    React.useEffect(() => {
        // Get tags
        let getAssets = () => {
            for(let i = 5000; i < 5005; i++) {
                let params = new URLSearchParams({'sku': i});
                fetch(`${ENDPOINTS('assets')}?${params}`, {
                    headers: {'Authorization': 'Bearer ' + config.accessToken}
                }).then(response => {
                    return response.json();
                }).then(data => {
                    setFpAssets(draft => {
                        data.forEach(item => {
                            if(!draft.find(e => e.epc_code === item.epc_code)) draft.push(item)
                        })
                    });
                }).catch(e => {
                    console.log('Error in asset fetch:', e);
                });
            }
        }
        getAssets();
    }, []);
    React.useEffect(() => {
        setFpLocations(draft => {
            let healthcare_locations = locations.filter(e => e.metadata?.healthcare_demo);
            if(healthcare_locations.length > 0) {
                draft.splice(0, draft.length, ...healthcare_locations);
            }
        })
    }, [locations]);
    React.useEffect(() => {
        if(selectedAsset) fetchAssetHistory()
    }, [selectedAsset]);
    
    const SkuToPosition = (sku, color = false) => {
        switch (sku) {
            case '5000':
                if(color) return theme.colors.green[7]
                return 'Nurse'
            case '5001':
                if(color) return theme.colors.orange[7]
                return 'Patient'
            case '5002':
                if(color) return theme.colors.blue[7]
                return 'Doctor'
            case '5003':
                if(color) return theme.colors.grape[7]
                return 'Staff'
            default:
                if(color) return theme.black
                return 'Other'
        }
    }
    
    const fetchAssetHistory = () => {
        assetFetch({tag: selectedAsset.id, page_size: 10}, 'history');
    }
    
    const moveAsset = async () => {
        setSelectEdit(draft => {draft.loading = true});
        let rq = await fetch(ENDPOINTS('asset_detail', {'id': selectedAsset.id}), {
            method: 'PATCH',
            body: JSON.stringify({current_location: selectEdit.new_location}),
            headers: {'Authorization': 'Bearer ' + config.accessToken, 'Content-Type': 'application/json'}
        });
        if(rq?.ok) {
            console.log('Succeé');
            fetchAssetHistory();
        }
        setSelectEdit(draft => {draft.loading = false});
    }
    
    return (
        <div className={classes.pageWrapper}>
            <div className={classes.headerWrapper}>
                <div className={classes.headerContainer}>
                    <Text className={classes.typeText} size="xl" weight="bold">Healthcare Use-case Demo</Text>
                    <div className={classes.verticalSeparator} />
                    <Text className={classes.headerText} size="xl">
                        <Text span>Hospital Floorplan</Text>
                    </Text>
                    <Text className={classes.rightText} color="dimmed" size="sm">
                        <Text span>Track & Trace</Text>
                    </Text>
                </div>
            </div>
            
            <div className={classes.dataWrapper}>
                <div className={classes.imageWrapper}>
                    <img id="floorplanimg" className={classes.fpimg}
                         src={floorplan_img} alt="Floorplan"/>
                    <div className={classes.fplocWrapper}>
                        {fplocations.map(fparea => (
                            <HoverCard shadow="md" openDelay={400} key={'area' + fparea.location_uuid} position="top">
                                <HoverCard.Target>
                                    <Box className={classes.fparea} style={{
                                        top: `${fparea.metadata?.room?.top?.[0]}%`,
                                        left: `${fparea.metadata?.room?.left?.[0]}%`,
                                        height: `${fparea.metadata?.room?.top?.[1] - fparea.metadata?.room?.top?.[0]}%`,
                                        width: `${fparea.metadata?.room?.left?.[1] - fparea.metadata?.room?.left?.[0]}%`,
                                        background: fparea.metadata?.sanitary_room ? '#4c81d321' : (fparea.metadata?.['non-patient_room'] ? '#ee976321' : '#cb94cc21')
                                    }}>
                                        <Group style={{alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', padding: 10, height: '100%'}}>
                                            {fpAssets.filter(e => e.current_location === fparea.location_uuid).map(asset => (
                                                <HoverCard zIndex={1000} shadow="md" openDelay={400} key={'areaAsset' + asset.id} position="top">
                                                    <HoverCard.Target>
                                                        <Indicator className="healthcareWarningIndicator" color="red" disabled={asset.metadata?.allowed_locations?.includes(asset.current_location) || !asset.metadata?.allowed_locations}>
                                                            <Box
                                                                style={{background: SkuToPosition(asset.product, true)}}
                                                                className={cx(classes.fpasset, {[classes.fpassetActive]: selectedAsset?.id === asset?.id})}
                                                                onClick={() => setSelectedAsset(asset)}
                                                            >
                                                                {(asset.name?.[0] ?? 'A') + (asset.name?.split(' ')?.[1]?.[0] ?? 'A').toUpperCase()}
                                                            </Box>
                                                        </Indicator>
                                                    </HoverCard.Target>
                                                    <HoverCard.Dropdown>
                                                        <Text>{SkuToPosition(asset.product)} - {asset.name ?? 'Unnamed'}</Text>
                                                        <Text size="xs" color="dimmed">ID: {asset.id}, EPC: {asset.epc_code}</Text>
                                                        <Text size="xs" color="dimmed">
                                                            Allowed locations:{' '}
                                                            {asset.metadata?.allowed_locations?.map(e => locations.find(n => n.location_uuid === e)?.name ?? 'Unknown location').join(', ')}
                                                            {(!asset.metadata?.allowed_locations || asset.metadata?.allowed_locations?.length === 0) && 'Unrestricted'}
                                                        </Text>
                                                    </HoverCard.Dropdown>
                                                </HoverCard>
                                            ))}
                                        </Group>
                                    </Box>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                    <div style={{color: 'darkgray', marginBottom: 5}}>Room Area</div>
                                    <div style={{fontSize: '1.1rem'}}>{fparea?.name ?? 'Unknown Location'}</div>
                                    <Text size="sm" c="dimmed">{fparea?.location_uuid ?? 'Unknown UUID'}</Text>
                                </HoverCard.Dropdown>
                            </HoverCard>
                        ))}
                        
                        {fplocations.map(fploc => (
                            <HoverCard shadow="md" openDelay={400} key={fploc.location_uuid} position="top">
                                <HoverCard.Target>
                                    <Box
                                        className={classes.fploc}
                                        style={{
                                            top: `${fploc.metadata?.y_coord ?? 0}%`,
                                            left: `${fploc.metadata?.x_coord ?? 0}%`,
                                            background: (fploc.metadata?.max_people < fploc.count) ? 'red' : (!fploc.count ? '#a09cff' : 'blue')
                                        }}
                                    >
                                        {fploc?.count ?? 0}
                                    </Box>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                    <Text>{fploc?.name ?? 'Unknown Location'}</Text>
                                    <Text size="xs" color="dimmed">Max allowed people: {fploc.metadata?.max_people ?? 'Unlimited'}</Text>
                                </HoverCard.Dropdown>
                            </HoverCard>
                        ))}
                    </div>
                </div>
                <div className={classes.fpNotiflist}>
                    <Text c="black" size="xl">Notifications:</Text>
                    <Divider />
                    {notifications.map(noti => (
                        <Stack style={{gap: 0}}>
                            <Group style={{justifyContent: 'space-between'}} mt={12}>
                                <Text c={noti.type === "Notice" ? "blue.8" : (noti.type === "Warning" ? "yellow.8" : 'red.8')}>{noti.type}</Text>
                                <Text c="gray.7" mr={8} size="sm" style={{alignSelf: 'flex-start'}}>{dayjs(noti.time).format('HH:mm:ss')}</Text>
                            </Group>
                            <Text c="gray.9" size="sm" mt={-4}>{noti.text}</Text>
                        </Stack>
                    ))}
                </div>
            </div>
            <Box mt={20}>
                {!selectedAsset && <Text>Nothing selected</Text>}
                {selectedAsset && <>
                    <Group position="apart">
                        <Group>
                            <Text>Move to:</Text>
                            <Select
                                value={selectEdit.new_location}
                                onChange={value => setSelectEdit(draft => {
                                    draft.new_location = value
                                })}
                                placeholder="New location"
                                label="New Location"
                                data={locations.filter(e => e.location_group == groups.find(n => n.name === 'Hospital Demo')?.id ?? true).map(location => ({
                                    label: location.name,
                                    value: location.location_uuid,
                                    group: groups.find(e => e.id === location.location_group)?.name ?? 'Root'
                                }))}
                                description="Where do you want to move this asset"
                                className={classes.formInput}
                            />
                            <Button style={{alignSelf: 'flex-end'}} onClick={moveAsset}
                                    loading={selectEdit.loading}>Go</Button>
                        </Group>
                        <Stack style={{gap: 0}}>
                            <Text>Asset: {selectedAsset.epc_code} ({selectedAsset?.name ?? 'Unnamed'})</Text>
                            <Button onClick={() => navigate('/assets/' + selectedAsset.epc_code)}>Go To Asset Detail</Button>
                        </Stack>
                    </Group>
                    <Box mt={30} style={{position: 'relative'}}>
                        <LoadingOverlay visible={!loadAttempted} overlayBlur={2}/>
                        <Table striped highlightOnHover>
                            <thead>
                            <tr>
                                <th>Location</th>
                                <th>Time Into</th>
                                <th>Time Out</th>
                                <th>Time Spent</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(history ?? []).map(entry => (
                                <tr key={entry.id}>
                                    <td>{locations.find(e => e.location_uuid === entry.location)?.name ?? entry.location}</td>
                                    <td>{dayjs(entry.time_into).format('HH:mm:ss || DD.MM.YYYY')}</td>
                                    <td>{entry.time_from ? dayjs(entry.time_from).format('HH:mm:ss || DD.MM.YYYY') : 'N/A'}</td>
                                    <td>{entry.time_spent
                                        ? <Text span>{humanizeSeconds(entry.time_spent, true)}</Text>
                                        : humanizeSeconds(((new Date().getTime() + (new Date().getTimezoneOffset() * 60000)) - new Date(entry.time_into).getTime()) / 1000, true)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Box>
                </>}
            </Box>
        </div>
    )
};
export default Healthcare;
