import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import {Button, Card, PasswordInput, createStyles} from "@mantine/core";
import IdcatLogo from "./RustyRadeaLogo";
import ENDPOINTS from "../config/endpoint";

const useStyles = createStyles((theme) => ({
    cardWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 60px)',
        width: '100%',
        padding: "0 30px"
    },
    mainCard: {
        width: 500,
        maxWidth: '90%',
        background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : '#fff',
        borderRadius: 8,
        padding: 16
    },
    cardHeader: {
        fontSize: '1.6rem',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 16
    },
    cardBody: {
        textAlign: 'center',
        width: '90%',
        margin: 'auto'
    },
    locationCode: {
        minWidth: '30%',
        textAlign: 'center',
        fontSize: '0.8rem',
        marginTop: 16,
        marginBottom: 20
    },
    homeButton: {
        alignSelf: 'flex-end',
        marginRight: 30,
        marginTop: 30,
        marginBottom: 15
    },

    highligher: {
        color: theme.colorScheme === 'dark' ? theme.colors[theme.primaryColor][2] : theme.colors[theme.primaryColor][8]
    }
}));

const Login = props => {
    const { classes } = useStyles();
    
    const [value, setValue] = React.useState('');
    const [visible, { toggle }] = useDisclosure(false);

    return (
        <div className={classes.cardWrapper}>
            <Card shadow="lg" className={classes.mainCard}>
                <div className="centeredContent">
                    <IdcatLogo />
                    <header className={classes.cardHeader}>Login To IDCAT</header>
                    <PasswordInput
                        label="IDCAT Access Token"
                        description="Please input your access token"
                        placeholder="Access Token"
                        visible={visible}
                        onVisibilityChange={toggle}
                        value={value}
                        onChange={(event) => setValue(event.currentTarget.value)}
                        style={{width: '90%'}}
                    />
                    <Button mt='md' w='90%' onClick={() => props.attemptLogin(value)}>
                        Enter IDCAT
                    </Button>
                </div>
            </Card>
        </div>
        )
};
export default Login;