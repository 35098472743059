import React from 'react';
import {Button, createStyles, Select, TextInput} from "@mantine/core";
import {useImmer} from "use-immer";
import ENDPOINTS from "../../config/endpoint";
import {showNotification} from "@mantine/notifications";

import AppContext from "../../config/AppContext";

const useStyles = createStyles(theme => ({
    formContainer: {
        maxWidth: 1800,
        margin: 'auto'
    },
    formInput: {
        marginTop: 7
    },
    submitButton: {
        marginTop: 30
    }
}));

const ProductForm = props => {
    const config = React.useContext(AppContext);

    const { classes } = useStyles();

    const [formValues, setFormValues] = useImmer({
        sku: props.edit?.sku ?? '',
        type_: props.edit?.type_ ?? 'physical',
        name: props.edit?.name ?? ''
    });

    const submit = () => {
        if(!props.edit) {
            fetch(ENDPOINTS('products'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + config.accessToken
                },
                body: JSON.stringify(formValues)
            }).then(response => {
                if(!response.ok) failNotification()
                else {
                    successNotification();
                    props.close();
                }
            }).catch(error => {
                errorNotification();
                console.warn(error);
            });
        } else {
            if(props.edit?.sku) {
                fetch(ENDPOINTS('product_detail', {sku: props.edit.sku}), {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + config.accessToken
                    },
                    body: JSON.stringify(formValues)
                }).then(response => {
                    if(!response.ok) failNotification()
                    else {
                        successNotification();
                        props.close();
                    }
                }).catch(error => {
                    errorNotification();
                    console.warn(error);
                });
            } else console.warn('Improperly configured product edit form')
        }
    };
    const failNotification = () => showNotification({
        title: 'Failed to add/edit product',
        message: 'Adding/Editing product failed. Please check your inputs and try again',
        color: 'red'
    });
    const successNotification = () => showNotification({
        title: 'Added/Edited product',
        color: 'green'
    });
    const errorNotification = () => showNotification({
        title: 'Something went wrong in the request',
        message: 'Please wait a while and try again. If the problem persists, please contact support',
        color: 'red'
    });

    return (
            <div className={classes.formContainer}>
                <TextInput
                    value={formValues.name}
                    onChange={e => setFormValues(draft => {draft.name = e.target.value})}
                    placeholder="Product Name"
                    label="Product Name"
                    className={classes.formInput}
                />
                <TextInput
                    value={formValues.sku}
                    onChange={e => setFormValues(draft => {draft.sku = e.target.value})}
                    placeholder="Product SKU"
                    label="Product SKU"
                    description="Stock keeping unit of this product"
                    withAsterisk={!props.edit}
                    className={classes.formInput}
                />
                <Select
                    value={formValues.type_}
                    onChange={value => setFormValues(draft => {draft.type_ = value})}
                    placeholder="Product Type"
                    label="Product Type"
                    data={[
                    {value: 'physical', label: 'Physical'},
                    {value: 'service', label: 'Service'},
                    {value: 'unassigned', label: 'Unassigned'}
                    ]}
                    description="Is this a physical product or something else"
                    withAsterisk={!props.edit}
                    className={classes.formInput}
                />
                <div className={classes.submitButton}>
                    <Button radius="xs" fullWidth onClick={submit}>Submit</Button>
                </div>
            </div>
            )
};
export default ProductForm;