import React from 'react';
import {CreateEditModal, HorizontalSeparator, ListTopActions} from "../../components/RustyRadeaComponents";
import {ActionIcon, LoadingOverlay, Menu, Table, useMantineTheme} from "@mantine/core";
import {IconCaretRight, IconRefresh} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";
import FloorplanForm from "./FloorplanForm";
import {useDebouncedState} from "@mantine/hooks";

import AppContext from "../../config/AppContext";

import ENDPOINTS from '../../config/endpoint';

const FloorplanList = props => {
    const config = React.useContext(AppContext);

    const theme = useMantineTheme();
    const navigate = useNavigate();
    const [floorplans, setFloorplans] = React.useState([]);
    // eslint-disable-next-line no-unused-vars
    const [search, setSearch] = useDebouncedState('', 400);
    const [showCreate, setShowCreate] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        fetchFloorplans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const fetchFloorplans = async () => {
        let req = await fetch(ENDPOINTS('floorplan_list'), {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        });
        setFloorplans(await req.json());
        setLoading(false);
    }

    return (
        <div>
            <HorizontalSeparator size="lg" text="Floorplan Listing" color="primary" top={0} />
            <ListTopActions
                onSearch={value => setSearch(value)}
                createText="Add A New Floorplan"
                onCreate={() => setShowCreate(true)}
                extraMethods={<Menu.Dropdown>
                    <Menu.Label>General</Menu.Label>
                    <Menu.Item icon={<IconRefresh />} onClick={() => fetchFloorplans()} color="blue">Update Data</Menu.Item>
                </Menu.Dropdown>}
            />
            <div style={{ position: 'relative', maxWidth: '1800px', margin: 'auto' }}>
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <Table striped highlightOnHover>
                    <thead>
                        <tr><th>Name</th><th>Description</th><th>Image name</th><th></th></tr>
                    </thead>
                    <tbody>
                        {(floorplans ?? []).map(floorplan => (
                            <tr key={floorplan.id}>
                                <td>{floorplan.name}</td><td>{floorplan.description}</td><td>{floorplan.fs_name}</td>
                                <td className="tableActions">
                                    <ActionIcon variant="light" color={theme.primaryColor} onClick={() => navigate(String(floorplan.id), {state: floorplan})}><IconCaretRight /></ActionIcon>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <CreateEditModal open={showCreate} close={() => {setShowCreate(false); fetchFloorplans();}} modalTitle="Create A New Floorplan">
                <FloorplanForm close={() => {setShowCreate(false); fetchFloorplans();}} />
            </CreateEditModal>
        </div>
    );
}
export default FloorplanList;
