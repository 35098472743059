import {Route, Routes} from "react-router-dom";
import ReaderList from "./ReaderList";

const ReaderMaster = props => {
    return (
        <Routes>
            <Route index element={<ReaderList />} />
        </Routes>
        )
};
export default ReaderMaster;
