import React from 'react';
import {useLocation} from "react-router-dom";
import {useImmer} from "use-immer";
import {
    Accordion,
    Button,
    HoverCard,
    NumberInput,
    Menu,
    Popover,
    createStyles,
    useMantineTheme,
    Select, Input
} from "@mantine/core";
import {
    IconCirclePlus,
    IconPencil,
    IconRefresh,
    IconTrash,
    IconPlus
} from "@tabler/icons-react";

import {
    DetailViewHeader
} from "../../components/RustyRadeaComponents";
import AppContext from "../../config/AppContext";
import {useLocations} from "../../hooks/useLocations";
import ENDPOINTS from '../../config/endpoint';

import PingSound from '../../assets/ping.mp3';
import ClickSound from '../../assets/click.mp3';
import AlarmSound from '../../assets/alarm.mp3';

const useStyles = createStyles(theme => ({
    pageWrapper: {
        maxWidth: 1800,
        margin: 'auto'
    },
    dataWrapper: {
        paddingTop: 15,
        display: 'flex',
        flexDirection: 'row'
    },
    imageWrapper: {
        position: 'relative',
        flexGrow: 1
    },
    
    fpimg: {
        maxWidth: '100%',
        width: '100%',
        height: '100%'
    },
    fplocWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    fploc: {
        position: 'absolute',
        display: 'inline-block',
        background: 'blue',
        borderRadius: 200,
        zIndex: 10,
        width: 35,
        height: 35,
        textAlign: "center",
        lineHeight: 2.4,
        color: '#fff',
        border: '1px solid #000',
        boxShadow: '2px 3px 4px #000, -2px -2px 3px #fff',
        cursor: 'default',
        transform: 'translateX(-50%) translateY(-50%)'
    },
    fplocactive: {
        background: "#f0f069",
        color: '#000'
    },
    pbucket: {
        background: 'brown'
    },
    pbucketactive: {
        background: 'salmon',
        color: '#000'
    },
    fpLocationlist: {
        minWidth: 300,
        padding: 10
    },
    fpLocationCoordWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    fpLocationCoordActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 15,
        justifyContent: 'center'
    },

    fpuwbtag: {
        background: 'lightblue',
        lineHeight: 1.9,
        color: '#000',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    fpdangeruwbtag: {
        background: 'red',
        color: '#fff'
    },
    positionedfploc: {
        background: 'lightgreen',
        color: '#000'
    },
    uwbaz: {
        position: 'absolute',
        background: '#ff000077',
    },
    uwb_danger_proximity: {
        position: 'relative',
        display: 'none',
        color: 'red',
        background: 'rgba(206,195,48,0.61)',
        fontWeight: 'bold',
        fontSize: '2.5rem',
        zIndex: 10,
        borderRadius: 7
    }
}));

const FloorplanDetail = props => {
    const config = React.useContext(AppContext);

    const location = useLocation();
    const {classes, cx} = useStyles();
    const theme = useMantineTheme();
    const {locations, groups, update} = useLocations();

    const [floorplan, setFloorplan] = React.useState({});
    const [fplocations, setFpLocations] = useImmer([]);
    const [selectedLocation, setSelectedLocation] = React.useState(null);
    const [locationEditMode, setLocationEditMode] = React.useState(false);
    const [newLocationLocation, setNewLocationLocation] = React.useState(null);

    const [uwbLocations, setUwbLocations] = useImmer([]);
    const [uwbAlertingZones, setUwbAlertingZones] = useImmer([]);
    const [newUwbAlertingZone, setNewUwbAlertingZone] = useImmer({name: '', type: 1, location: null});
    const [uwbAlertingSocket, setUwbAlertingSocket] = React.useState(null);
    const [uwbConfigurationMode, setUwbConfigurationMode] = React.useState(false);
    const [newUwbLocation, setNewUwbLocation] = React.useState(null);
    const [uwbTags, setUwbTags] = useImmer([]);
    const [positioningBuckets, setPositioningBuckets] = useImmer([]);
    const [selectedPositioningBucket, setSelectedPositioningBucket] = React.useState(null);
    const [selectedBucketTags, setSelectedBucketTags] = React.useState([]);

    React.useEffect(() => {
        // Get websockets
        const inventory_socket = new WebSocket(ENDPOINTS('inventory_updates_ws').replace('https://', 'wss://').replace('http://', 'ws://'));
        inventory_socket.addEventListener("message", (event) => {
            const edata = JSON.parse(event.data);
            console.log('WS Inventory Event:', edata);
            if(edata.type === 'location' && edata.location) {
                setFpLocations(draft => {
                    let change_index = draft.findIndex(e => e.location === edata.location);
                    if(change_index !== -1) {
                        draft[change_index].location_data.count = edata.count;
                    }
                });
                setUwbTags(draft => {
                    let change_index = draft.findIndex(e => e.connected_location === edata.location);
                    if(change_index !== -1) draft[change_index].location_data.count = edata.count;
                });
            }
        });
        const uwb_location_socket = new WebSocket(ENDPOINTS('uwb_location_updates_ws').replace('https://', 'wss://').replace('http://', 'ws://'));
        uwb_location_socket.addEventListener('message', (event) => {
            const edata = JSON.parse(event.data);
            //console.log('WS UWB location event:', edata);
            if(edata.x && edata.y) {
                setUwbTags(draft => {
                    let elem = draft.find(e => e.hwid === edata.tag);
                    if(elem) {
                        elem.x = edata.x;
                        elem.y = edata.y;
                    }
                })
            }
        });
        const uwb_alert_socket = new WebSocket(ENDPOINTS('uwb_alerts_ws').replace('https://', 'wss://').replace('http://', 'ws://'));
        setUwbAlertingSocket(uwb_alert_socket);

        return () => {
            inventory_socket.close();
            uwb_location_socket.close();
            uwb_alert_socket.close();
        }
    }, []);
    const uwbDangerProximityAlert = event => {
        const edata = JSON.parse(event.data);
        console.log('WS UWB alert event:', edata);
        // Check that the zone of the alert is in a UWB location assigned to this floorplan
        if(uwbLocations.find(e => e.id === uwbAlertingZones.find(n => n.id === edata.zone)?.location)?.floor_plan === floorplan.id) {
            console.log('Alert raised! Status: ', edata.status);
            if(edata.status === 'entered') {
                var audio = new Audio(PingSound);
                audio.loop = false;
                audio.play();
            } else if(edata.status === 'exited') {
                var audio = new Audio(ClickSound);
                audio.loop = false;
                audio.play();
            }
        }
        if(uwbLocations.find(e => e.id === uwbTags.find(n => n.hwid === edata.tag)?.location)?.floor_plan === floorplan.id) {
            if(edata.status === 'closeby_danger') {
                var audio = new Audio(AlarmSound);
                audio.loop = false;
                audio.play();
                // Set warning text
                let elem = document.getElementById('uwb_danger_proximitytext');
                console.log('Tagdata:', uwbTags);
                elem.style.left = (uwbTags.find(n => n.hwid === edata.tag)?.x + 2) + '%';
                elem.style.top = (uwbTags.find(n => n.hwid === edata.tag)?.y - 5) + '%';
                elem.style.display = 'inline-block';
                window.setTimeout(() => elem.style.display = 'none', 5000);
            }
        }
    };
    React.useEffect(() => {
        setFloorplan(location.state);
        update();
    }, [location]);
    React.useEffect(() => {
        if(Object.keys(floorplan).length !== 0 && locations.length > 0) {
            fetchUwbData();
            fetchFpLocations();
        }
    }, [floorplan, locations]);
    React.useEffect(() => {
        if(uwbAlertingSocket) {
            uwbAlertingSocket.addEventListener('message', uwbDangerProximityAlert);
            return () => uwbAlertingSocket.removeEventListener('message', uwbDangerProximityAlert);
        }
    }, [uwbTags]);
    const fetchFpLocations = async () => {
        let req = await fetch(ENDPOINTS('floorplan_locations', {floorplan_id: floorplan.id}), {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        });
        let data = await req.json();
        for (const item in data) {
            data[item]['location_data'] = locations.find(e => e.location_uuid === data[item].location);
        }
        setFpLocations(data);
    };
    
    const updateLocationCoords = (fploc, value, dir) => {
        setFpLocations(draft => {
            draft.find(loc => loc.location === fploc.location)[dir] = value;
            draft.find(loc => loc.location === fploc.location).edited = true;
        });
    }
    const saveLocationCoords = async (fploc) => {
        await fetch(ENDPOINTS('floorplan_location_detail', {fplocation_id: fploc.id}), {
            method: 'PATCH',
            body: JSON.stringify({
                x_coord: fploc.x_coord,
                y_coord: fploc.y_coord
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.accessToken
            }
        });
        setFpLocations(draft => {
            draft.find(loc => loc.location === fploc.location).edited = false;
        });
    }
    const addLocationToFloorplan = async () => {
        let req = await fetch(ENDPOINTS('floorplan_locations', {floorplan_id: floorplan.id}), {
            method: 'POST',
            body: JSON.stringify({
                location: newLocationLocation
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.accessToken
            }
        });
        let data = await req.json();
        data.location_data = locations.find(e => e.location_uuid === data.location)
        setFpLocations(draft => {
            draft.push(data)
        });
        setNewLocationLocation(null);
    }
    const deleteLocationFromFloorplan = async (fploc) => {
        await fetch(ENDPOINTS('floorplan_location_detail', {fplocation_id: fploc.id}), {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + config.accessToken
            }
        });
        setFpLocations(draft => {
            draft.splice(draft.findIndex(loc => loc.location === fploc.location), 1)
        });
    }
    const fetchUwbData = async () => {
        let req = await fetch(ENDPOINTS('uwb_locations'), {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        });
        let data = await req.json();
        if(data) setUwbLocations(data);
        else console.log('Failed to fetch UWB locations');

        let az_req = await fetch(ENDPOINTS('uwb_alerting_zones'), {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        });
        let az_data = await az_req.json();
        if(az_data) setUwbAlertingZones(az_data);
        else console.log('Failed to fetch UWB alerting zones');

        let tag_req = await fetch(ENDPOINTS('uwb_tags'), {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        });
        let tag_data = await tag_req.json();
        if(tag_data) {
            tag_data.forEach(datapoint => {
               if(datapoint.connected_location) {
                   console.log('Find location:', locations.find(e => e.location_uuid === datapoint.connected_location))
                   datapoint.location_data = locations.find(e => e.location_uuid === datapoint.connected_location);
               }
            });
            console.log('UWB tags:', tag_data);
            setUwbTags(tag_data);
        }
        else console.log('Failed to fetch UWB tags');

        // Positioning buckets
        let pb_req = await fetch(ENDPOINTS('uwb_positioning_buckets') + '?floorplan=' + floorplan.id, {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        });
        let pb_data = await pb_req.json();
        if(pb_data) {
            setPositioningBuckets(pb_data);
        }
    }
    const assignUwbLocationToFloorplan = async () => {
        await fetch(ENDPOINTS('uwb_location_assignment', {id: newUwbLocation}), {
            method: 'POST',
            body: JSON.stringify({
                floorplan: floorplan.id
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.accessToken
            }
        });
        setUwbLocations(draft => {
            draft.find(e => e.id === newUwbLocation).floor_plan = floorplan.id;
        });
        setNewUwbLocation(null);
    }
    const addNewUwbAlertingZone = async () => {
        let req = await fetch(ENDPOINTS('uwb_alerting_zones'), {
            method: 'POST',
            body: JSON.stringify(newUwbAlertingZone),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.accessToken
            }
        });
        if(req.ok) {
            let newAz = await req.json();
            console.log('New AZ:', newAz);
            setUwbAlertingZones(draft => {
                draft.push(newAz)
            });
            setNewUwbAlertingZone(draft => ({name: '', type: 1, location: null}));
        }
    }
    const updateAlertingZoneCoords = async (az) => {
        let req = await fetch(ENDPOINTS('uwb_alerting_zone_detail', {id: az.id}), {
            method: 'PATCH',
            body: JSON.stringify({
                x1: az.zone.coordinates[0][0][0],
                x2: az.zone.coordinates[0][2][0],
                y1: az.zone.coordinates[0][0][1],
                y2: az.zone.coordinates[0][2][1]
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.accessToken
            }
        });
        if(req.ok) {
            setUwbAlertingZones(draft => {
                draft.find(e => e.id === az.id).edited = false;
            })
        }
    }
    const deleteAlertingZone = async (az) => {
        let req = await fetch(ENDPOINTS('uwb_alerting_zone_detail', {id: az.id}), {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + config.accessToken
            }
        });
        if(req.ok) {
            setUwbAlertingZones(draft => {
                let idx = draft.findIndex(e => e.id === az.id);
                if(idx) draft.splice(idx, 1);
            });
        }
    }
    
    const getPBucketTags = async (id) => {
        let req = await fetch(ENDPOINTS('assets') + '?positioning_bucket=' + id, {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        });
        let data = await req.json();
        if(data) setSelectedBucketTags(data)
    }

    return (
        <div className={classes.pageWrapper}>
            <DetailViewHeader
                type={'Floorplan'}
                headerText={floorplan?.name}
                rightText={"Id: " + floorplan?.id}
                actions={<>
                    <Menu.Label>Data</Menu.Label>
                    <Menu.Item icon={<IconRefresh size={18}/>} color="cyan">Update Data</Menu.Item>
                    <Menu.Item
                        icon={<IconPencil size={18}/>}
                        color={theme.colorScheme === 'dark' ? "orange.3" : "orange.6"}
                        onClick={() => setLocationEditMode(prev => !prev)}
                    >
                        Edit Floorplan
                    </Menu.Item>
                    <Menu.Divider/>

                    <Menu.Label>Content Settings</Menu.Label>
                    <Menu.Item
                        icon={<IconPlus size={18}/>}
                        color={theme.colorScheme === 'dark' ? "green.3" : "green.6"}
                        onClick={() => setLocationEditMode(prev => !prev)}
                    >
                        Add Locations
                    </Menu.Item>
                    <Menu.Item
                        icon={<IconPlus size={18}/>}
                        color={theme.colorScheme === 'dark' ? "green.3" : "green.6"}
                        onClick={() => setUwbConfigurationMode(prev => !prev)}
                    >
                        Configure UWB Positioning
                    </Menu.Item>
                    <Menu.Divider/>
    
                    <Menu.Label>Danger Zone</Menu.Label>
                    <Menu.Item icon={<IconTrash size={18}/>} color="red">Delete</Menu.Item>
                </>}
            />
            <div className={classes.dataWrapper}>
                <div className={classes.imageWrapper}>
                    <img id="floorplanimg" className={classes.fpimg} src={ENDPOINTS('floorplans', {fs_name: floorplan.fs_name})} alt="Floorplan Image" />
                    <div className={classes.fplocWrapper}>
                        {uwbAlertingZones.filter(n => uwbLocations.find(e => e.id === n.location)?.floor_plan === floorplan.id).map(uwbaz => (
                            <HoverCard shadow="md" openDelay={400} key={uwbaz.id} position="top">
                                <HoverCard.Target>
                                    <div className={cx(classes.uwbaz)} style={{
                                        top: `${uwbaz.zone?.coordinates?.[0][0][1]}%`,
                                        left: `${uwbaz.zone?.coordinates?.[0][0][0]}%`,
                                        height: `${uwbaz.zone?.coordinates?.[0][2][1] - uwbaz.zone?.coordinates?.[0][0][1]}%`,
                                        width: `${uwbaz.zone?.coordinates?.[0][2][0] - uwbaz.zone?.coordinates?.[0][0][0]}%`,
                                    }} onClick={() => null}/>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                    <div style={{fontSize: '0.7rem', color: 'darkgray', marginBottom: 5}}>UWB Alerting
                                        Zone
                                    </div>
                                    <div style={{fontSize: '1.4rem'}}>{uwbaz?.name ?? 'Unknown Location'}</div>
                                    <div style={{fontSize: '0.8rem'}}>Type: {uwbaz?.type}</div>
                                </HoverCard.Dropdown>
                            </HoverCard>
                        ))}
                        {positioningBuckets.map(pbucket => (
                            <HoverCard shadow="md" openDelay={400} key={pbucket.id} position="top">
                                <HoverCard.Target>
                                    <div
                                        className={cx(classes.fploc, classes.pbucket, {[classes.pbucketactive]: selectedPositioningBucket === pbucket.id})}
                                        style={{top: `${pbucket.y}%`, left: `${pbucket.x}%`}}
                                        onClick={() => {
                                            setSelectedPositioningBucket(pbucket.id);
                                            getPBucketTags(pbucket.id)
                                        }}
                                    >
                                        {''}
                                    </div>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                    <div style={{fontSize: '0.7rem', color: 'darkgray', marginBottom: 5}}>RFID Tag
                                        Positioning Bucket
                                    </div>
                                    <div style={{fontSize: '0.8rem'}}>x: {(pbucket?.x ?? 0).toFixed(2)},
                                        y: {(pbucket?.y ?? 0).toFixed(2)}</div>
                                </HoverCard.Dropdown>
                            </HoverCard>
                        ))}
                        {fplocations.map(fploc => (
                            <HoverCard shadow="md" openDelay={400} key={fploc.location} position="top">
                                <HoverCard.Target>
                                    <div
                                        className={cx(classes.fploc, {[classes.fplocactive]: selectedLocation === fploc.id})}
                                        style={{top: `${fploc.y_coord}%`, left: `${fploc.x_coord}%`}}
                                        onClick={() => setSelectedLocation(fploc.id)}>
                                        {fploc?.location_data?.count ?? 0}
                                    </div>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                    {fploc?.location_data?.name ?? 'Unknown Location'}
                                </HoverCard.Dropdown>
                            </HoverCard>
                        ))}
                        {uwbTags.filter(n => uwbLocations.find(e => e.id === n.location)?.floor_plan === floorplan.id && n.x && n.y && !n.connected_location).map(uwbtag => (
                            <HoverCard shadow="md" openDelay={400} key={uwbtag.hwid} position="top">
                                <HoverCard.Target>
                                    <div
                                        className={cx(classes.fploc, classes.fpuwbtag, {[classes.fpdangeruwbtag]: uwbtag.poses_danger})}
                                        style={{top: `${uwbtag.y}%`, left: `${uwbtag.x}%`}}
                                        onClick={() => null}
                                    >
                                        <span style={{fontSize: '0.6rem'}}>{uwbtag?.name ?? 'Unknown UWB Tag'}</span>
                                    </div>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                    <div style={{fontSize: '0.7rem', color: 'darkgray', marginBottom: 5}}>UWB Tag</div>
                                    <div style={{fontSize: '1.4rem'}}>{uwbtag?.name ?? 'Unknown UWB Tag'}</div>
                                    <div style={{fontSize: '0.8rem'}}>HWID: {uwbtag?.hwid}</div>
                                    <div style={{fontSize: '0.8rem'}}>x: {(uwbtag?.x ?? 0).toFixed(2)},
                                        y: {(uwbtag?.y ?? 0).toFixed(2)}, z: {((uwbtag?.z ?? 0).toFixed(2))}</div>
                                </HoverCard.Dropdown>
                            </HoverCard>
                        ))}
                        {uwbTags.filter(n => uwbLocations.find(e => e.id === n.location)?.floor_plan === floorplan.id && n.x && n.y && n.connected_location).map(uwbtag => (
                            <HoverCard shadow="md" openDelay={400} key={uwbtag.hwid} position="top">
                                <HoverCard.Target>
                                    <div
                                        className={cx(classes.fploc, classes.positionedfploc)}
                                        style={{top: `${uwbtag.y}%`, left: `${uwbtag.x}%`}}
                                        onClick={() => null}
                                    >
                                        <span>{uwbtag?.location_data?.count ?? 0}</span>
                                    </div>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                    <div style={{fontSize: '0.7rem', color: 'darkgray', marginBottom: 5}}>UWB Tag With
                                        Location
                                    </div>
                                    <div style={{fontSize: '1.4rem'}}>
                                        {uwbtag?.name ?? 'Unknown UWB Tag'} -
                                        Location: {locations.find(e => e.location_uuid === uwbtag.connected_location).name}
                                    </div>
                                    <div style={{fontSize: '0.8rem'}}>HWID: {uwbtag?.hwid} |
                                        Count: {uwbtag?.location_data?.count ?? 0}</div>
                                    <div style={{fontSize: '0.8rem'}}>x: {(uwbtag?.x ?? 0).toFixed(2)},
                                        y: {(uwbtag?.y ?? 0).toFixed(2)}</div>
                                </HoverCard.Dropdown>
                            </HoverCard>
                        ))}
                        <div className={classes.uwb_danger_proximity} id="uwb_danger_proximitytext">Warning!</div>
                    </div>
                </div>
                {locationEditMode && <div className={classes.fpLocationlist}>
                    <Popover trapFocus position="bottom" withArrow shadow="md">
                        <Popover.Target>
                            <Button variant="subtle" leftIcon={<IconCirclePlus />} style={{marginBottom: 20}}>Add New Location</Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <Select
                                value={newLocationLocation}
                                onChange={value => setNewLocationLocation(value)}
                                placeholder="Location To Show"
                                label="Location To Show"
                                data={locations.map(location => ({
                                    label: location.name,
                                    value: location.location_uuid,
                                    group: groups.find(e => e.id === location.location_group)?.name ?? 'Root',
                                    disabled: fplocations.find(e => e.location === location.location_uuid)
                                }))}
                                description="The location you'd like to show in this floorplan"
                            />
                            <Button onClick={addLocationToFloorplan} disabled={newLocationLocation === null}>Add</Button>
                        </Popover.Dropdown>
                    </Popover>
                    <Accordion>
                        {fplocations.map(fploc => (
                            <Accordion.Item value={fploc.location} key={fploc.location} fullWidth>
                                <Accordion.Control>{fploc?.location_data?.name ?? 'Unknown Location'}</Accordion.Control>
                                <Accordion.Panel>
                                    <div className={classes.fpLocationCoordWrapper}>
                                        <span style={{wordBreak: 'keep-all', paddingRight: 7}}>Top:</span>
                                        <NumberInput precision={2} min={0} max={100} value={fploc.y_coord} onChange={e => updateLocationCoords(fploc, e, 'y_coord')} />
                                    </div>
                                    <div className={classes.fpLocationCoordWrapper}>
                                        <span style={{wordBreak: 'keep-all', paddingRight: 7}}>Left:</span>
                                        <NumberInput precision={2} min={0} max={100} value={fploc.x_coord} onChange={value => updateLocationCoords(fploc, value, 'x_coord')} />
                                    </div>
                                    <div className={classes.fpLocationCoordActions}>
                                        {fploc.edited && (
                                            <Button className={classes.coordEditSave} onClick={() => saveLocationCoords(fploc)}>Save</Button>
                                            )}
                                        <Button color="red" onClick={() => deleteLocationFromFloorplan(fploc)}>Delete</Button>
                                    </div>
                                </Accordion.Panel>
                            </Accordion.Item>
                            ))}
                    </Accordion>
                </div>}
                {uwbConfigurationMode && <div className={classes.fpLocationlist}>
                    <Popover trapFocus position="bottom" withArrow shadow="md">
                        <Popover.Target>
                            <Button variant="subtle" leftIcon={<IconCirclePlus />} style={{marginBottom: 20}}>Add New UWB Location</Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <Select
                                value={newUwbLocation}
                                onChange={value => setNewUwbLocation(value)}
                                placeholder="UWB Location To Assign"
                                label="UWB Location To Assign"
                                data={uwbLocations.map(location => ({
                                    label: location.ext_name,
                                    value: location.id,
                                    disabled: location.floor_plan === floorplan.id
                                }))}
                                description="The UWB site you'd like to assign to this floorplan"
                            />
                            <Button onClick={assignUwbLocationToFloorplan} disabled={newUwbLocation === null}>Add</Button>
                        </Popover.Dropdown>
                    </Popover>
                    <Accordion>
                        {uwbLocations.filter(e => e.floor_plan === floorplan.id).map(uwbloc => (
                            <Accordion.Item value={uwbloc.ext_id} key={uwbloc.id} fullWidth>
                                <Accordion.Control>{uwbloc?.ext_name ?? 'Unknown Location'}</Accordion.Control>
                                <Accordion.Panel>
                                    <Popover trapFocus position="bottom" withArrow shadow="md">
                                        <Popover.Target>
                                            <Button
                                                variant="subtle"
                                                leftIcon={<IconCirclePlus />}
                                                style={{marginBottom: 20}}
                                                onClick={() => setNewUwbAlertingZone(draft => {
                                                    draft.name = '';
                                                    draft.location = uwbloc.id;
                                                })}
                                            >
                                                Add New Alerting Zone
                                            </Button>
                                        </Popover.Target>
                                        <Popover.Dropdown>
                                            <Input
                                                value={newUwbAlertingZone.name}
                                                onChange={e => setNewUwbAlertingZone(draft => {
                                                    draft.location = uwbloc.id;
                                                    draft.name = e.target.value;
                                                })}
                                                placeholder="Name"
                                            />
                                            <Button onClick={addNewUwbAlertingZone} disabled={newUwbAlertingZone.name === ''}>Add</Button>
                                        </Popover.Dropdown>
                                    </Popover>
                                    <Accordion>
                                        {uwbAlertingZones.filter(e => uwbLocations.filter(n => n.floor_plan === floorplan.id).findIndex(m => m.id === e.location) !== -1).map(az => (
                                            <Accordion.Item value={az.name} key={az.name} fullWidth>
                                                <Accordion.Control>{az?.name ?? 'Unknown Alerting Zone'}</Accordion.Control>
                                                <Accordion.Panel>
                                                    <div className={classes.fpLocationCoordWrapper}>
                                                        <span style={{wordBreak: 'keep-all', paddingRight: 7}}>Top Start:</span>
                                                        <NumberInput
                                                            precision={2} min={0} max={100}
                                                            value={az.zone?.coordinates[0][0][1]}
                                                            onChange={v => setUwbAlertingZones(draft => {
                                                                let elem = draft.find(e => e.id === az.id);
                                                                elem.zone.coordinates[0][0][1] = v;
                                                                elem.zone.coordinates[0][1][1] = v;
                                                                elem.zone.coordinates[0][4][1] = v;
                                                                elem.edited = true;
                                                            })}
                                                        />
                                                    </div>
                                                    <div className={classes.fpLocationCoordWrapper}>
                                                        <span style={{wordBreak: 'keep-all', paddingRight: 7}}>Left Start:</span>
                                                        <NumberInput
                                                            precision={2} min={0} max={100}
                                                            value={az.zone?.coordinates[0][0][0]}
                                                            onChange={v => setUwbAlertingZones(draft => {
                                                                let elem = draft.find(e => e.id === az.id);
                                                                elem.zone.coordinates[0][0][0] = v;
                                                                elem.zone.coordinates[0][3][0] = v;
                                                                elem.zone.coordinates[0][4][0] = v;
                                                                elem.edited = true;
                                                            })}
                                                        />
                                                    </div>
                                                    <div className={classes.fpLocationCoordWrapper}>
                                                        <span style={{wordBreak: 'keep-all', paddingRight: 7}}>Top End:</span>
                                                        <NumberInput
                                                            precision={2} min={0} max={100}
                                                            value={az.zone?.coordinates[0][2][1]}
                                                            onChange={v => setUwbAlertingZones(draft => {
                                                                let elem = draft.find(e => e.id === az.id);
                                                                elem.zone.coordinates[0][1][1] = v;
                                                                elem.zone.coordinates[0][2][1] = v;
                                                                elem.edited = true;
                                                            })}
                                                        />
                                                    </div>
                                                    <div className={classes.fpLocationCoordWrapper}>
                                                        <span style={{wordBreak: 'keep-all', paddingRight: 7}}>Left End:</span>
                                                        <NumberInput
                                                            precision={2} min={0} max={100}
                                                            value={az.zone?.coordinates[0][2][0]}
                                                            onChange={v => setUwbAlertingZones(draft => {
                                                                let elem = draft.find(e => e.id === az.id);
                                                                elem.zone.coordinates[0][2][0] = v;
                                                                elem.zone.coordinates[0][3][0] = v;
                                                                elem.edited = true;
                                                            })}
                                                        />
                                                    </div>
                                                    <div className={classes.fpLocationCoordActions}>
                                                        {az.edited &&
                                                            <Button
                                                                className={classes.coordEditSave}
                                                                onClick={() => updateAlertingZoneCoords(az)}
                                                            >
                                                                Save
                                                            </Button>
                                                        }
                                                        <Button color="red" onClick={() => deleteAlertingZone(az)}>Delete</Button>
                                                    </div>
                                                </Accordion.Panel>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                </Accordion.Panel>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>}
            </div>
            <div>
                {selectedBucketTags.length > 0 && <div style={{margin: 20}}>
                    {selectedBucketTags.map(tag => (
                        <div>{tag.epc_code}</div>
                    ))}
                </div>}
            </div>
        </div>
    )
};
export default FloorplanDetail;
