import React from 'react';
import {TimescaleChartControl} from "../TimescaleChartControl";
import {createStyles, LoadingOverlay} from "@mantine/core";
import {RustyRadeaChart} from "../RustyRadeaChart";
import dayjs from "dayjs";
import {useInventory} from "../../hooks/RustyRadeaHooks";

const useStyles = createStyles(theme => ({
    chartContainer: {
        paddingTop: 10
    }
}));

/**
 * InventoryGraph component produces an inventory graph utilizing apache echarts using the provided filters either
 * for product per location, location or product basis
 * @param {Object} props.filters                     Filters to use in the call to inventory data
 * @param {String} props.type                        The type of data to use from inventory (inventory, product or location)
 * @param {String} props.title                       Title of the graph shown above the graph
 *
 * @returns {React.ReactElement} The InventoryGraph component
 */
export const InventoryGraph = props => {
    const {classes} = useStyles();
    const {inventory, location, product, fetch, loadAttempted} = useInventory();

    React.useEffect(() => {
        switch (props.type) {
            case 'location': fetch(props.filters, 'locationinventory'); break;
            case 'product': fetch(props.filters, 'productinventory'); break;
            default: fetch(props.filters, 'inventory'); break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filters]);

    return (
        <div>
            <div style={{position: 'relative'}}>
                <LoadingOverlay visible={!loadAttempted} overlayBlur={2} />
                <TimescaleChartControl availableTypes={props.availableTypes} defaultType={props.dataType} defaultRange={props.defaultRange} defaultGranularity={props.defaultGranularity}/>
                <div className={classes.chartContainer}>
                    <RustyRadeaChart
                        option={{
                            title: {
                                text: props.title ?? ''
                            },
                            tooltip: {
                                trigger: 'axis',
                                formatter: function (params) {
                                    params = params[0];
                                    return (
                                            `${params.marker} ${dayjs(params.name).format('DD.MM.YYYY HH:mm')} - Count: ${params.value[1]}`
                                    );
                                },
                                axisPointer: {
                                    animation: false
                                }
                            },
                            xAxis: {
                                type: 'time',
                                splitLine: {
                                    show: false
                                }
                            },
                            yAxis: {
                                type: 'value',
                                boundaryGap: [0, '10%']
                            },
                            series: [
                                {
                                    data: (props.type === 'location' ? location : props.type === 'product' ? product : inventory).map(x => ({
                                        name: x.updated,
                                        value: [
                                            dayjs(x.updated).format('YYYY/MM/DD HH:mm'),
                                            x.count
                                        ]
                                    })),
                                    type: 'line',
                                    showSymbol: false,
                                }
                            ]
                        }}
                        notMerge={true}
                        lazyUpdate={true}
                    />
                </div>
            </div>
        </div>
    )
}