import React from 'react';
import {
    CreateEditModal,
    DetailViewTabContainer,
    HorizontalSeparator,
    ListTopActions
} from "../../components/RustyRadeaComponents";
import {LoadingOverlay, Menu, Table} from "@mantine/core";
import {useLocations, useProducts} from "../../hooks/RustyRadeaHooks";
import dayjs from "dayjs";
import {IconRefresh} from "@tabler/icons-react";
import {useDebouncedState} from "@mantine/hooks";
import LocationForm from "../locations/LocationForm";

const ProductInLocations = props => {
    const {productLocations, fetchProductLocations, productLocationsLoadAttempted} = useProducts();
    const {locations, update} = useLocations();

    const [search, setSearch] = useDebouncedState('', 400);
    const [showCreate, setShowCreate] = React.useState(false);

    React.useEffect(() => {
        if(props.product) fetchProductLocations(props.product);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.product]);

    return (
            <DetailViewTabContainer>
                <HorizontalSeparator text="Product In Locations" size="sm" />
                <ListTopActions
                    onSearch={value => setSearch(value)}
                    createText="Add A New Location"
                    onCreate={() => setShowCreate(true)}
                    extraMethods={<Menu.Dropdown>
                        <Menu.Label>Location List Actions</Menu.Label>
                        <Menu.Item icon={<IconRefresh size={14} />} color="blue">Update Data</Menu.Item>
                    </Menu.Dropdown>}
                />
                <div style={{ position: 'relative' }}>
                    <LoadingOverlay visible={!productLocationsLoadAttempted} overlayBlur={2} />
                    <Table striped highlightOnHover>
                        <thead>
                            <tr><th>Location Name</th><th>Count</th><th>Updated</th></tr>
                        </thead>
                        <tbody>
                            {(productLocations ?? []).filter(e => locations.find(l => l.location_uuid === e.location)?.name.toLowerCase().indexOf(search) !== -1).map(prodloc => (
                                    <tr key={prodloc.location_uuid}>
                                        <td>{locations.find(e => e.location_uuid === prodloc.location)?.name ?? 'Unknown'}</td>
                                        <td>{prodloc.count}</td>
                                        <td>{dayjs(prodloc.updated).format('HH:mm:ss DD.MM.YYYY')}</td>
                                    </tr>
                                    ))}
                        </tbody>
                    </Table>
                </div>
                <CreateEditModal open={showCreate} close={() => setShowCreate(false)} modalTitle="Create A New Location">
                    <LocationForm close={() => {setShowCreate(false); update();}} />
                </CreateEditModal>
            </DetailViewTabContainer>
            );
}
export default ProductInLocations;