import {createStyles} from "@mantine/core";

const useStyles = createStyles(theme => ({
    tabOutletWrapper: {
        padding: 15
    }
}));

/**
* DetailViewTabContainer component is a light wrapper around the tab content in a detail page to provide visually cohesive experience
* @param {node}  props.children                                 The content to render in the tab
*
* @returns {React.ReactElement} The DetailViewTabContainer component
*/
export const DetailViewTabContainer = props => {
    const { classes } = useStyles();
    return (
        <div className={classes.tabOutletWrapper}>
            {props.children}
        </div>
    )
}