import React from 'react';
import {TimescaleChartControl} from "../TimescaleChartControl";
import {createStyles, LoadingOverlay} from "@mantine/core";
import {RustyRadeaChart} from "../RustyRadeaChart";
import {useProducts, useLocations} from "../../hooks/RustyRadeaHooks";

const useStyles = createStyles(theme => ({
    chartContainer: {
        paddingTop: 10
    }
}));

/**
 * ProductInLocationsGraph component produces an inventory graph utilizing apache echarts using the provided filters either
 * for product per location, location or product basis
 * @param {Object} props.filters                     Filters to use in the call to inventory data
 * @param {String} props.type                        The type of data to use from inventory (inventory, product or location)
 * @param {String} props.title                       Title of the graph shown above the graph
 *
 * @returns {React.ReactElement} The ProductInLocationsGraph component
 */
export const ProductInLocationsGraph = props => {
    const {classes} = useStyles();
    const {productLocations, fetchProductLocations, productLocationsLoadAttempted} = useProducts();
    const {locations} = useLocations();

    React.useEffect(() => {
        if(props.product) fetchProductLocations(props.product);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.product]);

    return (
        <div>
            <div style={{position: 'relative'}}>
                <LoadingOverlay visible={!productLocationsLoadAttempted} overlayBlur={2}/>
                <TimescaleChartControl availableTypes={['Current']} />
                <div className={classes.chartContainer}>
                    <RustyRadeaChart
                        option={{
                            title: {
                                text: 'Product Location Counts'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            /*legend: {
                                type: 'scroll',
                                orient: 'vertical',
                                right: 25,
                                top: 30,
                                bottom: 20,
                                data: productLocations.map(prodloc => (
                                        (locations.find(e => e.location_uuid === prodloc.location)?.name ?? 'Unknown') + ': ' + prodloc.count
                                ))
                            },*/
                            series: [
                            {
                                name: 'Count In Location',
                                type: 'pie',
                                radius: '55%',
                                center: ['37%', '50%'],
                                data: productLocations.map(prodloc => ({
                                    value: prodloc.count,
                                    name: (locations.find(e => e.location_uuid === prodloc.location)?.name ?? 'Unknown')
                                })),
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                        }}
                        notMerge={true}
                        lazyUpdate={true}
                    />
                </div>
            </div>
        </div>
    )
}
