import {HorizontalSeparator as HorizontalSeparatorComponent} from "./HorizontalSeparator";
import {ListCard as ListCardComponent} from "./ListCard";
import {DetailViewHeader as DetailViewHeaderComponent} from "./DetailViewHeader";
import {StatsCard as StatsCardComponent} from "./StatsCard";
import {DetailViewTabContainer as DetailViewTabContainerComponent} from "./DetailViewTabContainer";
import {RustyRadeaChart as RustyRadeaChartComponent} from "./RustyRadeaChart";
import {ListTopActions as ListTopActionsComponent} from "./ListTopActions";
import {CreateEditModal as CreateEditModalComponent} from "./CreateEditModal";

// Graphs
import {InventoryGraph as InventoryGraphComponent} from "./graphs/InventoryGraph";
import {TagHospitalityGraph as TagHospitalityGraphComponent} from "./graphs/TagHospitalityGraph";
import {ProductInLocationsGraph as ProductInLocationsGraphComponent} from "./graphs/ProductInLocationsGraph";


// EXPORTS
export const HorizontalSeparator = HorizontalSeparatorComponent;
export const ListCard = ListCardComponent;
export const DetailViewHeader = DetailViewHeaderComponent;
export const StatsCard = StatsCardComponent;
export const DetailViewTabContainer = DetailViewTabContainerComponent;
export const RustyRadeaChart = RustyRadeaChartComponent;
export const ListTopActions = ListTopActionsComponent;
export const CreateEditModal = CreateEditModalComponent;

// Graphs
export const InventoryGraph = InventoryGraphComponent;
export const TagHospitalityGraph = TagHospitalityGraphComponent;
export const ProductInLocationsGraph = ProductInLocationsGraphComponent;
