import React from 'react';
import PropTypes from 'prop-types';
import {ActionIcon, Button, createStyles, Input, Menu, useMantineTheme} from "@mantine/core";
import {IconDotsVertical, IconPlus, IconSearch, IconTiltShift} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
    actionWrapper: {
        height: 28,
        width: '100%',
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        [`@media(max-width:${theme.breakpoints.sm}px)`]: {
            height: 76,
            marginBottom: 3,
            paddingBottom: 90,
            borderBottom: 'thin solid ' + (theme.colorScheme === 'dark' ? theme.colors.dark[3] : '#fff')
        }
    },
    searchInput: {
        '& input.mantine-Input-input': {
            height: 28,
            minHeight: 28,
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : '#fff'
        },
        [`@media(max-width:${theme.breakpoints.sm}px)`]: {
            minWidth: '100%',
            marginBottom: 12,

            '& input.mantine-Input-input': {
                height: 36,
                minHeight: 36
            },
        }
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        [`@media(max-width:${theme.breakpoints.sm}px)`]: {
            minWidth: '100%',
            justifyContent: 'space-between'
        }
    },
    actionButton: {
        height: 28,
        borderRadius: 0,
        borderRight: 'thin solid ' + theme.colors[theme.primaryColor][2],
        '&:last-of-type': {
            borderRight: 'none'
        },
        [`@media(max-width:${theme.breakpoints.sm}px)`]: {
            '&:last-of-type': {
                borderLeft: 'thin solid ' + theme.colors[theme.primaryColor][2],
            },
            '&:nth-of-type(2)': {
                borderLeft: 'thin solid ' + theme.colors[theme.primaryColor][2],
            }
        }
    },
}));

/**
 * ListTopActions component creates design-wise coherent top-row actions to list views
 * @param {function} props.onCreate                     Action to run when the create button is clicked
 * @param {String} props.createText                     Text to show on the create button
 * @param {function} props.onFilters                    Function to run when the filters button is clicked
 * @param {boolean} props.filtersActive                 Are any filters active or not
 * @param {function} props.onSearch                     Function to run when the search value changes
 * @param {node} props.extraMethods                     The menu items present in extra actions menu (<Menu.Dropdown> component)
 *
 * @returns {React.ReactElement} The ListTopActions component
*/
export const ListTopActions = props => {
    const { classes } = useStyles();
    const theme = useMantineTheme();

    return (
        <div className={classes.actionWrapper}>
            {props.onSearch && <Input icon={<IconSearch />} placeholder="Search" className={classes.searchInput} onChange={e => props.onSearch(e.target.value)} />}
            {!props.onSearch && <div />}
            <div className={classes.actionContainer}>
                {props.onCreate && <Button variant="subtle" className={classes.actionButton} leftIcon={<IconPlus />} onClick={props.onCreate}>{props.createText}</Button>}
                {props.onFilters && <Button variant="subtle" className={classes.actionButton} leftIcon={<IconTiltShift />} onClick={props.onFilters}>Show Filters</Button>}
                {props.extraMethods && <Menu shadow="md" position="bottom-end">
                    <Menu.Target>
                        <ActionIcon variant="subtle" color={theme.primaryColor} className={classes.actionButton}>
                            <IconDotsVertical size={16} />
                        </ActionIcon>
                    </Menu.Target>

                    {props.extraMethods}
                </Menu>}
            </div>
        </div>
    )
};
/**
 * Property validator for ListTopActions component
*/
ListTopActions.propTypes = {
    onCreate: PropTypes.func,
    createText: PropTypes.string,
    onFilters: PropTypes.func,
    filtersActive: PropTypes.bool,
    onSearch: PropTypes.func,
    extraMethods: PropTypes.node
};
/**
 * Default property declarator for ListTopActions component
*/
ListTopActions.defaultProps = {
    createText: "Add New",
    filtersActive: false
};
