import React from 'react';
import {Button, createStyles, FileInput, TextInput} from "@mantine/core";
import {useImmer} from "use-immer";
import ENDPOINTS from "../../config/endpoint";
import {showNotification} from "@mantine/notifications";
import {IconUpload} from "@tabler/icons-react";

import AppContext from "../../config/AppContext";

const useStyles = createStyles(theme => ({
    formContainer: {
        maxWidth: 1800,
        margin: 'auto'
    },
    formInput: {
        marginTop: 7
    },
    submitButton: {
        marginTop: 30
    }
}));

const FloorplanForm = props => {
    const config = React.useContext(AppContext);

    const { classes } = useStyles();

    const [formValues, setFormValues] = useImmer({
        name: props.edit?.name ?? (props.defaultName ?? ''),
        description: props.edit?.description ?? (props.defaultDescription ?? ''),
        floor_plan_image: null
    });
    //const [file, setFile] = React.useState(null);

    const submit = () => {
        const formData  = new FormData();
        formData.append('name', formValues.name);
        formData.append('description', formValues.description);
        formData.append('floor_plan_image', formValues.floor_plan_image);
        fetch(ENDPOINTS('floorplan_list'), {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + config.accessToken
            },
            body: formData
        }).then(response => {
            if(!response.ok) failNotification()
            else {
                successNotification();
                props.close();
            }
        }).catch(error => {
            errorNotification();
            console.warn(error);
        });
    };
    const failNotification = () => showNotification({
        title: 'Failed to add/edit floorplan',
        message: 'Adding floorplan failed. Please check your inputs and try again',
        color: 'red'
    });
    const successNotification = () => showNotification({
        title: 'Added/Edited floorplan',
        color: 'green'
    });
    const errorNotification = () => showNotification({
        title: 'Something went wrong in the request',
        message: 'Please wait a while and try again. If the problem persists, please contact support',
        color: 'red'
    });

    return (
        <div className={classes.formContainer}>
            <TextInput
                value={formValues.name}
                onChange={e => setFormValues(draft => {draft.name = e.target.value})}
                placeholder="Floorplan Name"
                label="Floorplan Name"
                className={classes.formInput}
                withAsterisk={!props.edit}
            />
            <TextInput
                value={formValues.description}
                onChange={e => setFormValues(draft => {draft.description = e.target.value})}
                placeholder="Description"
                label="Description"
                className={classes.formInput}
            />
            <FileInput
                value={formValues.floor_plan_image}
                onChange={img => setFormValues(draft => {draft.floor_plan_image = img})}
                label="Floorplan Image"
                placeholder="Floorplan Image"
                icon={<IconUpload size={17} />}
                className={classes.formInput}
            />
            
            <div className={classes.submitButton}>
                <Button radius="xs" fullWidth onClick={submit} disabled={formValues.floor_plan_image === null}>Submit</Button>
            </div>
        </div>
        )
};
export default FloorplanForm;