import React from 'react';
import {
    CreateEditModal,
    DetailViewTabContainer,
    HorizontalSeparator,
    ListTopActions
} from "../../components/RustyRadeaComponents";
import {LoadingOverlay, Menu, Table} from "@mantine/core";
import {useAssets, useLocations} from "../../hooks/RustyRadeaHooks";
import dayjs from "dayjs";
import {IconRefresh} from "@tabler/icons-react";
import {useDebouncedState} from "@mantine/hooks";
import AssetForm from "../assets/AssetForm";

const AssetsOfProduct = props => {
    const { assets, fetch, loadAttempted } = useAssets();
    const { locations } = useLocations();

    const [search, setSearch] = useDebouncedState('', 400);
    const [showCreate, setShowCreate] = React.useState(false);

    React.useEffect(() => {
        let filters = {};
        if(search) filters.search = search;
        if(props.product) fetch({...filters, sku: props.product});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.product, search]);

    return (
            <DetailViewTabContainer>
                <HorizontalSeparator text="Assets Of Product" size="sm" />
                <ListTopActions
                    onSearch={value => setSearch(value)}
                    createText="Add A New Asset"
                    onCreate={() => setShowCreate(true)}
                    extraMethods={<Menu.Dropdown>
                        <Menu.Label>Asset List Actions</Menu.Label>
                        <Menu.Item icon={<IconRefresh size={14} />} color="blue">Update Data</Menu.Item>
                    </Menu.Dropdown>}
                />
                <div style={{ position: 'relative' }}>
                    <LoadingOverlay visible={!loadAttempted} overlayBlur={2} />
                    <Table striped highlightOnHover>
                        <thead>
                            <tr><th>Name</th><th>EPC</th><th>Current Location</th><th>Updated</th><th>Created</th></tr>
                        </thead>
                        <tbody>
                            {(assets ?? []).map(asset => (
                                    <tr key={asset.epc_code}>
                                        <td>{asset.name}</td><td>{asset.epc_code}</td><td>{locations.find(e => e.location_uuid === asset.current_location)?.name ?? asset.current_location}</td>
                                        <td>{dayjs(asset.updated).format('HH:mm:ss DD.MM.YYYY')}</td><td>{dayjs(asset.created).format('HH:mm:ss DD.MM.YYYY')}</td>
                                    </tr>
                                    ))}
                        </tbody>
                    </Table>
                    <CreateEditModal open={showCreate} close={() => setShowCreate(false)} modalTitle="Create A New Asset">
                        <AssetForm close={() => {setShowCreate(false); fetch();}} defaultProduct={props.product} />
                    </CreateEditModal>
                </div>
            </DetailViewTabContainer>
            );
}
export default AssetsOfProduct;