import React from 'react';
import PropTypes from 'prop-types';
import {Card, createStyles, Group, ThemeIcon, UnstyledButton, useMantineTheme} from "@mantine/core";

import {IconCircleCheckFilled, IconCircle} from "@tabler/icons-react";
import {Link} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    cardProper: {
        borderBottomLeftRadius: 30,
        borderTopRightRadius: 30,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : '#fff',
        '&.mantine-Paper-root': {
            paddingLeft: 35
        }
    },
    cardWrapper: {
        width: '100%',
        marginTop: 5,
        lineHeight: 1.55
    },
    buttonFixes: {
        marginTop: 0,

        '& .rustyreact-listcard:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]
        }
    },
    linkFixes: {
        textDecoration: 'none',
        display: 'block'
    }
}));

/**
 * ListCard component creates design-wise coherent cards specifically to be used in lists.
 * @param {Object} props.children                       Card content to render inside of the card
 * @param {String} props.className                      Classname to add to the component
 * @param {String | number} props.top                   Top margin applied to the element (either number in pixels or valid CSS string)
 * @param {boolean} [props.button=false]                Whether to render the card as a button or not
 * @param {String} [props.link=false]                   Whether to render the card a s a link (if link is an address that address will be set as the link url=
 * @param {function} props.onClick                      Function to run when the button is clicked if the card is rendered as a button
 * @param {Object} [props.buttonProps={}]               Extra props to pass the button if the card is to be rendered as a button
 * @param {String} [props.p="md"]                       Padding of the card component
 * @param {boolean | null} [props.useCheck=null]        If true will show a checkmark in a circle before card content, if false an empty circle and otherwise nothing
 *
 * @returns {React.ReactElement} The ListCard component
*/
export const ListCard = props => {
    const { classes, cx } = useStyles();
    const theme = useMantineTheme();

    let wrapperProps = {
        ...props.buttonProps,
        className: cx(classes.cardWrapper, props.buttonProps?.className ?? '', {[classes.buttonFixes]: props.button, [classes.linkFixes]: props.link})
    }
    if(props.top) wrapperProps.style = {
        ...props.buttonProps?.style, marginTop: props.top
    };
    if(props.onClick) wrapperProps.onClick = props.onClick;
    if(props.link) wrapperProps.to = props.link;

    return (
        React.createElement(props.button ? UnstyledButton : props.link ? Link : 'div', wrapperProps,
            <Card p={props.p} className={cx(props.className, classes.cardProper, 'rustyreact-listcard')} shadow="md">
                <Group align="center" style={{width: '100%'}}>
                    {props.useCheck === true && <ThemeIcon
                        variant="outline"
                        style={{border: 'none', color: theme.colorScheme === 'dark' ? theme.colors.green[5] : theme.colors.green[8]}}
                    >
                        <IconCircleCheckFilled />
                    </ThemeIcon>}
                    {props.useCheck === false && <ThemeIcon
                        variant="outline"
                        style={{border: 'none', color: theme.colorScheme === 'dark' ? theme.colors.yellow[0] : theme.colors.gray[8]}}
                    >
                        <IconCircle />
                </ThemeIcon>}
                    <div style={{width: props.useCheck !== undefined ? 'auto' : '100%'}}>{props.children}</div>
                </Group>
            </Card>
        )
    )
};
/**
 * Property validator for ListCard component
*/
ListCard.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    button: PropTypes.bool,
    link: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    buttonProps: PropTypes.object,
    p: PropTypes.oneOfType([
        PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
        PropTypes.number
    ]),
    useCheck: PropTypes.bool
};
/**
 * Default property declarator for ListCard component
*/
ListCard.defaultProps = {
    button: false,
    link: false,
    buttonProps: {},
    p: 'md'
};
