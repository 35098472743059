import React from 'react';
import {Button, createStyles, Select, Tooltip} from "@mantine/core";
import {useImmer} from "use-immer";

const useStyles = createStyles(theme => ({
    controlWrapper: {
        backgroundColor: theme.colorScheme === 'dark' ? '#000' : '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    chartControlSelect: {
        'input': {
            maxWidth: 100,
            width: 100,
            background: theme.colorScheme === 'dark' ? '#000' : '#fff',
            height: 25,
            fontSize: 11,
            minHeight: 25
        }
    },
    chartControlButton: {
        height: 25,

    }
}));

/**
 * TimescaleChartControl component creates
 * @param {Array} [props.availableTypes=["Current", "Timescale]]   Types available to select in the dropdown
 * @param {String} [props.defaultType="Current"]                   Default type of chart data
 * @param {String} [props.defaultGranularity="1 hour"]             Default timeframe used initially
 * @param {String} [props.defaultRange="2 days"]                   Default interval to start at
 *
 * @returns {React.ReactElement} The TimescaleChartControl component
*/
export const TimescaleChartControl = props => {
    const timeranges = {
        'minute': [{value: 60, label: '1 hour'}, {value: 180, label: '3 hours'}, {value: 360, label: '6 hours'}, {value: 720, label: '12 hours'}],
        'hour': [{value: 12, label: '12 hours'}, {value: 24, label: '1 day'}, {value: 48, label: '2 days'}, {value: 168, label: '1 week'}],
        'day': [{value: 7, label: '1 week'}, {value: 30, label: '1 month'}, {value: 90, label: '3 months'}, {value: 180, label: '6 months'}],
        'week': [{value: 183, label: '6 months'}, {value: 365, label: '1 year'}, {value: 730, label: '2 years'}, {value: 1095, label: '3 years'}]
    };
    const granularities = {
        '1 minute': {alloc: 'minute', time: 'minute', count: 1},
        '1 hour': {alloc: 'hour', time: 'hour', count: 1},
        '1 day': {alloc: 'day', time: 'day', count: 1},
        '1 week': {alloc: 'week', time: 'day', count: 7}
    };

    const { classes } = useStyles();
    const [type, setType] = React.useState(props.defaultType);
    const [granularity, setGranularity] = React.useState(props.defaultGranularity);
    const [timerange, setTimerange] = React.useState(timeranges[granularities[props.defaultGranularity].alloc].find(e => e.label === props.defaultRange).value);
    const [changes, setChanges] = useImmer({
        type: props.defaultType, granularity: props.defaultGranularity, timerange: timeranges[granularities[props.defaultGranularity].alloc].find(e => e.label === props.defaultRange).value, changes: false
    });

    React.useEffect(() => {
        if(changes.type !== type || changes.granularity !== granularity || changes.timerange !== timerange) {
            setChanges(draft => {draft.changes = true});
        } else setChanges(draft => {draft.changes = false});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, granularity, timerange, changes]);
    // Get the processed data out immediately
    React.useEffect(() => {
        if(props.onConfirm) props.onConfirm(type, granularities[granularity], timerange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.controlWrapper}>
            <Tooltip label="Type of the chart data">
                <Select
                    className={classes.chartControlSelect}
                    placeholder="Type"
                    data={props.availableTypes}
                    value={type}
                    disabled={props.availableTypes.length === 1}
                    onChange={value => {setType(value); setGranularity(changes.granularity); setTimerange(changes.timerange)}}
                />
            </Tooltip>
            {type === 'Timescale' && <>
            <Tooltip label="Granularity of the data">
                <Select
                    className={classes.chartControlSelect}
                    placeholder="Granularity"
                    data={['1 minute', '1 hour', '1 day', '1 week']}
                    value={granularity}
                    onChange={value => {setGranularity(value); setTimerange(timeranges[granularities[value].alloc][1].value)}}
                />
            </Tooltip>
            <Tooltip label="Timerange to show">
                <Select
                    className={classes.chartControlSelect}
                    placeholder="Timerange"
                    data={timeranges[granularities[granularity].alloc]}
                    value={timerange}
                    onChange={value => setTimerange(value)}
                />
            </Tooltip>
            </>}
            {props.availableTypes.some(e => ['Timescale'].includes(e)) && <div style={{flex: 1, textAlign: 'right'}}>
                <Button
                    variant="light"
                    className={classes.chartControlButton}
                    disabled={!changes.changes}
                    onClick={() => {
                        setChanges(draft => {draft.type = type; draft.granularity = granularity; draft.timerange = timerange});
                        if(props.onConfirm) props.onConfirm(type, granularities[granularity], timerange)
                    }}
                >Confirm</Button>
            </div>}
        </div>
    )
}
/**
 * Default property declarator for TimescaleChartControl component
*/
TimescaleChartControl.defaultProps = {
    availableTypes: ['Current', 'Timescale'],
    defaultType: 'Current',
    defaultGranularity: '1 day',
    defaultRange: '1 month'
};

