import dayjs from "dayjs";

/**
 * A method to put super simple syntax highlighting to stringified JSON objects.
 * Due to the nature of how this works requires setting the HTML dangerously
 * such as (<div dangerouslySetInnerHTML={{__html: syntaxHighlightJSON(string)}}) />)
 * Params:
 * @param {String} string                  - The stringified JSON to be syntax highlighted
 */
export function syntaxHighlightJSON(string) {
    if(typeof string === 'string') {
        let json = string.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
            let isLink = false;
            // Number
            let classname = 'jsonNumber';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    // Key
                    classname = 'jsonKey';
                } else {
                    // String
                    classname = 'jsonString';
                    //if(match.indexOf('http://') !== -1 || match.indexOf('https://') !== -1) isLink = true;
                    if(/^"http[s]?:\/\//.test(match)) isLink = true;
                }
            } else if (/true|false/.test(match)) {
                // Boolean
                classname = 'jsonBoolean';
            } else if (/null/.test(match)) {
                // Null
                classname = 'jsonNull';
            }
            return '<span class="' + classname + '">' + (isLink ? '<a target="_blank" href="' + match.replace('"', '') + '">' : '') + match + (isLink ? '</a>' : '') + '</span>';
        });
    }
    return '';
}

export function humanizeSeconds(duration, accurate=false) {
    if(!accurate) {
        return dayjs.duration(duration, 'seconds').humanize();
    }
    let years = dayjs.duration(duration, "seconds").years();
    let months = dayjs.duration(duration, "seconds").months();
    let days = dayjs.duration(duration, "seconds").days();
    let hours = dayjs.duration(duration, "seconds").hours();
    let minutes = dayjs.duration(duration, "seconds").minutes();
    let seconds = dayjs.duration(duration, "seconds").seconds();

    return  (years > 0 ? years + ' years ' : '') +
            (months > 0 ? months + ' mo ' : '') +
            (days > 0 ? days + ' d ' : '') +
            (hours > 0 ? hours + ' h ' : '') +
            (minutes > 0 ? minutes + ' min ' : '') +
            (seconds > 0 ? seconds + ' sec' : '');
}

/**
 * This function will create random hexadecimal (0-F) characters
 * Params:
 * @param {Number} length               - The number of hex-characters to create
 */
export function generateRandomHex(length) {
    let result = '';
    const characters = '0123456789abcdef';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};