import React from 'react';
import {useImmer} from 'use-immer';
import { showNotification } from '@mantine/notifications';

import AppContext from "../config/AppContext";

import ENDPOINTS from '../config/endpoint';

export const useProducts = props => {
    const config = React.useContext(AppContext);

    const [products, setProducts] = useImmer(window.rustyradea?.products ? JSON.parse(JSON.stringify(window.rustyradea?.products)) : {all: [], locations: {}, productinlocations: {}});
    const [productLocations, setProductLocations] = React.useState([]);
    const [loadAttempted, setLoadAttempted] = React.useState(false);
    const [productLocationsLoadAttempted, setProductLocationsLoadAttempted] = React.useState(false);

    const fetchProductData = (filters={}) => {
        let params = new URLSearchParams(filters);
        fetch(`${ENDPOINTS('products')}?${params}`, {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        }).then(response => {
            if(!response.ok) {
                errorNotification();
            }
            return response.json();
        }).then(data => {
            if(!window.rustyradea.products) window.rustyradea.products = {all: [], locations: {}, productinlocations: {}};
            if(filters.location) {
                if(!window.rustyradea.products.locations[filters.location]) window.rustyradea.products.locations[filters.location] = [];
                window.rustyradea.products.locations[filters.location] = data;
                setProducts(draft => {draft.locations[filters.location] = data});
            } else {
                window.rustyradea.products.all = data;
                setProducts(draft => {draft.all = data});
            }
        }).catch(e => {
            errorNotification();
            console.log('Error in products fetch:', e);
        }).finally(() => setLoadAttempted(true));
    };
    const fetchProductLocations = (product, update=false) => {
        if(!window.rustyradea?.products?.productinlocations?.[product] || update) {
            let params = new URLSearchParams({sku: product});
            fetch(`${ENDPOINTS('productlocations')}?${params}`, {
                headers: {'Authorization': 'Bearer ' + config.accessToken}
            }).then(response => {
                if(!response.ok) {
                    errorNotification();
                }
                return response.json();
            }).then(data => {
                if(!window.rustyradea.products) window.rustyradea.products = {all: [], locations: {}, productinlocations: {}};
                if(!window.rustyradea.products.productinlocations[product]) window.rustyradea.products.productinlocations[product] = {}
                window.rustyradea.products.productinlocations[product] = data;
                setProducts(draft => {draft.productinlocations[product] = data});
                setProductLocations(window.rustyradea?.products?.productinlocations?.[product]);
            }).catch(e => {
                errorNotification();
                console.log('Error in products fetch:', e);
            }).finally(() => setProductLocationsLoadAttempted(true));
        } else {
            setProductLocations(window.rustyradea.products.productinlocations[product]);
            setProductLocationsLoadAttempted(true);
        }
    }
    const errorNotification = (type="list") => {
        showNotification({
            color: 'red',
            title: 'Failed to fetch ' + type === 'list' ? 'products' : 'product locations',
            message: 'The request to fetch products failed. Please refresh the page and try again',
        });
    };
    const updateData = (filters={}, update=false) => {
        if((loadAttempted === false && !window.rustyradea?.products) || update) fetchProductData(filters);
        if(window.rustyradea?.products) setLoadAttempted(true);
    }
    const fetchLocationProducts = (location, filters={}, update=false) => {
        if(!window.rustyradea?.products?.locations?.[location] || update) {
            fetchProductData({...filters, location: location})
        }
    };

    return {
        products: products.all,
        locations: products.locations,
        productLocations: productLocations,
        update: updateData,
        fetchLocationProducts: fetchLocationProducts,
        fetchProductLocations: fetchProductLocations,
        loadAttempted: loadAttempted,
        productLocationsLoadAttempted: productLocationsLoadAttempted
    };
};