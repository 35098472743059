import {IconCaretRight} from "@tabler/icons-react";
import dayjs from "dayjs";
import React from "react";
import {
    ActionIcon,
    Badge,
    Box, Button, Checkbox,
    createStyles,
    Group, HoverCard,
    LoadingOverlay,
    Stack,
    Table,
    Text,
    UnstyledButton
} from "@mantine/core";
import {useImmer} from "use-immer";
import AppContext from "../../config/AppContext";
import ENDPOINTS from "../../config/endpoint";
import {generateRandomHex} from "../../config/method";
import {useAssets} from "../../hooks/RustyRadeaHooks";
import {useLocations} from "../../hooks/useLocations";

import factory_icon from '../../assets/factory_icon.png';
import manufacture_icon from '../../assets/robot-arm_icon.png';
import qc_icon from '../../assets/exclamation-mark_icon.png';
import internal_transit_icon from '../../assets/loader_icon.png';
import storage_icon from '../../assets/cardboard_icon.png';
import transit_icon from '../../assets/truck_icon.png';

const useStyles = createStyles(theme => ({
    headerWrapper: {
        border: 'thin solid',
        borderColor: theme.colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.13)' : 'rgba(0, 0, 0, 0.5)',
        padding: 3,
        boxSizing: 'border-box',
        height: 44,
        display: 'flex',
        alignItems: 'center',
        background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : '#ffffff',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%'
    },
    typeText: {
        marginLeft: 25,
        color: theme.colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
        cursor: 'default'
    },
    verticalSeparator: {
        height: 42,
        width: 1,
        background: theme.colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.13)' : 'rgba(0, 0, 0, 0.5)',
        margin: '0 15px'
    },
    headerText: {
        letterSpacing: 2,
        whiteSpace: 'nowrap',
        maxWidth: 'calc(100% - 220px)',
        overflow: 'hidden',
        
        ['@media(max-width: ' + theme.breakpoints.sm + 'px)']: {
            '&': {
                marginLeft: 25,
            }
        }
    },
    rightText: {
        flex: 1,
        textAlign: 'right',
        paddingRight: 5
    },
    
    pageWrapper: {
        maxWidth: 1800,
        margin: 'auto'
    },
    
    phaseSelectionButton: {
        background: '#ffffff',
        padding: '0px 30px',
        borderRadius: 8,
        boxSizing: 'border-box',
    },
    phaseButtonActive: {
        border: '3px solid ' + theme.colors[theme.primaryColor][5],
    },
    phaseSelectionContainer: {
        margin: 40,
        gap: 50
    },
    phaseButtonActiveText: {
        fontWeight: 'bold',
    }
}));

const Industrial = props => {
    const {classes, cx} = useStyles();
    const {locations} = useLocations();
    const { assets, fetch: assetFetch, loadAttempted } = useAssets();
    const config = React.useContext(AppContext);
    
    const [selectedPhase, setSelectedPhase] = React.useState(null);
    
    const [locationsSet, setLocationsSet] = React.useState(false);
    const [idlocations, setIdLocations] = useImmer([]);
    const [actionsLoading, setActionsLoading] = React.useState(false);
    const [selectedAssets, setSelectedAssets] = useImmer([]);
    
    const [qcIssues, setQcIssues] = useImmer({
        status: null,
        assets: [],
        issues: []
    });
    
    React.useEffect(() => {
        // Get websockets
        const inventory_socket = new WebSocket(ENDPOINTS('inventory_updates_ws').replace('https://', 'wss://').replace('http://', 'ws://'));
        inventory_socket.addEventListener("message", (event) => {
            const edata = JSON.parse(event.data);
            console.log('WS Inventory Event:', edata);
            if (edata.type === 'location' && edata.location) {
                setIdLocations(draft => {
                    console.log('Updating IdLocations!');
                    let change_index = draft.findIndex(e => e.location_uuid === edata.location);
                    if (change_index !== -1) {
                        console.log('Change idx found!');
                        draft[change_index].count = edata.count;
                    }
                });
            }
        });
        const tag_socket = new WebSocket(ENDPOINTS('tag_updates_ws').replace('https://', 'wss://').replace('http://', 'ws://'));
        tag_socket.addEventListener("message", (event) => {
            const edata = JSON.parse(event.data);
            console.log('WS Tag Event:', edata);
            if (edata.location) {
                /*setFpAssets(draft => {
                    let change_index = draft.findIndex(e => e.epc_code === edata.code);
                    if (change_index !== -1) {
                        draft[change_index].current_location = edata.location;
                    }
                });*/
            }
        });
        
        return () => {
            inventory_socket.close();
            tag_socket.close();
        }
    }, []);
    React.useEffect(() => {
        setIdLocations(draft => {
            let industrial_locations = locations.filter(e => e.metadata?.industrial_demo);
            if(industrial_locations.length > 0) {
                setLocationsSet(true);
                draft.splice(0, draft.length, ...industrial_locations);
            }
        })
    }, [locations]);
    React.useEffect(() => {
        setSelectedAssets([]);
        setQcIssues({
            status: null,
            assets: [],
            issues: []
        });
        fetchPhaseAssets();
    }, [selectedPhase]);
    React.useEffect(() => {
        if(locationsSet) setSelectedPhase(0);
    }, [locationsSet]);
    
    const fetchPhaseAssets = () => {
        if(idlocations.length !== 0) {
            let filters = {location: phaseToLocation(selectedPhase)?.location_uuid ?? '', page_size: 10000};
            if([2, 3, 4, 5].includes(selectedPhase)) filters.has_parent = false;
            assetFetch(filters);
        }
    }
    
    const phaseToLocation = (phase) => {
        switch (phase) {
            case 0:
                return idlocations.find(e => e.metadata?.production_line_phase === 'initial')
            case 1:
                return idlocations.find(e => e.metadata?.production_line_phase === 'manufacture')
            case 2:
                return idlocations.find(e => e.metadata?.production_line_phase === 'qc')
            case 3:
                return idlocations.find(e => e.metadata?.production_line_phase === 'internal_transit')
            case 4:
                return idlocations.find(e => e.metadata?.production_line_phase === 'storage')
            case 5:
                return idlocations.find(e => e.metadata?.production_line_phase === 'left_factory')
            default:
                return idlocations.find(e => e.metadata?.production_line_phase === 'initial')
        }
    }
    
    const recipes = {
        '6001': [
            { item: '6010', count: 2, name: 'Gadget' },
            { item: '6012', count: 1, name: 'Thingamajig' },
            { item: '6014', count: 3, name: 'Doohickey' },
            { item: '6017', count: 1, name: 'Widget' }
        ],
        '6002': [
            { item: '6011', count: 1, name: 'Gizmo' },
            { item: '6013', count: 2, name: 'Whatchamacallit' },
            { item: '6015', count: 1, name: 'Doodad' },
            { item: '6018', count: 2, name: 'Thingamabob' },
            { item: '6019', count: 1, name: 'Whatnot' }
        ],
        '6003': [
            { item: '6010', count: 1, name: 'Gadget' },
            { item: '6011', count: 2, name: 'Gizmo' },
            { item: '6016', count: 1, name: 'Contraption' },
            { item: '6017', count: 3, name: 'Widget' },
            { item: '6020', count: 2, name: 'Gimmick' }
        ],
        '6004': [
            { item: '6012', count: 1, name: 'Thingamajig' },
            { item: '6013', count: 1, name: 'Whatchamacallit' },
            { item: '6014', count: 2, name: 'Doohickey' },
            { item: '6015', count: 2, name: 'Doodad' },
            { item: '6016', count: 1, name: 'Contraption' },
            { item: '6018', count: 1, name: 'Thingamabob' },
            { item: '6019', count: 1, name: 'Whatnot' },
            { item: '6020', count: 1, name: 'Gimmick' }
        ]
    };
    const finishedProducts = {
        '6001': 'Manufactured Item #1',
        '6002': 'Produced Item #2',
        '6003': 'Created Item #3',
        '6004': 'Assembled Item #4',
    }
    
    const createAssetOfSku = async (sku) => {
        setActionsLoading(true);
        const current_location = phaseToLocation(selectedPhase);
        let rq = await fetch(ENDPOINTS('assets'), {
            method: 'POST',
            body: JSON.stringify({
                current_location: current_location.location_uuid,
                epc_code: "06F" + generateRandomHex(19).toUpperCase(),
                product: String(sku)
            }),
            headers: {'Authorization': 'Bearer ' + config.accessToken, 'Content-Type': 'application/json'}
        });
        if(rq?.ok) {
            console.log('Succeé');
            fetchPhaseAssets();
        }
        setActionsLoading(false);
    }
    const buildAssetFromSelection = async (sku) => {
        setActionsLoading(true);
        const current_location = phaseToLocation(selectedPhase);
        let rq = await fetch(ENDPOINTS('assets'), {
            method: 'POST',
            body: JSON.stringify({
                current_location: current_location.location_uuid,
                epc_code: "06F" + generateRandomHex(19).toUpperCase(),
                product: String(sku),
                metadata: {
                    components: selectedAssets.map(e => e.epc_code)
                }
            }),
            headers: {'Authorization': 'Bearer ' + config.accessToken, 'Content-Type': 'application/json'}
        });
        if(rq?.ok) {
            let data = await rq.json();
            console.log('Succeé');
            for(const asset of selectedAssets) {
                let rq = await fetch(ENDPOINTS('asset_detail', {'id': asset.id}), {
                    method: 'PATCH',
                    body: JSON.stringify({parent: data.id}),
                    headers: {'Authorization': 'Bearer ' + config.accessToken, 'Content-Type': 'application/json'}
                });
                if(!rq?.ok) {
                    console.log('Failed to set parent to asset');
                }
            }
            fetchPhaseAssets();
        }
        setActionsLoading(false);
    }
    const checkAssetForIssues = async () => {
        setActionsLoading(true);
        if(selectedAssets.length > 0) {
            let params = new URLSearchParams({parent: selectedAssets[0].id})
            let rq = await fetch(`${ENDPOINTS('assets')}?${params}`, {
                method: 'GET',
                headers: {'Authorization': 'Bearer ' + config.accessToken, 'Content-Type': 'application/json'}
            });
            if(rq?.ok) {
                let issues = [];
                let data = await rq.json();
                let recipe = recipes[selectedAssets[0].product];
                if(recipe) {
                    recipe.forEach((item) => {
                        let count = data.filter(e => e.product === item.item).length;
                        if(count !== item.count) issues.push(`Wrong amount of ${item.name} (${item.item}). Expected ${item.count}, got ${count}`);
                    });
                    console.log('Checktime issues:', issues);
                    setQcIssues(draft => {
                        draft.status = !!!issues.length;
                        draft.issues = issues;
                        draft.assets = data;
                    });
                    setSelectedAssets(draft => {draft[0].metadata.qc_valid =!!!issues.length });
                    let rq = await fetch(ENDPOINTS('asset_detail', {'id': selectedAssets[0].id}), {
                        method: 'PATCH',
                        body: JSON.stringify({metadata: {...selectedAssets[0].metadata, qc_valid: !!!issues.length, qc_issues: issues}}),
                        headers: {'Authorization': 'Bearer ' + config.accessToken, 'Content-Type': 'application/json'}
                    });
                    if(rq?.ok) {
                        fetchPhaseAssets();
                    }
                }
            }
        }
        setActionsLoading(false);
    }
    
    const moveAssetsToNextPhase = async (skipPhases = 1) => {
        if(typeof skipPhases !== 'number' || !skipPhases) skipPhases = 1;
        setActionsLoading(true);
        const current_location = phaseToLocation(selectedPhase + skipPhases);
        let assetsToMove = [...selectedAssets];
        if(selectedAssets[0].metadata?.components) assetsToMove = [...assetsToMove, ...selectedAssets[0].metadata?.components.map(e => ({epc_code: e, product: ''}))]
        let rq = await fetch(ENDPOINTS('send_processlist'), {
            method: 'POST',
            body: JSON.stringify({
                reader: "b3c2a3d2-912b-44eb-a043-438d36e68190",
                format: "trace|goodsin",
                location: current_location.location_uuid,
                items: assetsToMove.map(asset => ({
                    code: asset.epc_code,
                    sku: asset.product
                })),
                metadata: {}
            }),
            headers: {'Authorization': 'Bearer ' + config.accessToken, 'Content-Type': 'application/json'}
        });
        if(rq?.ok) {
            console.log('Succeé');
            fetchPhaseAssets();
        }
        setActionsLoading(false);
    }
    
    return (
        <div className={classes.pageWrapper}>
            <div className={classes.headerWrapper}>
                <div className={classes.headerContainer}>
                    <Text className={classes.typeText} size="xl" weight="bold">Industrial Use-case Demo</Text>
                    <div className={classes.verticalSeparator}/>
                    <Text className={classes.headerText} size="xl">
                        <Text span>Production Facility</Text>
                    </Text>
                    <Text className={classes.rightText} color="dimmed" size="sm">
                        <HoverCard shadow="md" openDelay={400} position="top">
                            <HoverCard.Target>
                                <Text span style={{cursor: 'pointer'}}>Manufacturing Recipes</Text>
                            </HoverCard.Target>
                            <HoverCard.Dropdown>
                                <Box>
                                    {Object.keys(recipes).map(recipe => (
                                        <Box key={recipe}>
                                            <Text mb={5} size="lg" color="white">{finishedProducts[recipe]} ({recipe})</Text>
                                            <Table mb={20}>
                                                <thead><tr><th>Item</th><th>Count</th></tr></thead>
                                                <tbody>
                                                    {recipes[recipe].map(ingredients => (
                                                        <tr key={ingredients.item}>
                                                            <td>{ingredients.name} ({ingredients.item})</td>
                                                            <td>{ingredients.count}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Box>
                                    ))}
                                </Box>
                            </HoverCard.Dropdown>
                        </HoverCard>
                    </Text>
                </div>
            </div>
            
            <Group position="center" className={classes.phaseSelectionContainer}>
                <UnstyledButton className={cx(classes.phaseSelectionButton, {[classes.phaseButtonActive]: selectedPhase === 0})} onClick={() => setSelectedPhase(0)}>
                    <Stack align="center">
                        <img width={128} height={128} src={factory_icon}  alt="Initial Phase" />
                        <Text c="black" className={cx({[classes.phaseButtonActiveText]: selectedPhase === 0})}>Factory</Text>
                        <Group position="apart" mb={10} mt={-10}>
                            <Badge size="lg">{phaseToLocation(0)?.count ?? 0}</Badge>
                        </Group>
                    </Stack>
                </UnstyledButton>
                <UnstyledButton className={cx(classes.phaseSelectionButton, {[classes.phaseButtonActive]: selectedPhase === 1})} onClick={() => setSelectedPhase(1)}>
                    <Stack align="center">
                        <img width={128} height={128} src={manufacture_icon}  alt="Manufacturing Phase" />
                        <Text c="black" className={cx({[classes.phaseButtonActiveText]: selectedPhase === 1})}>Manufacture</Text>
                        <Group position="apart" mb={10} mt={-10}>
                            <Badge size="lg">{phaseToLocation(1)?.count ?? 0}</Badge>
                        </Group>
                    </Stack>
                </UnstyledButton>
                <UnstyledButton className={cx(classes.phaseSelectionButton, {[classes.phaseButtonActive]: selectedPhase === 2})} onClick={() => setSelectedPhase(2)}>
                    <Stack align="center" style={{marginTop: 10, padding: '0 5px'}}>
                        <img width={118} height={118} src={qc_icon}  alt="Quality Control" />
                        <Text c="black" className={cx({[classes.phaseButtonActiveText]: selectedPhase === 2})}>Quality Control</Text>
                        <Group position="apart" mb={10} mt={-10}>
                            <Badge size="lg">{phaseToLocation(2)?.count ?? 0}</Badge>
                        </Group>
                    </Stack>
                </UnstyledButton>
                <UnstyledButton className={cx(classes.phaseSelectionButton, {[classes.phaseButtonActive]: selectedPhase === 3})} onClick={() => setSelectedPhase(3)}>
                    <Stack align="center">
                        <img width={128} height={128} src={internal_transit_icon}  alt="Internal Transit" />
                        <Text c="black" className={cx({[classes.phaseButtonActiveText]: selectedPhase === 3})}>Removal</Text>
                        <Group position="apart" mb={10} mt={-10}>
                            <Badge size="lg">{phaseToLocation(3)?.count ?? 0}</Badge>
                        </Group>
                    </Stack>
                </UnstyledButton>
                <UnstyledButton className={cx(classes.phaseSelectionButton, {[classes.phaseButtonActive]: selectedPhase === 4})} onClick={() => setSelectedPhase(4)}>
                    <Stack align="center" style={{marginTop: 18, padding: '0 15px'}}>
                        <img width={100} height={100} src={storage_icon}  alt="Storage Phase" style={{marginBottom: 10}} />
                        <Text c="black" className={cx({[classes.phaseButtonActiveText]: selectedPhase === 4})}>Warehouse</Text>
                        <Group position="apart" mb={10} mt={-10}>
                            <Badge size="lg">{phaseToLocation(4)?.count ?? 0}</Badge>
                        </Group>
                    </Stack>
                </UnstyledButton>
                {/*<UnstyledButton
                    className={cx(classes.phaseSelectionButton, {[classes.phaseButtonActive]: selectedPhase === 5})}
                    onClick={() => setSelectedPhase(5)}>
                    <Stack align="center">
                        <img width={128} height={128} src={transit_icon} alt="Transit Phase"/>
                        <Text c="black" className={cx({[classes.phaseButtonActiveText]: selectedPhase === 5})}>Outbound
                            Logistics</Text>
                        <Group position="apart" mb={10} mt={-10}>
                            <Badge size="lg">{phaseToLocation(5)?.count ?? 0}</Badge>
                        </Group>
                    </Stack>
                </UnstyledButton>*/}
            </Group>
            
            <Group position="apart" pt={50}>
                <Box style={{flexGrow: '1', minHeight: '400px', minWidth: '50%', maxWidth: '50%'}}>
                    {/* ----------------------------
                           PHASE 0 - FACTORY
                    ---------------------------- */}
                    {selectedPhase === 0 && (
                        <Box style={{maxWidth: '70%', margin: 'auto'}}>
                            <Group style={{position: 'relative', flexWrap: 'wrap'}}>
                                <LoadingOverlay visible={actionsLoading} />
                                <Button variant="outline" style={{flexGrow: 1}} onClick={() => createAssetOfSku(6010)}>Order A Gadget - 6010 ({assets?.filter(e => e.product === '6010')?.length ?? 0})</Button>
                                <Button variant="outline" style={{flexGrow: 1}} onClick={() => createAssetOfSku(6011)}>Order A Gizmo - 6011 ({assets?.filter(e => e.product === '6011')?.length ?? 0})</Button>
                                <Button variant="outline" style={{flexGrow: 1}} onClick={() => createAssetOfSku(6012)}>Order A Thingamajig - 6012 ({assets?.filter(e => e.product === '6012')?.length ?? 0})</Button>
                                <Button variant="outline" style={{flexGrow: 1}} onClick={() => createAssetOfSku(6013)}>Order A Whatchamacallit - 6013 ({assets?.filter(e => e.product === '6013')?.length ?? 0})</Button>
                                <Button variant="outline" style={{flexGrow: 1}} onClick={() => createAssetOfSku(6014)}>Order A Doohickey - 6014 ({assets?.filter(e => e.product === '6014')?.length ?? 0})</Button>
                                <Button variant="outline" style={{flexGrow: 1}} onClick={() => createAssetOfSku(6015)}>Order A Doodad - 6015 ({assets?.filter(e => e.product === '6015')?.length ?? 0})</Button>
                                <Button variant="outline" style={{flexGrow: 1}} onClick={() => createAssetOfSku(6016)}>Order A Contraption - 6016 ({assets?.filter(e => e.product === '6016')?.length ?? 0})</Button>
                                <Button variant="outline" style={{flexGrow: 1}} onClick={() => createAssetOfSku(6017)}>Order A Widget - 6017 ({assets?.filter(e => e.product === '6017')?.length ?? 0})</Button>
                                <Button variant="outline" style={{flexGrow: 1}} onClick={() => createAssetOfSku(6018)}>Order A Thingamabob - 6018 ({assets?.filter(e => e.product === '6018')?.length ?? 0})</Button>
                                <Button variant="outline" style={{flexGrow: 1}} onClick={() => createAssetOfSku(6019)}>Order A Whatnot - 6019 ({assets?.filter(e => e.product === '6019')?.length ?? 0})</Button>
                                <Button variant="outline" style={{flexGrow: 1}} onClick={() => createAssetOfSku(6020)}>Order A Gimmick - 6020 ({assets?.filter(e => e.product === '6020')?.length ?? 0})</Button>
                            </Group>
                            <Stack mt={80}>
                                <Button disabled={selectedAssets.length === 0} loading={actionsLoading} fullWidth onClick={moveAssetsToNextPhase}>Move Selected Assets To Manufacture</Button>
                                <Button color="red" disabled={selectedAssets.length === 0} loading={actionsLoading} fullWidth onClick={() => moveAssetsToNextPhase(3)}>Mark Selected Assets For Removal</Button>
                            </Stack>
                        </Box>
                    )}
                    {/* ----------------------------
                         PHASE 1 - MANUFACTURE
                    ---------------------------- */}
                    {selectedPhase === 1 && (
                        <Box style={{maxWidth: '70%', margin: 'auto'}}>
                            <Stack style={{position: 'relative'}}>
                                <LoadingOverlay visible={actionsLoading} />
                                <Button disabled={selectedAssets.length === 0} variant="outline" onClick={() => buildAssetFromSelection(6001)}>Build A Manufactured Item #1 (6001) Using The Selected Assets</Button>
                                <Button disabled={selectedAssets.length === 0} variant="outline" onClick={() => buildAssetFromSelection(6002)}>Build A Produced Item #2 (6002) Using The Selected Assets</Button>
                                <Button disabled={selectedAssets.length === 0} variant="outline" onClick={() => buildAssetFromSelection(6003)}>Build A Created Item #3 (6003) Using The Selected Assets</Button>
                                <Button disabled={selectedAssets.length === 0} variant="outline" onClick={() => buildAssetFromSelection(6004)}>Build A Assembled Item #4 (6004) Using The Selected Assets</Button>
                            </Stack>
                            <Box mt={80}>
                                <Button disabled={selectedAssets.length === 0} loading={actionsLoading} fullWidth onClick={moveAssetsToNextPhase}>Move Selected Assets To QC</Button>
                            </Box>
                        </Box>
                    )}
                    {/* ----------------------------
                       PHASE 2 - QUALITY CONTROL
                    ---------------------------- */}
                    {selectedPhase === 2 && (
                        <Box style={{maxWidth: '70%', margin: 'auto'}}>
                            <Stack style={{position: 'relative'}}>
                                <LoadingOverlay visible={actionsLoading} />
                                <Button disabled={selectedAssets.length !== 1} variant="outline" onClick={checkAssetForIssues}>Verify The Components Of The Selected Asset</Button>
                                {qcIssues.status === true && <Box>
                                    <Text color="green">The asset is complete and flawless</Text>
                                </Box>}
                                {qcIssues.status === false && <Box>
                                    <Text color="red">The asset has the following issues:</Text>
                                    {qcIssues.issues.map((issue, idx) => (
                                        <Text>Issue #{idx}: {issue}</Text>
                                    ))}
                                </Box>}
                            </Stack>
                            <Stack mt={80}>
                                <Button disabled={selectedAssets[0]?.metadata?.qc_valid !== true} loading={actionsLoading} onClick={() => moveAssetsToNextPhase(2)}>Move Asset To Warehouse</Button>
                                <Button disabled={selectedAssets.length === 0} color="red" onClick={moveAssetsToNextPhase} loading={actionsLoading}>Mark Asset For Removal</Button>
                            </Stack>
                        </Box>
                    )}
                </Box>
                
                
                <Box style={{flexGrow: '1', boxSizing: 'border-box', borderLeft: 'thin solid #333', minHeight: '500px'}}>
                    <Text ml={10}>{phaseToLocation(selectedPhase)?.name} ({phaseToLocation(selectedPhase)?.location_uuid}) | Currently Present Asset List</Text>
                    <div style={{position: 'relative', maxWidth: '1800px', margin: 'auto'}}>
                        <LoadingOverlay visible={!loadAttempted} overlayBlur={2}/>
                        <Table striped highlightOnHover>
                            <thead>
                            <tr>
                                <th>{[0, 1].includes(selectedPhase) && <Checkbox
                                    checked={selectedAssets.length === assets.length}
                                    onChange={() => {
                                        setSelectedAssets(draft => {
                                            if(draft.length === assets.length) return []
                                            else return [...assets]
                                        })
                                    }}
                                />}</th>
                                <th>EPC</th>
                                <th>Name</th>
                                <th>Product SKU</th>
                                <th>Updated</th>
                                <th>Created</th>
                                {[2, 3, 4].includes(selectedPhase) && <th>Parts</th>}
                                {[2, 3].includes(selectedPhase) && <th>Valid</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {(assets ?? []).slice().sort((a, b) => parseInt(a.product ?? '0') - parseInt(b.product ?? '0')).map(asset => (
                                <tr key={asset.epc_code}>
                                    <td><Checkbox checked={selectedAssets?.find(e => e.id === asset.id) ?? false} onChange={e => {
                                        setSelectedAssets(draft => {
                                            let oldidx = draft.findIndex(e => e.id === asset.id);
                                            if(oldidx !== -1) draft.splice(oldidx, 1);
                                            else {
                                                if(selectedPhase === 2) return [asset]
                                                else draft.push(asset);
                                            }
                                        });
                                    }} /></td>
                                    <td>{asset.epc_code}</td>
                                    <td>{asset.name}</td>
                                    <td>{asset.product}</td>
                                    <td>{dayjs(asset.updated).format('HH:mm:ss DD.MM.YYYY')}</td>
                                    <td>{dayjs(asset.created).format('HH:mm:ss DD.MM.YYYY')}</td>
                                    {[2, 3, 4].includes(selectedPhase) && <td>
                                        <HoverCard shadow="md" openDelay={400} position="top">
                                            <HoverCard.Target><Text style={{cursor: 'pointer', textDecoration: 'dotted salmon underline'}}>{asset.metadata?.components?.length ?? 0}</Text></HoverCard.Target>
                                            <HoverCard.Dropdown>
                                                <Stack>
                                                    {(asset.metadata?.components ?? []).map(component => <Text>EPC: {component}</Text>)}
                                                    {!asset.metadata?.components && <Text>No parts in asset</Text>}
                                                </Stack>
                                            </HoverCard.Dropdown>
                                        </HoverCard>
                                    </td>}
                                    {[2, 3].includes(selectedPhase) && <td>
                                        {asset.metadata?.qc_valid === true ? <Text color="green">Yes</Text>
                                        : asset.metadata?.qc_valid === false ? <Text color="red">No</Text> : 'Unchecked'}
                                    </td>}
                                </tr>
                            ))}
                            {(assets ?? []).length === 0 && <tr><td colSpan={9}><Group position="center" style={{height: 425, alignItems: 'center'}}>
                                <Text>No Assets Present In Location</Text>
                            </Group></td></tr>}
                            </tbody>
                        </Table>
                    </div>
                </Box>
            </Group>
        </div>
    )
}
export default Industrial;