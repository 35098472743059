import {Route, Routes} from "react-router-dom";
import ProductDetail from "./ProductDetail";
import ProductList from "./ProductList";

const ProductMaster = props => {
    return (
            <Routes>
                <Route path="/:sku" element={<ProductDetail />} />
                <Route index element={<ProductList />} />
            </Routes>
            )
};
export default ProductMaster;
