import React from 'react';
import {HorizontalSeparator, ListCard, ListTopActions, CreateEditModal} from "../../components/RustyRadeaComponents";
import {createStyles, Menu, useMantineTheme, Text} from "@mantine/core";
import AppContext from "../../config/AppContext";

import {useLocations} from '../../hooks/RustyRadeaHooks';
import {IconArrowFork, IconCircleFilled, IconPlus, IconRefresh} from "@tabler/icons-react";
import LocationForm from "./LocationForm";
import LocationGroupForm from "./LocationGroupForm";
import {useDebouncedState} from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
    contentWrapper: {
        margin: 'auto',
        width: '90%',
        maxWidth: 900
    },

    groupWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch'
    },
    groupBundler: {
        width: 3,
        height: '100%',
        filter: 'blur(3px)',
        borderRadius: 1,
        marginRight: 10
    },
    groupContainer: {
        flex: 1
    },
    cardContentTitle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    cardContentExtra: {
        marginTop: 0,
        '& > :first-child': {
            marginRight: 20
        }
    },
    locationUuid: {
        ['@media(max-width: ' + theme.breakpoints.md + 'px)']: {
            display: 'none'
        }
    }
}));

const GroupContainerRecursive = props => {
    return <GroupContainer {...props} />
}

const GroupContainer = props => {
    const { classes } = useStyles();
    const theme = useMantineTheme();

    const [locations, setLocations] = React.useState(props.locations);

    React.useEffect(() => {
        setLocations(props.locations.filter(e => e.name.toLowerCase().indexOf(props.search) !== -1));
    }, [props.locations, props.search]);

    return (
        <>
        <HorizontalSeparator
            size="sm"
            text={<span>{Array(props.level).fill(0).map((i, index) => <IconCircleFilled key={i + index} size={6} style={{transform: 'translateY(-2px)'}} />)} {props.current?.name ?? 'Location Root'}</span>}
            color="primary"
            top={20}
        />
        <div className={classes.groupWrapper}>
            <div
                className={classes.groupBundler}
                style={{
                    backgroundColor: props.current?.color ? props.current.color + 'bb' : theme.colors[theme.primaryColor][6] + 'bb'
                }}
            />
            <div className={classes.groupContainer}>
                {locations.filter(e => e.location_group === (props.current?.id ?? null)).map(location => (
                    <ListCard key={location.location_uuid} link={location.location_uuid} onClick={() => null} top={5}>
                        <div className={classes.cardContentTitle}><Text>{location.name}</Text><Text color="dimmed" size="sm" className={classes.locationUuid}>{location.location_uuid}</Text></div>
                        <div className={classes.cardContentExtra}><Text size="xs" span>Count: {location?.count ?? 0}</Text><Text size="xs" span>Role: {location.role}</Text></div>
                    </ListCard>
                ))}
                {props.groups.filter(e => e.parent === (props.current?.id ?? null)).map(group => (
                    <GroupContainerRecursive key={group.id} current={group} prev={props.current?.id ?? null} groups={props.groups} locations={props.locations} level={props.level + 1} search={props.search} />
                ))}
            </div>
        </div>
        </>
    )
};

const LocationList = _ => {
    const { classes } = useStyles();
    const { groups, locations, update } = useLocations();
    const context = React.useContext(AppContext);
    
    // eslint-disable-next-line no-unused-vars
    const [ search, setSearch ] = useDebouncedState('', 200);
    const [ showCreate, setShowCreate ] = React.useState(false);
    const [ showCreateGroup, setShowCreateGroup ] = React.useState(false);
    const [ showRadea, setShowRadea ] = React.useState(false);

    return (
        <div>
            <HorizontalSeparator size="lg" text="Location Listing" color="primary" top={0} />
            <ListTopActions
                onSearch={value => setSearch(value)}
                createText="Add A New Location"
                onCreate={() => setShowCreate(true)}
                extraMethods={<Menu.Dropdown>
                    <Menu.Label>General</Menu.Label>
                    <Menu.Item icon={<IconPlus />} onClick={() => setShowCreateGroup(true)}>Add A New Location Group</Menu.Item>
                    <Menu.Item icon={<IconRefresh />} onClick={update} color="blue">Update Data</Menu.Item>
                    {context.config?.find(e => e.key === 'radea_integration_enabled')?.value &&
                        <Menu.Item icon={<IconArrowFork />} onClick={() => setShowRadea(true)} color="teal">Show RADEA Locations</Menu.Item>
                    }
                </Menu.Dropdown>}
            />
            <div className={classes.contentWrapper}>
                <GroupContainer current={null} prev={null} groups={groups} locations={locations} level={0} search={search} />
            </div>
            <CreateEditModal open={showCreate} close={() => {setShowCreate(false); update();}} modalTitle="Create A New Location">
                <LocationForm close={() => {setShowCreate(false); update();}} />
            </CreateEditModal>
            <CreateEditModal open={showCreateGroup} close={() => {setShowCreateGroup(false); update();}} modalTitle="Create A New Location Group">
                <LocationGroupForm close={() => {setShowCreateGroup(false); update();}} />
            </CreateEditModal>
        </div>
    )
};
export default LocationList;