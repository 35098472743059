import React from 'react';
import {Routes, Route, NavLink, Navigate} from 'react-router-dom';
import {
    createStyles,
    Header,
    Group,
    Burger,
    ActionIcon,
    useMantineTheme,
    AppShell, Drawer
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { IconSettings, IconUser } from '@tabler/icons-react';

import './App.css';

// General Components
import RustyRadeaLogo from './general/RustyRadeaLogo';
import PageNotFound from './general/PageNotFound';

// Navigation Components
import Settings from './general/Settings';
import User from './general/User';
import AiMaster from "./main/ai/AiMaster";

import ConfigurationMaster from "./main/configuration/AccessConfiguration";
import LocationMaster from "./main/locations/AccessLocations";
import ProductMaster from "./main/products/AccessProducts";
import AssetMaster from "./main/assets/AccessAssets";
import ReaderMaster from "./main/readers/AccessReaders";
import FloorplanMaster from "./main/floorplans/AccessFloorplans";
import Healthcare from "./main/usecase/Healthcare";
import Industrial from "./main/usecase/Industrial";

const useStyles = createStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: "0 30px"
    },
    navigationWrapper: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        alignItems: 'center'
    },

    links: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
        marginLeft: 35,
        height: '100%'
    },

    burger: {
        marginLeft: 20,
    },

    link: {
        textDecoration: 'none',
        height: '100%',
        boxSizing: 'border-box'
    },
    linkButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: 90,
        textAlign: 'center',
        lineHeight: 1,
        padding: '8px 12px',
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        fontSize: theme.fontSizes.sm,
        fontWeight: 'bold',
        flexBasis: 0,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },

    linkButtonActive: {
        '&, &:hover': {
            backgroundImage: theme.fn.gradient({ from: theme.colors[theme.primaryColor][4], to: theme.colors[theme.primaryColor][9] }),
            borderBottom: '1px solid ' + theme.colors[theme.primaryColor][1],
            color: '#fff'
        },
    },
    navLinkText: {
        textTransform: 'capitalize'
    },
    linkIconActive: {
        '& button': {
            background: theme.colorScheme === 'dark' ? theme.colors[theme.primaryColor][1] : theme.colors[theme.primaryColor][0],
            boxShadow: `${theme.colors[theme.primaryColor][4]} -3px 0 18px -7px, ${theme.colors[theme.primaryColor][4]} 3px 0 18px -7px,
                        ${theme.colors[theme.primaryColor][4]} 0 -3px 18px -7px, ${theme.colors[theme.primaryColor][4]} 0 3px 18px -7px`
        },
        '& svg': {
            color: '#000'
        },
        '& button:hover': {
            background: theme.colorScheme === 'dark' ? theme.colors[theme.primaryColor][2] : theme.colors[theme.primaryColor][1],
        }
    },

    rightIcon: {
        padding: 3,
        margin: '0 10px'
    },
    appBase: {
        width: '100%',
        minHeight: 'calc(100vh - 60px)',
        backgroundImage: theme.colorScheme === 'dark' ? theme.fn.gradient({ from: '#000', to: theme.colors[theme.primaryColor]?.[10] ?? '#030515' })
                                                      : theme.fn.gradient({ from: '#fff', to: theme.fn.lighten(theme.colors[theme.primaryColor][0], 0.8) }),
        padding: 16,
        boxSizing: 'border-box'
    },
    drawerLink: {
        width: '100%',
        height: 50,
        marginBottom: 15
    }
}));

const App = props => {
    const [opened, { toggle }] = useDisclosure(false);
    const { classes, cx } = useStyles();
    const theme = useMantineTheme();

    const links = ['locations', 'products', 'assets', 'readers', 'floor plans', 'config', 'NLP', 'healthcare', 'industrial'];

    return (
        <AppShell
            padding={0}
            header={
            <Header height={60} mb={120}>
                <div className={classes.header}>
                    <div className={classes.navigationWrapper}>
                        <RustyRadeaLogo />
                        <Group spacing={5} className={classes.links}>
                            {links.slice(0, 5).map(link => (
                                <NavLink key={link} to={`/${link}`} className={({ isActive }) => cx(classes.link, classes.linkButton, { [classes.linkButtonActive]: isActive })}>
                                    <div className={classes.navLinkText} style={{textTransform: 'capitalize'}}>{link}</div>
                                </NavLink>
                            ))}
                        </Group>
                    </div>
                    <div className={classes.navigationWrapper}>
                        <NavLink to="/settings" className={({ isActive }) => cx(classes.link, { [classes.linkIconActive]: isActive })}>
                            <div className="centeredContent">
                                <ActionIcon color={theme.primaryColor} variant="filled" size="md" className={classes.rightIcon}><IconSettings /></ActionIcon>
                            </div>
                        </NavLink>
                        <NavLink to="/user" className={({ isActive }) => cx(classes.link, { [classes.linkIconActive]: isActive })}>
                            <div className="centeredContent">
                                <ActionIcon color={theme.primaryColor} variant="filled" size="md" className={classes.rightIcon}><IconUser /></ActionIcon>
                            </div>
                        </NavLink>
                        <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
                    </div>
                </div>
                <Drawer
                    opened={opened}
                    onClose={toggle}
                    title="IDCAT Menu"
                    padding="md"
                    size="xl"
                >
                    {links.map(link => (
                        <NavLink onClick={toggle} key={link} to={`/${link}`} className={({ isActive }) => cx(classes.link, classes.linkButton, classes.drawerLink, { [classes.linkButtonActive]: isActive })}>
                            <div className={classes.navLinkText} style={{textTransform: 'capitalize'}}>{link}</div>
                        </NavLink>
                    ))}
                </Drawer>
            </Header>
            }
        >
            <div id="appBase" className={classes.appBase}>
                <Routes>
                    <Route index element={<Navigate to="/locations" replace={true} />} />
                    <Route path="/locations/*" element={<LocationMaster />} />
                    <Route path="/products/*" element={<ProductMaster />} />
                    <Route path="/assets/*" element={<AssetMaster />} />
                    <Route path="/readers/*" element={<ReaderMaster />} />
                    <Route path="/floor plans/*" element={<FloorplanMaster />} />
                    <Route path="/config/*" element={<ConfigurationMaster />} />
                    <Route path="/nlp/*" element={<AiMaster />} />
                    <Route path="/healthcare/*" element={<Healthcare />} />
                    <Route path="/industrial/*" element={<Industrial />} />

                    <Route path="/settings" element={<Settings setPrimaryColor={props.setPrimaryColor} />} />
                    <Route path="/user" element={<User />} />

                    <Route path="*" element={<PageNotFound/>} />
                </Routes>
            </div>
        </AppShell
>
    );
}
export default App;
