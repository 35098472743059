import React from 'react';
import {useImmer} from 'use-immer';
import { showNotification } from '@mantine/notifications';

import AppContext from "../config/AppContext";

import ENDPOINTS from '../config/endpoint';
import App from "../App";

export const useLocations = props => {
    const config = React.useContext(AppContext);

    const [locationGroups, setLocationGroups] = useImmer(window.rustyradea?.locationgroups ?? []);
    const [locations, setLocations] = useImmer(window.rustyradea?.locations ?? []);
    const [locationTagHospitality, setLocationTagHospitality] = useImmer([]);
    const [loadAttempted, setLoadAttempted] = React.useState(false);
    const [hospitalityLoadAttempted, setHospitalityLoadAttempted] = React.useState(false);

    const fetchGroupData = () => {
        fetch(ENDPOINTS('locationgroups'), {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        }).then(response => {
            if(!response.ok) {
                errorNotification('group');
            }
            return response.json();
        }).then(data => {
            window.rustyradea.locationgroups = data;
            setLocationGroups(data);
        }).catch(e => {
            errorNotification('group');
            console.log('Error in location group fetch:', e);
        }).finally(() => setLoadAttempted(true));
    };
    const fetchLocationData = () => {
        fetch(ENDPOINTS('locations'), {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        }).then(response => {
            if(!response.ok) {
                errorNotification('location');
            }
            return response.json();
        }).then(data => {
            window.rustyradea.locations = data;
            let params = new URLSearchParams({ locations: data.reduce((a, c) => {a.push(c.location_uuid); return a}, []) });
            fetch(ENDPOINTS('location_hospitality_aggregates') + '?' + params, {
                headers: {'Authorization': 'Bearer ' + config.accessToken}
            }).then(response => {
                if(!response.ok) errorNotification('hospitality');
                return response.json();
            }).then(hdata => {
                for(const item in data) {
                    let aggregates = [];
                    hdata.forEach(aggregate => {
                        if(aggregate.location === data[item].location_uuid) aggregates.push(aggregate)
                    });
                    data[item].location_history_aggregates = aggregates;
                }
                setLocations(data);
            })
        }).catch(e => {
            errorNotification('location');
            console.log('Error in location fetch:', e);
        }).finally(() => setLoadAttempted(true));
    };
    const fetchTagHospitalityData = (location, filters) => {
        let params = new URLSearchParams(filters);
        fetch(`${ENDPOINTS('location_hospitality', {location_uuid: location})}?${params}`, {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        }).then(response => {
            if(!response.ok) {
                errorNotification('hospitality');
            }
            return response.json();
        }).then(data => {
            if(Array.isArray(data)) setLocationTagHospitality(data);
        }).catch(e => {
            errorNotification('hospitality');
            console.log('Error in location fetch:', e);
        }).finally(() => setHospitalityLoadAttempted(true));
    };
    const errorNotification = type => {
        showNotification({
            color: 'red',
            title: 'Failed to fetch ' + type === 'group' ? 'location groups' : type === 'hospitality' ? 'tag time spent' : 'locations',
            message: 'The request to fetch ' + (type === 'group' ? 'location groups' : type === 'hospitality' ? 'tag time spent' : 'locations') + ' failed. Please refresh the page and try again',
        });
    };
    const updateData = () => {
        fetchGroupData();
        fetchLocationData();
    }

    React.useEffect(() => {
        if(loadAttempted === false && !window.rustyradea?.locationgroups) {
            fetchGroupData();
        }
        if(loadAttempted === false && !window.rustyradea?.locations) {
            fetchLocationData();
        }
        if(window.rustyradea?.locationgroups && window.rustyradea?.locations) setLoadAttempted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        groups: locationGroups,
        locations: locations,
        loadAttempted: loadAttempted,
        update: updateData,

        hospitality: locationTagHospitality,
        getHospitality: fetchTagHospitalityData,
        hospitalityLoadAttempted: hospitalityLoadAttempted
    };
};