import {Route, Routes} from "react-router-dom";
import FloorplanList from "./FloorplanList";
import FloorplanDetail from "./FloorplanDetail";

const FloorplanMaster = props => {
    return (
        <Routes>
            <Route path="/:floorplan_id" element={<FloorplanDetail />} />
            <Route index element={<FloorplanList />} />
        </Routes>
        )
};
export default FloorplanMaster;