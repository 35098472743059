import {Menu, Text} from "@mantine/core";
import {IconRefresh} from "@tabler/icons-react";
import React from "react";
import {HorizontalSeparator, ListTopActions} from "../../components/RustyRadeaComponents";

const Pipelines = props => {
    const [ showCreate, setShowCreate ] = React.useState(false);
    
    return (
        <div>
            <HorizontalSeparator size="lg" text="Data Pipelines" color="primary" top={10} />
            <ListTopActions
                createText="Add A New Pipeline"
                onCreate={() => setShowCreate(true)}
                extraMethods={<Menu.Dropdown>
                    <Menu.Label>General</Menu.Label>
                    <Menu.Item icon={<IconRefresh />} onClick={() => null} color="blue">Update Data</Menu.Item>
                </Menu.Dropdown>}
            />
            <div style={{ position: 'relative', maxWidth: '1800px', margin: 'auto' }}>
                <Text>Pipelines are in progress for now...</Text>
            </div>
        </div>
    )
}
export default Pipelines;