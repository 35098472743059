import React from 'react';
import {Button, createStyles, NumberInput, Select, TextInput} from "@mantine/core";
import {useImmer} from "use-immer";
import ENDPOINTS from "../../config/endpoint";
import {showNotification} from "@mantine/notifications";
import {useLocations} from "../../hooks/RustyRadeaHooks";

import AppContext from "../../config/AppContext";

const useStyles = createStyles(theme => ({
    formContainer: {
        maxWidth: 1800,
        margin: 'auto'
    },
    formInput: {
        marginTop: 7
    },
    submitButton: {
        marginTop: 30
    }
}));

const ZebraZIoTCConfigurationForm = props => {
    const config = React.useContext(AppContext);

    const { classes } = useStyles();
    const { locations, groups } = useLocations();
    
    const [ radeaLocations, setRadeaLocations ] = React.useState([]);
    const [formValues, setFormValues] = useImmer({
        description: props.edit?.description ?? (props.defaultDescription ?? ''),
        reader_name: props.edit?.reader_name ?? (props.defaultName ?? ''),
        antenna_port: props.edit?.antenna_port ?? (props.defaultAntenna ?? ''),
        location: props.edit?.location ?? (props.defaultLocation ?? ''),
        sendToRadeaLocation: props.edit?.sendToRadeaLocation ?? (props.defaultRadeaLocation ?? ''),
    });
    
    React.useEffect(() => {
        const get_radea_locations = async () => {
            fetch(ENDPOINTS('radea_locations'), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + config.accessToken
                }
            }).then(async response => {
                if(!response.ok) console.log('Failed to get RADEA locations')
                else {
                    let data = await response.json();
                    let leaflocs = data.filter(e => data.findIndex(n => n.parent === e.uuid) === -1);
                    setRadeaLocations(leaflocs);
                }
            }).catch(error => {
                console.warn(error);
            });
        }
        if(config.config?.find(e => e.key === 'radea_integration_enabled')?.value === '1') get_radea_locations();
    }, []);

    const submit = () => {
        if(!props.edit) {
            fetch(ENDPOINTS('zebra_config_list'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + config.accessToken
                },
                body: JSON.stringify(formValues)
            }).then(response => {
                if(!response.ok) failNotification()
                else {
                    successNotification();
                    props.close();
                }
            }).catch(error => {
                errorNotification();
                console.warn(error);
            });
        }
    };
    const failNotification = () => showNotification({
        title: 'Failed to add/edit ZIoTC configuration',
        message: 'Adding ZIoTC configuration failed. Please check your inputs and try again',
        color: 'red'
    });
    const successNotification = () => showNotification({
        title: 'Added/Edited ZIoTC configuration',
        color: 'green'
    });
    const errorNotification = () => showNotification({
        title: 'Something went wrong in the request',
        message: 'Please wait a while and try again. If the problem persists, please contact support',
        color: 'red'
    });

    return (
        <div className={classes.formContainer}>
            <TextInput
                value={formValues.description}
                onChange={e => setFormValues(draft => {draft.description = e.target.value})}
                placeholder="Description"
                label="Description"
                description="Description of the ZIoTC configuration"
                withAsterisk={!props.edit}
                className={classes.formInput}
            />
            <TextInput
                value={formValues.reader_name}
                onChange={e => setFormValues(draft => {draft.reader_name = e.target.value})}
                placeholder="Reader name"
                description="Reader name as set in ZIoTC HTTP POST URL endpoint"
                label="Reader name"
                className={classes.formInput}
            />
            <NumberInput
                value={formValues.antenna_port}
                onChange={value => setFormValues(draft => {draft.antenna_port = value})}
                placeholder="Antenna Port"
                description="Antenna port which this configuration listens to. Set 0 to use all antennas"
                label="Antenna Port"
                className={classes.formInput}
            />
            <Select
                value={formValues.location ?? null}
                onChange={value => setFormValues(draft => {draft.location = value})}
                placeholder="Location of reading"
                label="Location of reading"
                data={locations.map(location => ({label: location.name, value: location.location_uuid, group: groups.find(e => e.id === location.location_group)?.name ?? 'Root'}))}
                description="The locations the readings of the reader are placed into"
                className={classes.formInput}
            />
            {config.config?.find(e => e.key === 'radea_integration_enabled')?.value === '1' && <Select
                value={formValues.sendToRadeaLocation ?? null}
                onChange={value => setFormValues(draft => {
                    draft.sendToRadeaLocation = value
                })}
                placeholder="RADEA.IO Forwarding"
                label="RADEA.IO Forwarding"
                data={radeaLocations.map(location => ({label: location.name, value: location.uuid}))}
                description="RADEA.IO location to send all incoming Zebra reading data into"
                className={classes.formInput}
            />}
            <div className={classes.submitButton}>
                <Button radius="xs" fullWidth onClick={submit}>Submit</Button>
            </div>
        </div>
        )
};
export default ZebraZIoTCConfigurationForm;