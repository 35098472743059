import {createStyles, Group, Paper, Text, ThemeIcon} from "@mantine/core";
import {IconArrowDownRight, IconArrowUpRight, IconCircle} from "@tabler/icons-react";

const useStyles = createStyles(theme => ({
    label: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
    cardContainer: {
        background: theme.colorScheme === 'dark' ? '#000000' : '#ffffff'
    }
}));

/**
 * StatsCard component shows a statistic (specifically numeric) and provides extra insight into the difference related to another period
 * @param {String}  props.title                                  Name of this statistic
 * @param {String | node}  props.formattedValue                  Formatted value if you want to show it on a way other than just a number
 * @param {number}  props.value                                  The current value of the statistic
 * @param {number} props.prevValue                               Previous value of the statistic used to calculate the difference
 * @param {String} [props.interval='last month']                 Time interval passed between current and previous counts
 * @param {bool} [props.showDiff=true]                           Whether to show a difference to a previous value or not
 *
 * @returns {React.ReactElement} The DetailViewHeader component
*/
export const StatsCard = props => {
    const { classes } = useStyles();

    let diffPercentage = (1 / (props.prevValue / (100 * props.value)) - 100).toFixed(1);
    const DiffIcon = props.value === props.prevValue ? IconCircle : diffPercentage > 0 ? IconArrowUpRight : IconArrowDownRight;

    return (
        <Paper withBorder p="xs" radius="md" key={props.title} className={classes.cardContainer}>
            <Group position="apart">
                <div>
                    <Text
                        color="dimmed"
                        transform="uppercase"
                        weight={700}
                        size="xs"
                        className={classes.label}
                    >
                        {props.title}
                    </Text>
                    <Text weight={700} size="xl" transform="capitalize">
                        {props.formattedValue ?? props.value}
                    </Text>
                </div>
                {props.showDiff && <ThemeIcon
                    color="gray"
                    variant="light"
                    sx={(theme) => ({ color: props.value === props.prevValue ? theme.colors.yellow[6] : diffPercentage > 0 ? theme.colors.teal[6] : theme.colors.red[6] })}
                    size={38}
                    radius="md"
                    >
                    <DiffIcon size={28} stroke={1.5} />
                </ThemeIcon>}
            </Group>
            <Text color="dimmed" size="sm" mt={0}>
                {props.showDiff && <>
                    {props.value === props.prevValue && 'No change compared to ' + props.interval}
                    {props.value !== props.prevValue && <>
                    <Text component="span" color={diffPercentage > 0 ? 'teal' : 'red'} weight={700}>
                        {props.prevValue !== 0 ? diffPercentage + '%' : 'Uncalculable'}
                    </Text>{' '}{diffPercentage > 0 ? 'increase' : 'decrease'} compared to {props.interval}
                    </>}
                </>}
                {!props.showDiff && props.interval}
            </Text>
        </Paper>
    )
};
/**
 * Default property declarator for StatsCard component
*/
StatsCard.defaultProps = {
    interval: 'last month',
    showDiff: true
};

