import React from 'react';
import {useImmer} from 'use-immer';
import { showNotification } from '@mantine/notifications';

import AppContext from "../config/AppContext";

import ENDPOINTS from '../config/endpoint';

export const useReaders = props => {
    const config = React.useContext(AppContext);

    const [readers, setReaders] = useImmer([]);
    const [impinjScConfigs, setImpinjScConfigs] = useImmer([]);
    const [zebraConfigs, setZebraConfigs] = useImmer([]);
    const [loadAttempted, setLoadAttempted] = React.useState(false);

    const fetchReaderData = (filters={}) => {
        let params = new URLSearchParams(filters);
        fetch(`${ENDPOINTS('reader_list')}?${params}`, {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        }).then(response => {
            if(!response.ok) {
                errorNotification();
            }
            return response.json();
        }).then(data => {
            setReaders(draft => data)
        }).catch(e => {
            errorNotification();
            console.log('Error in reader fetch:', e);
        }).finally(() => setLoadAttempted(true));
    };
    const fetchImpinjScData = (filters = {}) => {
        let params = new URLSearchParams(filters);
        fetch(`${ENDPOINTS('impinj_sc_config_list')}?${params}`, {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        }).then(response => {
            if(!response.ok) {
                errorNotification('Impinj configurations');
            }
            return response.json();
        }).then(data => {
            setImpinjScConfigs(data);
        }).catch(e => {
            errorNotification('Impinj configurations');
            console.log('Error in fetching Impinj reader configs: ', e);
        })
    }
    const fetchZebraZIoTCData = (filters = {}) => {
        let params = new URLSearchParams(filters);
        fetch(`${ENDPOINTS('zebra_config_list')}?${params}`, {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        }).then(response => {
            if(!response.ok) {
                errorNotification('Zebra configurations');
            }
            return response.json();
        }).then(data => {
            setZebraConfigs(data);
        }).catch(e => {
            errorNotification('Zebra configurations');
            console.log('Error in fetching Zebra reader configs: ', e);
        })
    }
    const errorNotification = (typetext = 'readers') => {
        showNotification({
            color: 'red',
            title: 'Failed to fetch readers',
            message: 'The request to fetch ' + typetext + ' failed. Please refresh the page and try again',
        });
    };
    const fetchData = (filters={}) => {
        let success = 0;
        Object.entries(filters).forEach(([key, value]) => value ? success++ : null);
        if (success === Object.keys(filters).length) {
            fetchReaderData(filters);
        }
    }

    return {
        readers: readers,
        fetch: fetchData,
        loadAttempted: loadAttempted,

        fetchImpinj: fetchImpinjScData,
        impinjScConfigs: impinjScConfigs,
        fetchZebra: fetchZebraZIoTCData,
        zebraConfigs: zebraConfigs
    };
};
