import React from 'react';
import {
    CreateEditModal,
    DetailViewTabContainer,
    HorizontalSeparator,
    ListTopActions
} from "../../components/RustyRadeaComponents";
import {LoadingOverlay, Menu, Table} from "@mantine/core";
import {useProducts} from "../../hooks/RustyRadeaHooks";
import {IconRefresh} from "@tabler/icons-react";
import ProductForm from "../products/ProductForm";
import {useDebouncedState} from "@mantine/hooks";

const ProductsInLocation = props => {
    const { locations, fetchLocationProducts, loadAttempted } = useProducts();

    const [search, setSearch] = useDebouncedState('', 400);
    const [showCreate, setShowCreate] = React.useState(false);

    React.useEffect(() => {
        if(props.location) updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location, search]);
    const updateData = () => {
        let filters = {};
        if(search) filters.search = search;
        fetchLocationProducts(props.location, filters, true);
    }

    return (
        <DetailViewTabContainer>
            <HorizontalSeparator text="Products In Location" size="sm" />
            <ListTopActions
                onSearch={value => setSearch(value)}
                createText="Add A New Product"
                onCreate={() => setShowCreate(true)}
                extraMethods={<Menu.Dropdown>
                    <Menu.Label>Product List Actions</Menu.Label>
                    <Menu.Item icon={<IconRefresh size={14} />} onClick={updateData} color="blue">Update Data</Menu.Item>
                </Menu.Dropdown>}
            />
            <div style={{ position: 'relative' }}>
                <LoadingOverlay visible={!loadAttempted} overlayBlur={2} />
                <Table striped highlightOnHover>
                    <thead>
                        <tr><th>Name</th><th>SKU</th><th>Total Count</th><th>In Location</th></tr>
                    </thead>
                    <tbody>
                        {(locations[props.location] ?? []).map(product => (
                            <tr key={product.sku}>
                                <td>{product.name}</td><td>{product.sku}</td><td>{product.count?.[0]?.count ?? 0}</td><td>{product.location_count?.[0]?.count ?? 0}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <CreateEditModal open={showCreate} close={() => setShowCreate(false)} modalTitle="Create A New Product">
                <ProductForm close={() => {setShowCreate(false); updateData();}} />
            </CreateEditModal>
        </DetailViewTabContainer>
    );
}
export default ProductsInLocation;