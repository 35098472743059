import {useLocations as useLocationsHook} from "./useLocations";
import {useProducts as useProductsHook} from "./useProducts";
import {useAssets as useAssetsHook} from "./useAssets";
import {useInventory as useInventoryHook} from "./useInventory";
import {useReaders as useReadersHook} from "./useReaders";

export const useLocations = useLocationsHook;
export const useProducts = useProductsHook;
export const useAssets = useAssetsHook;
export const useInventory = useInventoryHook;
export const useReaders = useReadersHook;