import {ActionIcon, Group, Menu, PasswordInput, Table, Text, TextInput, useMantineTheme} from "@mantine/core";
import {useDebouncedState} from "@mantine/hooks";
import {showNotification} from "@mantine/notifications";
import {IconCheck, IconDeviceFloppy, IconRefresh} from "@tabler/icons-react";
import React from "react";
import {HorizontalSeparator, ListTopActions} from "../../components/RustyRadeaComponents";
import AppContext from "../../config/AppContext";
import ENDPOINTS from "../../config/endpoint";

const ConfigurationValues = props => {
    const theme = useMantineTheme();
    const context = React.useContext(AppContext);
    
    const [search, setSearch] = useDebouncedState('', 200);
    
    const foundKeys = React.useMemo(() => (
        (context?.config ?? []).filter(e => e.key.toLowerCase().indexOf(search) !== -1)
    ), [context, search]);
    
    const updateConfigurationValue = config => {
        window.fetch(ENDPOINTS('edit_config', {config_key: config.key}), {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + context.accessToken
            },
            body: JSON.stringify({value: config.value})
        }).then(response => {
            if(!response.ok) showNotification({
                title: 'Failed to update configuration kye value',
                message: 'Updating configuration failed. Please refresh the page and try again',
                color: 'red'
            });
            else {
                showNotification({
                    title: 'Updated configuration key value',
                    color: 'green'
                });
            }
        }).catch(error => {
            console.warn(error);
        });
    }
    
    return (
        <div>
            <HorizontalSeparator size="lg" text="Configuration Keys" color="primary" top={10} />
            <ListTopActions
                onSearch={value => setSearch(value)}
                createText="Add A New Floorplan"
                //onCreate={() => setShowCreate(true)}
                extraMethods={<Menu.Dropdown>
                    <Menu.Label>General</Menu.Label>
                    <Menu.Item icon={<IconRefresh />} onClick={() => null} color="blue">Update Data</Menu.Item>
                </Menu.Dropdown>}
            />
            <div style={{ position: 'relative', maxWidth: '1800px', margin: 'auto' }}>
                <Table>
                    <thead>
                        <tr>
                            <th>Key</th>
                            <th>Description</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(foundKeys ?? []).map((config) => (
                            <tr key={config.key}>
                                <td>{config.key}</td>
                                <td><Text maw={500}>{config.description}</Text></td>
                                <td>
                                    <Group>
                                        {config.value_type !== 'Hidden' && <TextInput
                                            style={{flexGrow: 1}}
                                            value={config.value}
                                            disabled={!config.user_editable}
                                            onChange={e => context.setConfig(draft => {
                                                draft.find(e => e.key === config.key).value = e.target.value;
                                            })}
                                        />}
                                        {config.value_type === 'Hidden' && <PasswordInput
                                            style={{flexGrow: 1}}
                                            value={config.value}
                                            disabled={!config.user_editable}
                                            onChange={e => context.setConfig(draft => {
                                                draft.find(e => e.key === config.key).value = e.target.value;
                                            })}
                                        />}
                                        <ActionIcon
                                            color={theme.primaryColor}
                                            variant="subtle"
                                            disabled={!config.user_editable}
                                            onClick={() => updateConfigurationValue(config)}
                                        >
                                            <IconDeviceFloppy />
                                        </ActionIcon>
                                    </Group>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
export default ConfigurationValues;
