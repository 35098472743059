import React from 'react';
import {useParams} from "react-router-dom";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {useLocations} from "../../hooks/useLocations";
import {
    DetailViewHeader,
    StatsCard,
    HorizontalSeparator,
    DetailViewTabContainer,
    InventoryGraph,
    TagHospitalityGraph, CreateEditModal
} from "../../components/RustyRadeaComponents";
import {createStyles, Menu, SimpleGrid, Tabs, Text, useMantineTheme} from "@mantine/core";
import {
    IconCheese,
    IconLocation,
    IconPencil,
    IconRefresh,
    IconSquarePlus,
    IconTag,
    IconTrash
} from "@tabler/icons-react";
import {useViewportSize} from "@mantine/hooks";

import ProductsInLocation from "./ProductsInLocation";
import AssetsInLocation from "./AssetsInLocation";
import LocationForm from "./LocationForm";

const useStyles = createStyles(theme => ({
    pageWrapper: {
        maxWidth: 1800,
        margin: 'auto'
    },
    dataWrapper: {
        paddingTop: 15
    },
    dataBlockWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'

    },
    dataBlock: {
        width: '50%',
        minWidth: 450,
        boxSizing: 'border-box',
        border: 'thin solid',
        borderColor: theme.colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.13)' : 'rgba(0, 0, 0, 0.5)',
        padding: 10,

        ['@media(max-width: ' + theme.breakpoints.md + 'px)']: {
            '&': {
                width: '100%'
            }
        }
    },
    blockHeaderText: {
        letterSpacing: 1
    },
    chartWrapper: {
        marginTop: 15,
        backgroundColor: theme.colorScheme === 'dark' ? '#000' : '#fff'
    }
}));

const LocationDetail = props => {
    dayjs.extend(duration);
    dayjs.extend(relativeTime);
    const theme = useMantineTheme();
    const {location_uuid} = useParams();
    const {classes} = useStyles();
    const {locations, update} = useLocations();
    const {width} = useViewportSize();

    const [locationData, setLocationData] = React.useState(locations.find(e => e.location_uuid === location_uuid) ?? {});
    const [showEdit, setShowEdit] = React.useState(false);

    React.useEffect(() => {
        setLocationData(locations.find(e => e.location_uuid === location_uuid) ?? {})
    }, [location_uuid, locations]);

    return (
        <div className={classes.pageWrapper}>
            <DetailViewHeader
                type={'Location'}
                headerText={locationData.name}
                rightText={locationData.location_uuid}
                actions={<>
                    <Menu.Label>Data</Menu.Label>
                    <Menu.Item icon={<IconRefresh size={18}/>} color="cyan" onClick={() => update()}>Update Data</Menu.Item>
                    <Menu.Item icon={<IconPencil size={18}/>}
                               color={theme.colorScheme === 'dark' ? "orange.3" : "orange.6Dt"} onClick={() => setShowEdit(true)}>Edit</Menu.Item>

                    <Menu.Label>Related</Menu.Label>
                    <Menu.Item icon={<IconSquarePlus size={18}/>}>Add New Related Location</Menu.Item>

                    <Menu.Divider/>

                    <Menu.Label>Danger Zone</Menu.Label>
                    <Menu.Item icon={<IconTrash size={18}/>} color="red">Delete</Menu.Item>
                </>}
            />
            <div className={classes.dataWrapper}>
                <div className={classes.dataBlockWrapper}>
                    <div className={classes.dataBlock}>
                        <Text size="sm" color="dimmed" className={classes.blockHeaderText}>Location Inventory
                            (Counts)</Text>
                        <SimpleGrid cols={1}>
                            <StatsCard
                                title="Item Count"
                                formattedValue={(locationData.count ?? 0) + ' assets'}
                                value={locationData.count ?? 0}
                                prevValue={2}
                                interval="currently in the location"
                                showDiff={false}
                            />
                        </SimpleGrid>
                        <div className={classes.chartWrapper}>
                            <InventoryGraph filters={{location: locationData.location_uuid}} type="location" title="Location Inventory" availableTypes={['Current']}/>
                        </div>
                    </div>
                    <div className={classes.dataBlock}>
                        <Text size="sm" color="dimmed" className={classes.blockHeaderText}>Tag Hospitality</Text>
                        <SimpleGrid cols={2}>
                            <StatsCard
                                title="Average Time Spent"
                                formattedValue={locationData.location_history_aggregates?.[0] ? dayjs.duration(locationData.location_history_aggregates[0]?.avg_hospitality, "seconds").humanize() : 'N/A'}
                                value={locationData.location_history_aggregates?.[0]?.avg_hospitality ?? 0}
                                prevValue={locationData.location_history_aggregates?.[1]?.avg_hospitality ?? 0}
                                interval="last week"
                            />
                            <StatsCard
                                title="Median Time Spent"
                                formattedValue={locationData.location_history_aggregates?.[0] ? dayjs.duration(locationData.location_history_aggregates[0]?.med_hospitality, "seconds").humanize() : 'N/A'}
                                value={locationData.location_history_aggregates?.[0]?.med_hospitality ?? 0}
                                prevValue={locationData.location_history_aggregates?.[1]?.med_hospitality ?? 0}
                                interval="last week"
                            />
                        </SimpleGrid>
                        <div className={classes.chartWrapper}>
                            <TagHospitalityGraph location={locationData.location_uuid} availableTypes={['Timescale']} defaultType="Timescale" />
                        </div>
                    </div>
                </div>
                <HorizontalSeparator text="Related Data" top={15}/>
                <Tabs defaultValue="products" orientation={width > theme.breakpoints.md ? 'vertical' : 'horizontal'}>
                    <Tabs.List>
                        <Tabs.Tab value="products" icon={<IconCheese size={16}/>}>Products</Tabs.Tab>
                        <Tabs.Tab value="assets" icon={<IconTag size={16}/>}>Assets</Tabs.Tab>
                        <Tabs.Tab value="reloc" icon={<IconLocation size={16}/>}>Related Locations</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="products">
                        <ProductsInLocation location={locationData.location_uuid}/>
                    </Tabs.Panel>
                    <Tabs.Panel value="assets">
                        <AssetsInLocation location={locationData.location_uuid}/>
                    </Tabs.Panel>
                    <Tabs.Panel value="reloc">
                        <DetailViewTabContainer>
                            <h1>Related Locations</h1>
                        </DetailViewTabContainer>
                    </Tabs.Panel>
                </Tabs>
            </div>
            <CreateEditModal open={showEdit} close={() => setShowEdit(false)} modalTitle="Edit Location">
                <LocationForm edit={locationData} close={() => {setShowEdit(false); update();}} />
            </CreateEditModal>
        </div>
    )
};
export default LocationDetail;