import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
    header: {
        fontSize: "2rem",
        paddingLeft: 7,
        marginRight: 7,
        borderRadius: 4,
        height: 35,
        cursor: 'default',
        lineHeight: 1
    },
    highlightText: {
        backgroundImage: theme.fn.gradient({ from: theme.colors[theme.primaryColor][4], to: theme.colors[theme.primaryColor][9] }),
        color: "#fff",
        padding: 6,
        paddingBottom: 0,

        background: `linear-gradient(${theme.colors[theme.primaryColor][4]}, ${theme.colors[theme.primaryColor][9]})`,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent'
    },
    boldedText: {
        fontWeight: '900'
    },
    lightText: {
        fontWeight: '100'
    }
}));

const IdcatLogo = props => {
    const { classes } = useStyles();
    return (
        <div className={classes.header + ' ' + classes.highlightText}>
            <span className={classes.lightText}>ID</span><span className={classes.boldedText}>CAT</span>
        </div>
    );
};
export default IdcatLogo;
