import {HorizontalSeparator} from "../components/RustyRadeaComponents";
import {Paper, Text, useMantineTheme} from "@mantine/core";

const User = _ => {
    const theme = useMantineTheme();
    return (
        <div>
            <HorizontalSeparator size="lg" text="User Information" color="primary" top={0} />
            <div style={{height: 50}} />
            <Paper shadow="md" p="md" style={{margin: 'auto', width: '70%', background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : '#fff'}}>
                <Text size="sm" color="dimmed" align="center">User information has not yet been implemented</Text>
                <Text align="center" style={{width: '70%', margin: 'auto', paddingTop: 30}}>
                    Login and users has not yet been implemented in the system. This page will show your user information, permissions and account status
                    once that functionality has been implemented in the IDCAT platform
                </Text>
            </Paper>
        </div>
    )
};
export default User;