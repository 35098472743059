import {DevSupport} from "@react-buddy/ide-toolbox";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {ComponentPreviews, useInitial} from "./dev";
import Provider from './Provider';
import reportWebVitals from './reportWebVitals';

window.rustyradea = {};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<DevSupport ComponentPreviews={ComponentPreviews}
                        useInitialHook={useInitial}
>
    <Provider/>
</DevSupport>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
