import {Button, Card, Code, createStyles, useMantineTheme} from "@mantine/core";
import {Link, useLocation} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    cardWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 60px)',
        width: '100%',
        padding: "0 30px"
    },
    mainCard: {
        width: 500,
        maxWidth: '90%',
        background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : '#fff',
        borderRadius: 8,
        padding: 16
    },
    cardHeader: {
        fontSize: '1.6rem',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 16
    },
    cardBody: {
        textAlign: 'center',
        width: '90%',
        margin: 'auto'
    },
    locationCode: {
        minWidth: '30%',
        textAlign: 'center',
        fontSize: '0.8rem',
        marginTop: 16,
        marginBottom: 20
    },
    homeButton: {
        alignSelf: 'flex-end',
        marginRight: 30,
        marginTop: 30,
        marginBottom: 15
    },

    highligher: {
        color: theme.colorScheme === 'dark' ? theme.colors[theme.primaryColor][2] : theme.colors[theme.primaryColor][8]
    }
}));

const PageNotFound = _ => {
    const location = useLocation();
    const { classes } = useStyles();
    const theme = useMantineTheme();

    return (
        <div className={classes.cardWrapper}>
            <Card shadow="lg" className={classes.mainCard}>
                <div className="centeredContent">
                    <header className={classes.cardHeader}><span className={classes.highligher}>404</span> | Page Not Found</header>
                    <Code block className={classes.locationCode} color={theme.primaryColor}>{location.pathname}</Code>
                    <p className={classes.cardBody}>
                        The page you were trying to access does not exist, you don't have the correct permissions or it has been removed.
                    </p>
                    <Link to="/" className={classes.homeButton}>
                        <Button>
                            Return To Home
                        </Button>
                    </Link>
                </div>
            </Card>
        </div>
    )
};
export default PageNotFound;