import React from 'react';
import {Text, Button, createStyles, ActionIcon, Menu, CopyButton, Tooltip, useMantineTheme} from "@mantine/core";
import {IconArrowLeft, IconDotsVertical} from "@tabler/icons-react";
import {Link, useLocation} from "react-router-dom";
import {useViewportSize} from "@mantine/hooks";

const useStyles = createStyles(theme => ({
    headerWrapper: {
        border: 'thin solid',
        borderColor: theme.colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.13)' : 'rgba(0, 0, 0, 0.5)',
        padding: 3,
        boxSizing: 'border-box',
        height: 44,
        display: 'flex',
        alignItems: 'center',
        background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : '#ffffff',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%'
    },
    returnLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none'
    },
    returnButton: {
        borderRadius: 2
    },
    typeText: {
        marginLeft: 25,
        color: theme.colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
        cursor: 'default'
    },
    verticalSeparator: {
        height: 42,
        width: 1,
        background: theme.colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.13)' : 'rgba(0, 0, 0, 0.5)',
        margin: '0 15px'
    },
    headerText: {
        letterSpacing: 2,
        whiteSpace: 'nowrap',
        maxWidth: 'calc(100% - 220px)',
        overflow: 'hidden',

        ['@media(max-width: ' + theme.breakpoints.sm + 'px)']: {
            '&': {
                marginLeft: 25,
            }
        }
    },
    rightText: {
        flex: 1,
        textAlign: 'right',
        paddingRight: 5
    },
    actionMenuTarget: {
        borderRadius: 2,
        marginLeft: 25,

        ['@media(max-width: ' + theme.breakpoints.md + 'px)']: {
            '&': {
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end'
            }
        }
    },
    copyText: {
        '&:hover': {
            cursor: 'pointer'
        },
        '&:hover::after': {
            content: "'⧉'",
            color: theme.colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
            fontSize: '0.9rem',
            paddingLeft: 4,
            transform: 'translateY(-5px)',
            position: 'absolute'
        }
    }
}));

/**
 * DetailViewHeader component is used to add a stylistically cohesive header to the detail views of the application.
 * It renders a back to listing button and  name of the object on the left as well as possible extra info we want
 * to show on the right.
 * @param {String | node}  props.headerText                      The string to show on the main portion of the header
 * @param {String}  props.type                                   Type of the detail view
 * @param {String} [props.returnText="To Listing"]               Whether to align the text right (by default aligns left)
 * @param {String} [props.returnLocation=".."]                   URL to return to from this view (can be any valid react-router to-string)
 * @param {String} [props.rightText=null]                        Any extra text to render on the right side (given that there's enough room for it)
 * @param {node} [props.actions=null]                            If not null will render a menu on the right with the menu dropdown specified here
 *
 * @returns {React.ReactElement} The DetailViewHeader component
*/
export const DetailViewHeader = props => {
    const { classes, cx } = useStyles();
    const theme = useMantineTheme();
    const { width } = useViewportSize();
    const location = useLocation();
    const [returnToReferrer, setReturnToReferrer] = React.useState(null);
    const [returnToReferrerText, setReturnToReferrerText] = React.useState(null);

    React.useEffect(() => {
        let params = new URLSearchParams(location.search);
        if(params.get('from')) {
            let to = null;
            let text = null;
            switch (params.get('from')) {
                case 'tag': to = `/assets/${params.get('id')}`; text = 'Back To Asset'; break;
                case 'product': to = `/products/${params.get('id')}`; text = 'Back To Product'; break;
                case 'location': to = `/locations/${params.get('id')}`; text = 'Back To Location'; break;
                default: to = props.returnLocation;
            }
            setReturnToReferrer(to);
            setReturnToReferrerText(text);
        } else {
            setReturnToReferrer(null);
            setReturnToReferrerText(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <div className={classes.headerWrapper}>
            <div className={classes.headerContainer}>
                <Link to={returnToReferrer ? returnToReferrer : props.returnLocation} className={classes.returnLink}>
                    <Button className={classes.returnButton} variant={theme.colorScheme === 'dark' ? 'light' : 'filled'} leftIcon={<IconArrowLeft />}>
                        {returnToReferrerText ? returnToReferrerText : props.returnText}
                    </Button>
                </Link>
                {width > theme.breakpoints.sm && <>
                    <Text className={classes.typeText} size="xl" weight="bold">{props.type ?? ''}</Text>
                    <div className={classes.verticalSeparator} />
                </>}
                <Text className={classes.headerText} size="xl">
                    <CopyButton value={props.headerText}>{
                        ({ copied, copy }) => (
                            <Tooltip opened={copied} label="Copied" position="right">
                                <Text span className={cx({[classes.copyText]: typeof props.headerText === 'string'})} onClick={typeof props.headerText === 'string' ? copy: null}>{props.headerText}</Text>
                            </Tooltip>
                        )
                    }</CopyButton>
                </Text>
                {width > theme.breakpoints.md && <Text className={classes.rightText} color="dimmed" size="sm">
                    <CopyButton value={props.rightText}>{
                        ({ copied, copy }) => (
                                <Tooltip opened={copied} label="Copied" position="left">
                                    <Text span className={classes.copyText} onClick={copy}>{props.rightText}</Text>
                                </Tooltip>
                                )
                    }</CopyButton>
                </Text>}
                {props.actions && <Menu position="bottom-end">
                    <Menu.Target>
                        <div className={classes.actionMenuTarget}>
                            <ActionIcon size={36} variant={theme.colorScheme === 'dark' ? 'light' : 'filled'} color="primary" radius={2}>
                                <IconDotsVertical />
                            </ActionIcon>
                        </div>
                    </Menu.Target>

                    <Menu.Dropdown>
                        {props.actions}
                    </Menu.Dropdown>
                </Menu>}
            </div>
        </div>
    );
};
/**
 * Default property declarator for DetailViewHeader component
*/
DetailViewHeader.defaultProps = {
    returnText: 'To Listing',
    returnLocation: '..',
    actions: null
};
