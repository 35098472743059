import React from 'react';
import {useNavigate} from 'react-router-dom';
import {CreateEditModal, HorizontalSeparator, ListTopActions} from "../../components/RustyRadeaComponents";
import {ActionIcon, LoadingOverlay, Menu, Table, useMantineTheme} from "@mantine/core";
import {useAssets, useLocations} from "../../hooks/RustyRadeaHooks";
import dayjs from "dayjs";
import {IconCaretRight, IconRefresh} from "@tabler/icons-react";
import AssetForm from "./AssetForm";
import {useDebouncedState} from "@mantine/hooks";

const AssetList = props => {
    const { locations } = useLocations();
    const { assets, fetch, loadAttempted } = useAssets();
    const navigate = useNavigate();
    const theme = useMantineTheme();

    const [search, setSearch] = useDebouncedState('', 400);
    const [showCreate, setShowCreate] = React.useState(false);

    React.useEffect(() => {
        let filters = {};
        if(search) filters.search = search;
        fetch(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
            <div>
                <HorizontalSeparator size="lg" text="Asset Listing" color="primary" top={0} />
                <ListTopActions
                    onSearch={value => setSearch(value)}
                    createText="Add A New Asset"
                    onCreate={() => setShowCreate(true)}
                    onFilters={() => null}
                    extraMethods={<Menu.Dropdown>
                        <Menu.Label>Asset List Actions</Menu.Label>
                        <Menu.Item icon={<IconRefresh size={14} />}>Update Data</Menu.Item>
                    </Menu.Dropdown>}
                />
                <div style={{ position: 'relative', maxWidth: '1800px', margin: 'auto' }}>
                    <LoadingOverlay visible={!loadAttempted} overlayBlur={2} />
                    <Table striped highlightOnHover>
                        <thead>
                            <tr><th>EPC</th><th>Name</th><th>Product SKU</th><th>Current Location</th><th>Updated</th><th>Created</th><th></th></tr>
                        </thead>
                        <tbody>
                            {(assets ?? []).map(asset => (
                                    <tr key={asset.epc_code}>
                                        <td>{asset.epc_code}</td><td>{asset.name}</td><td>{asset.product}</td>
                                        <td>{locations.find(e => e.location_uuid === asset.current_location)?.name ?? asset.current_location}</td>
                                        <td>{dayjs(asset.updated).format('HH:mm:ss DD.MM.YYYY')}</td><td>{dayjs(asset.created).format('HH:mm:ss DD.MM.YYYY')}</td>
                                        <td className="tableActions">
                                            <ActionIcon variant="light" color={theme.primaryColor} onClick={() => navigate(asset.epc_code)}><IconCaretRight /></ActionIcon>
                                        </td>
                                    </tr>
                                    ))}
                        </tbody>
                    </Table>
                </div>
                <CreateEditModal open={showCreate} close={() => setShowCreate(false)} modalTitle="Create A New Asset">
                    <AssetForm close={() => {setShowCreate(false); fetch();}} />
                </CreateEditModal>
            </div>
            );
}
export default AssetList;
