import {Route, Routes} from "react-router-dom";
import LocationList from "./LocationList";
import LocationDetail from "./LocationDetail";

const LocationMaster = props => {
    return (
        <Routes>
            <Route path="/:location_uuid" element={<LocationDetail />} />
            <Route index element={<LocationList />} />
        </Routes>
    )
};
export default LocationMaster;