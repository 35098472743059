import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
// Import chart types, all suffixes with Chart
import {
    LineChart,
    // BarChart,
    // PieChart,
    // ScatterChart,
    // RadarChart,
    // MapChart,
    // TreeChart,
    // TreemapChart,
    // GraphChart,
    // GaugeChart,
    // FunnelChart,
    // ParallelChart,
    // SankeyChart,
    // BoxplotChart,
    // CandlestickChart,
    // EffectScatterChart,
    // LinesChart,
    // HeatmapChart,
    // PictorialBarChart,
    // ThemeRiverChart,
    // SunburstChart,
    // CustomChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
    // GridSimpleComponent,
    GridComponent,
    // PolarComponent,
    // RadarComponent,
    // GeoComponent,
    // SingleAxisComponent,
    // ParallelComponent,
    // CalendarComponent,
    // GraphicComponent,
    // ToolboxComponent,
    TooltipComponent,
    // AxisPointerComponent,
    // BrushComponent,
    TitleComponent,
    // TimelineComponent,
    // MarkPointComponent,
    // MarkLineComponent,
    // MarkAreaComponent,
    // LegendComponent,
    // LegendScrollComponent,
    // LegendPlainComponent,
    // DataZoomComponent,
    // DataZoomInsideComponent,
    // DataZoomSliderComponent,
    // VisualMapComponent,
    // VisualMapContinuousComponent,
    // VisualMapPiecewiseComponent,
    // AriaComponent,
    // TransformComponent,
    DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
    CanvasRenderer,
    // SVGRenderer,
} from 'echarts/renderers';
import {useMantineTheme} from "@mantine/core";
import {createTheme} from "../config/echarts-themes/rustyradea";

/**
 * RustyRadeaChart component is a wrapper around echarts-for-react which only imports the chart properties required by rusty RADEA and
 * applies appropriate theming to the charts.
 * Make sure that all the required properties are imported from the echarts libraries
 * @param {Object} props                         The props you would normally use with ReactECharts component
 *
 * @returns {React.ReactElement} The RustyRadeaChart component
 */
export const RustyRadeaChart = props => {
    const theme = useMantineTheme();

    // Create the theme used by rusty RADEA
    createTheme(theme.colors[theme.primaryColor], theme.primaryColor);
    // Register the required components
    echarts.use(
        [DatasetComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer]
    );

    return <ReactEChartsCore echarts={echarts} theme={theme.colorScheme === 'dark' ? theme.primaryColor : 'light'} {...props} />
};