import React from 'react';
import {useImmer} from 'use-immer';
import { showNotification } from '@mantine/notifications';

import AppContext from "../config/AppContext";

import ENDPOINTS from '../config/endpoint';

export const useInventory = props => {
    const config = React.useContext(AppContext);

    const [inventory, setInventory] = useImmer([]);
    const [location, setLocation] = useImmer([]);
    const [product, setProduct] = useImmer([]);
    const [loadAttempted, setLoadAttempted] = React.useState(false);

    const fetchInventoryData = (filters={}, type) => {
        let params = new URLSearchParams(filters);
        fetch(`${ENDPOINTS([type])}?${params}`, {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        }).then(response => {
            if(!response.ok) {
                errorNotification();
            }
            return response.json();
        }).then(data => {
            switch (type) {
                case 'inventory': setInventory(draft => data); break;
                case 'locationinventory': setLocation(draft => data); break;
                case 'productinventory': setProduct(draft => data); break;
                default: setInventory(draft => data); break;
            }
        }).catch(e => {
            errorNotification();
            console.log('Error in inventory fetch:', e);
        }).finally(() => setLoadAttempted(true));
    };
    const errorNotification = _ => {
        showNotification({
            color: 'red',
            title: 'Failed to fetch inventory',
            message: 'The request to fetch inventory failed. Please refresh the page and try again',
        });
    };
    const fetchData = (filters, type='inventory') => {
        let success = 0;
        Object.entries(filters).forEach(([key, value]) => value ? success++ : null);
        if (success === Object.keys(filters).length) {
            fetchInventoryData(filters, type);
        }
    }

    return {
        inventory: inventory,
        location: location,
        product: product,
        fetch: fetchData,
        loadAttempted: loadAttempted
    };
};