import React from 'react';
import {useImmer} from 'use-immer';
import { showNotification } from '@mantine/notifications';

import AppContext from "../config/AppContext";

import ENDPOINTS from '../config/endpoint';

export const useAssets = props => {
    const config = React.useContext(AppContext);

    const [assets, setAssets] = useImmer([]);
    const [assetHistory, setAssetHistory] = useImmer([]);
    const [hospitality, setHospitality] = useImmer([]);
    const [loadAttempted, setLoadAttempted] = React.useState(false);

    const fetchAssetData = (filters={}) => {
        let params = new URLSearchParams(filters);
        fetch(`${ENDPOINTS('assets')}?${params}`, {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        }).then(response => {
            if(!response.ok) {
                errorNotification();
            }
            return response.json();
        }).then(data => {
            setAssets(draft => data);
        }).catch(e => {
            errorNotification();
            console.log('Error in asset fetch:', e);
        }).finally(() => setLoadAttempted(true));
    };
    const fetchHospitalityData = (filters={}) => {
        let tag = null;
        if(filters.tag) {
            tag = filters.tag;
            delete filters.tag;
        }
        if(tag !== null) {
            let params = new URLSearchParams(filters);
            fetch(`${ENDPOINTS('asset_hospitality', {epc_code: tag})}?${params}`, {
                headers: {'Authorization': 'Bearer ' + config.accessToken}
            }).then(response => {
                if(!response.ok) {
                    errorNotification('hospitality');
                }
                return response.json();
            }).then(data => {
                setHospitality(draft => data);
            }).catch(e => {
                errorNotification('hospitality');
                console.log('Error in asset hospitality fetch:', e);
            }).finally(() => setLoadAttempted(true));
        } else {
            console.warn('Attempted to get tag hospitality in useAssets without defining tag in filters!');
            showNotification({
                color: 'red',
                title: 'Improperly configured',
                message: 'There is a problem in the time spent in a location by asset graph on this page. It\'s caused by imporperly configured system, please notify support'
            });
        }
    };
    const fetchHistoryData = (filters={}) => {
        let params = new URLSearchParams(filters);
        fetch(`${ENDPOINTS('asset_history')}?${params}`, {
            headers: {'Authorization': 'Bearer ' + config.accessToken}
        }).then(response => {
            if(!response.ok) {
                errorNotification('history');
            }
            return response.json();
        }).then(data => {
            setAssetHistory(draft => data);
        }).catch(e => {
            errorNotification();
            console.log('Error in asset fetch:', e);
        }).finally(() => setLoadAttempted(true));
    }
    const errorNotification = (type='asset') => {
        let message = '';
        switch (type) {
            case 'asset': message = 'The request to fetch assets failed. Please refresh the page and try again'; break;
            case 'hospitality': message = 'The request to fetch time spent in location failed. Please refresh the page and try again'; break;
            case 'history': message = 'The request to fetch asset history failed. Please refresh the page and try again'; break;
            default: message = 'The request to fetch assets failed. Please refresh the page and try again'; break;
        }
        showNotification({
            color: 'red',
            title: 'Failed to fetch data',
            message: message,
        });
    };
    const fetchData = (filters, data='asset') => {
        if(data === 'asset') fetchAssetData(filters);
        if(data === 'hospitality') fetchHospitalityData(filters);
        if(data === 'history') fetchHistoryData(filters);
    }

    return {
        assets: assets,
        history: assetHistory,
        hospitality: hospitality,
        fetch: fetchData,
        loadAttempted: loadAttempted
    };
};