import React from 'react';
import {useParams} from "react-router-dom";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
    DetailViewHeader,
    StatsCard,
    HorizontalSeparator,
    InventoryGraph,
    ProductInLocationsGraph, CreateEditModal
} from "../../components/RustyRadeaComponents";
import {createStyles, Menu, SimpleGrid, Tabs, Text, useMantineTheme} from "@mantine/core";
import {
    IconLocation,
    IconPencil,
    IconRefresh,
    IconTag,
    IconTrash
} from "@tabler/icons-react";
import {useViewportSize} from "@mantine/hooks";
import {useProducts} from "../../hooks/RustyRadeaHooks";
import ProductInLocations from "./ProductInLocations";
import AssetsOfProduct from "./AssetsOfProduct";
import ProductForm from "./ProductForm";

const useStyles = createStyles(theme => ({
    pageWrapper: {
        maxWidth: 1800,
        margin: 'auto'
    },
    dataWrapper: {
        paddingTop: 15
    },
    dataBlockWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'

    },
    dataBlock: {
        width: '50%',
        minWidth: 450,
        boxSizing: 'border-box',
        border: 'thin solid',
        borderColor: theme.colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.13)' : 'rgba(0, 0, 0, 0.5)',
        padding: 10,

        ['@media(max-width: ' + theme.breakpoints.md + 'px)']: {
            '&': {
                width: '100%'
            }
        }
    },
    blockHeaderText: {
        letterSpacing: 1
    },
    chartWrapper: {
        marginTop: 15,
        backgroundColor: theme.colorScheme === 'dark' ? '#000' : '#fff'
    }
}));

const ProductDetail = props => {
    dayjs.extend(duration);
    dayjs.extend(relativeTime);
    const theme = useMantineTheme();
    const {sku} = useParams();
    const {classes} = useStyles();
    const {products, update, productLocations, fetchProductLocations} = useProducts();
    const {width} = useViewportSize();

    const [productData, setProductData] = React.useState(products.find(e => e.sku === sku) ?? {});
    const [showEdit, setShowEdit] = React.useState(false);

    React.useEffect(() => {
        update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        setProductData(products.find(e => e.sku === sku) ?? {});
    }, [sku, products]);
    React.useEffect(() => {
        if(productData.sku) {
            fetchProductLocations(productData.sku);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productData]);

    return(
        <div className={classes.pageWrapper}>
            <DetailViewHeader
                type={'Product'}
                headerText={productData.name ?? <Text color={theme.primaryColor} style={{opacity: '0.7'}}>{width > theme.breakpoints.md ? 'Unnamed Product' : 'SKU: ' + productData.sku}</Text>}
                rightText={productData.sku}
                actions={<>
                    <Menu.Label>Data</Menu.Label>
                    <Menu.Item icon={<IconRefresh size={18}/>} color="cyan">Update Data</Menu.Item>
                    <Menu.Item icon={<IconPencil size={18}/>}
                               color={theme.colorScheme === 'dark' ? "orange.3" : "orange.6Dt"} onClick={() => setShowEdit(true)}>Edit</Menu.Item>

                    <Menu.Divider/>

                    <Menu.Label>Danger Zone</Menu.Label>
                    <Menu.Item icon={<IconTrash size={18}/>} color="red">Delete</Menu.Item>
                </>}
            />
            <div className={classes.dataWrapper}>
                <div className={classes.dataBlockWrapper}>
                    <div className={classes.dataBlock}>
                        <Text size="sm" color="dimmed" className={classes.blockHeaderText}>Product Items
                            (Assets)</Text>
                        <SimpleGrid cols={1}>
                            <StatsCard
                                title="Item Count"
                                formattedValue={(productData.count?.[0]?.count ?? 0) + ' assets'}
                                value={productData.count?.[0]?.count ?? 0}
                                prevValue={2}
                                interval="assigned to this product"
                                showDiff={false}
                            />
                        </SimpleGrid>
                        <div className={classes.chartWrapper}>
                            <InventoryGraph filters={{product: productData.sku}} type="product" title="Product Inventory" availableTypes={['Current']}/>
                        </div>
                    </div>
                    <div className={classes.dataBlock}>
                        <Text size="sm" color="dimmed" className={classes.blockHeaderText}>Present In Locations</Text>
                        <SimpleGrid cols={1}>
                            <StatsCard
                                title="Product Is In"
                                formattedValue={(productLocations.length) + ' locations'}
                                value={productLocations.length}
                                prevValue={0}
                                interval="Distinct locations"
                                showDiff={false}
                            />
                        </SimpleGrid>
                        <div className={classes.chartWrapper}>
                            <ProductInLocationsGraph product={productData.sku} />
                        </div>
                    </div>
                </div>
                <HorizontalSeparator text="Related Data" top={15}/>
                <Tabs defaultValue="locations" orientation={width > theme.breakpoints.md ? 'vertical' : 'horizontal'}>
                    <Tabs.List>
                        <Tabs.Tab value="locations" icon={<IconLocation size={16}/>}>Locations</Tabs.Tab>
                        <Tabs.Tab value="assets" icon={<IconTag size={16}/>}>Assets</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="locations">
                        <ProductInLocations product={productData.sku} />
                    </Tabs.Panel>
                    <Tabs.Panel value="assets">
                        <AssetsOfProduct product={productData.sku} />
                    </Tabs.Panel>
                </Tabs>
            </div>
            <CreateEditModal open={showEdit} close={() => setShowEdit(false)} modalTitle="Edit Product">
                <ProductForm edit={productData} close={() => {setShowEdit(false); update();}} />
            </CreateEditModal>
        </div>
    )
};
export default ProductDetail;