import React from 'react';
import { HorizontalSeparator, ListCard } from '../components/RustyRadeaComponents';
import {createStyles, useMantineColorScheme, useMantineTheme} from "@mantine/core";

const useStyles = createStyles((theme) => ({
    contentWrapper: {
        margin: 'auto',
        width: '90%',
        maxWidth: 900
    }
}));

const Settings = props => {
    const { classes } = useStyles();
    const theme = useMantineTheme();
    const {colorScheme, toggleColorScheme} = useMantineColorScheme();
    
    const [themeUpdateId, setThemeUpdateId] = React.useState(0);
    
    React.useEffect(() => {
        window.localStorage.setItem('idcat_configuration', JSON.stringify({
            colorScheme: colorScheme,
            primaryColor: theme.primaryColor
        }))
    }, [themeUpdateId]);

    return (
        <div>
            <HorizontalSeparator size="lg" text="Look & Feel" color="primary" top={0} />
            <div className={classes.contentWrapper}>
                <HorizontalSeparator size="sm" text="Colour Scheme" color="default" top={20} />
                <ListCard button useCheck={colorScheme === 'light'} onClick={() => {
                    colorScheme !== 'light' && toggleColorScheme();
                    setThemeUpdateId(prev => prev + 1)
                }} top={10}>
                    Light Theme
                </ListCard>
                <ListCard button useCheck={colorScheme === 'dark'} onClick={() => {
                    colorScheme !== 'dark' && toggleColorScheme();
                    setThemeUpdateId(prev => prev + 1)
                }}>
                    Dark Theme
                </ListCard>

                <HorizontalSeparator size="sm" text="Theme Colour" color="default" top={50} />
                <ListCard button useCheck={theme.primaryColor === 'brady'} onClick={() => {
                    props.setPrimaryColor('brady');
                    setThemeUpdateId(prev => prev + 1)
                }} top={10}>
                    Brady Corporation
                </ListCard>
                <ListCard button useCheck={theme.primaryColor === 'nordic'} onClick={() => {
                    props.setPrimaryColor('nordic');
                    setThemeUpdateId(prev => prev + 1)
                }}>
                    NordicID
                </ListCard>
                <ListCard button useCheck={theme.primaryColor === 'nature'} onClick={() => {
                    props.setPrimaryColor('nature');
                    setThemeUpdateId(prev => prev + 1)
                }}>
                    Nature Theme
                </ListCard>
                <ListCard button useCheck={theme.primaryColor === 'princess'} onClick={() => {
                    props.setPrimaryColor('princess');
                    setThemeUpdateId(prev => prev + 1)
                }}>
                    Princess Theme
                </ListCard>
                <ListCard button useCheck={theme.primaryColor === 'prince'} onClick={() => {
                    props.setPrimaryColor('prince');
                    setThemeUpdateId(prev => prev + 1)
                }}>
                    Prince Theme
                </ListCard>
            </div>
        </div>
    )
};
export default Settings;
