import React from 'react';
import {
    DetailViewTabContainer,
    HorizontalSeparator,
    ListTopActions
} from "../../components/RustyRadeaComponents";
import {LoadingOverlay, Menu, Table, Text} from "@mantine/core";
import {useAssets, useLocations} from "../../hooks/RustyRadeaHooks";
import dayjs from "dayjs";
import {IconRefresh} from "@tabler/icons-react";
import {humanizeSeconds} from "../../config/method";

const AssetHistory = props => {
    const { history, fetch, loadAttempted } = useAssets();
    const { locations } = useLocations();

    React.useEffect(() => {
        updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tag]);
    const updateData = () => {
        if(props.tag) fetch({tag: props.tag}, 'history');
    }

    return (
            <DetailViewTabContainer>
                <HorizontalSeparator text="Asset History" size="sm" />
                <ListTopActions
                    onFilters={() => null}
                    extraMethods={<Menu.Dropdown>
                        <Menu.Label>Asset List Actions</Menu.Label>
                        <Menu.Item icon={<IconRefresh size={14} />} color="blue" onClick={updateData}>Update Data</Menu.Item>
                </Menu.Dropdown>}
                />
                <div style={{ position: 'relative' }}>
                    <LoadingOverlay visible={!loadAttempted} overlayBlur={2} />
                    <Table striped highlightOnHover>
                        <thead>
                            <tr><th>Location</th><th>Time Into</th><th>Time Out</th><th>Time Spent</th></tr>
                        </thead>
                        <tbody>
                            {(history ?? []).map(entry => (
                                    <tr key={entry.id}>
                                        <td>{locations.find(e => e.location_uuid === entry.location)?.name ?? entry.location}</td>
                                        <td>{dayjs(entry.time_into).format('HH:mm:ss || DD.MM.YYYY')}</td>
                                        <td>{entry.time_from ? dayjs(entry.time_from).format('HH:mm:ss || DD.MM.YYYY') : 'N/A'}</td>
                                        <td>{entry.time_spent
                                                ? <Text span>{humanizeSeconds(entry.time_spent, true)}</Text>
                                                : humanizeSeconds(((new Date().getTime() + (new Date().getTimezoneOffset() * 60000)) - new Date(entry.time_into).getTime()) / 1000, true)}</td>
                                    </tr>
                                    ))}
                        </tbody>
                    </Table>
                </div>
            </DetailViewTabContainer>
            );
}
export default AssetHistory;
