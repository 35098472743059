import {
    Box,
    Tabs,
    Text,
    Textarea,
    Group,
    ActionIcon,
    useMantineTheme,
    Tooltip,
    Avatar,
    Badge,
    Table
} from "@mantine/core";
import {IconRobot, IconSend, IconUser, IconX} from "@tabler/icons-react";
import dayjs from "dayjs";
import React from "react";
import {Route, Routes} from "react-router-dom";
import {useImmer} from "use-immer";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism } from '@mantine/prism';
import {RustyRadeaChart} from "../../components/RustyRadeaChart";
import {syntaxHighlightJSON} from "../../config/method";

const AIMaster = props => {
    return (
        <Routes>
            <Route index element={<Tabs defaultValue="nlpdata">
                <Tabs.List>
                    <Tabs.Tab value="nlpdata">NLP Data Fetching</Tabs.Tab>
                    <Tabs.Tab value="assistant">Smart Assistant</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="nlpdata"><NLPListing /></Tabs.Panel>
                <Tabs.Panel value="assistant"><SmartAssistant /></Tabs.Panel>
            </Tabs>} />
        </Routes>
    )
};
export default AIMaster;

export const SmartAssistant = props => {
    const theme = useMantineTheme();
    
    const [loading, setLoading] = React.useState(false);
    const [history, setHistory] = useImmer([]);
    const [prompt, setPrompt] = React.useState('');
    
    const sendPrompt = async () => {
        setLoading(true);
        setHistory(draft => {
            draft.push({
                role: 'user',
                content: [{
                    type: 'text',
                    text: prompt
                }]
            })
        });
        setPrompt('');
        let rq = await fetch('http://localhost:9099/ext/ai/assistant/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                history: history,
                prompt: prompt
            })
        }).catch(e => console.log('Error in sending assistant request: ', e));
        if(rq?.ok) {
            let rqdata = await rq.json();
            setHistory(draft => {
                draft.push({
                    role: 'assistant',
                    content: [{
                        type: 'text',
                        text: rqdata?.data?.content?.[0]?.text ?? 'Assistant error. Could not get response'
                    }]
                })
            });
        } else {
            setHistory(draft => {
                draft.push({
                    role: 'assistant',
                    content: [{
                        type: 'text',
                        text: 'Assistant error. Could not get response'
                    }]
                })
            });
        }
        setLoading(false);
    }
    
    React.useEffect(() => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    }, [history]);
    
    return (
        <Box m={50}>
            <div style={{position: 'relative', maxWidth: '1800px', margin: 'auto'}}>
                <Box>
                    {history.map((entry, index) => (
                        <Group noWrap={true} align="flex-start">
                            {entry.role === 'user' && <Avatar color="indigo" size="sm" mt={25}>
                                <IconUser/>
                            </Avatar>}
                            {entry.role === 'assistant' && <Avatar color="orange" size="sm" mt={25}>
                                <IconRobot/>
                            </Avatar>}
                            <Box key={index} style={{background: theme.colors.dark[7], borderRadius: 10, flexGrow: 1}}
                                 px={20} my={10} py={1}>
                                <Markdown
                                    remarkPlugins={[remarkGfm]}
                                    components={{
                                        code(props) {
                                            const {children, className, node, ...rest} = props;
                                            const match = /language-(\w+)/.exec(className || '');
                                            console.log(match);
                                            return match ? (
                                                <Prism withLineNumbers language={match[1]}>
                                                    {String(children).replace(/\n$/, '')}
                                                </Prism>
                                            ) : (
                                                <code {...rest} className={className}>
                                                    {children}
                                                </code>
                                            )
                                        }
                                    }}
                                >
                                    {entry?.content?.[0].text}
                                </Markdown>
                            </Box>
                        </Group>
                    ))}
                </Box>
                <form onSubmit={e => {
                    e.preventDefault();
                    sendPrompt();
                }}>
                    <Group>
                        <Tooltip label={"Clear context history. Currently " + history.length + " items"}>
                            <ActionIcon disabled={history.length < 1} size="xl" color={theme.primaryColor}
                                        onClick={() => setHistory([])}>
                                <IconX/>
                            </ActionIcon>
                        </Tooltip>
                        <Textarea
                            onKeyDown={e => {
                                if (e.key === "Enter" && !e.shiftKey) {
                                    e.preventDefault();
                                    e.target.form.requestSubmit();
                                }
                            }}
                            value={prompt}
                            onChange={e => setPrompt(e.target.value)}
                            placeholder="Ask your IDCAT related question here"
                            autosize
                            minRows={2}
                            maxRows={6}
                            style={{flexGrow: 1}}
                        />
                        <Tooltip label="Send prompt">
                            <ActionIcon type="submit" size="xl" color={theme.primaryColor} loading={loading}
                                        disabled={prompt === ''}>
                                <IconSend/>
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                </form>
            </div>
        </Box>
    )
}

export const NLPListing = props => {
    const theme = useMantineTheme();
    
    const [loading, setLoading] = React.useState(false);
    const [history, setHistory] = useImmer([]);
    const [prompt, setPrompt] = React.useState('');
    
    const sendPrompt = async () => {
        setLoading(true);
        setHistory(draft => {
            draft.push({
                role: 'user',
                content: [{
                    type: 'text',
                    text: prompt
                }]
            })
        });
        setPrompt('');
        let rq = await fetch('http://localhost:9099/ext/ai/listing/', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                prompt: prompt
            })
        }).catch(e => console.log('Error in sending assistant request: ', e));
        if(rq?.ok) {
            let rqdata = await rq.json();
            setHistory(draft => {
                draft.push(rqdata)
            });
        } else {
            setHistory(draft => {
                draft.push({
                    role: 'assistant',
                    content: [{
                        type: 'text',
                        text: 'Assistant error. Could not get response'
                    }]
                })
            });
        }
        setLoading(false);
    }
    
    React.useEffect(() => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    }, [history]);
    
    return (
        <Box m={50}>
            <div style={{position: 'relative', maxWidth: '1800px', margin: 'auto'}}>
                <Box mb={50}>
                    {history.map((entry, index) => (<>
                        {entry?.role && <>
                            <Group noWrap={true} align="flex-start">
                                <Avatar color="indigo" size="sm" mt={25}>
                                    <IconUser/>
                                </Avatar>
                                <Box key={index} style={{background: theme.colors.dark[7], borderRadius: 10, flexGrow: 1}}
                                     px={20} my={10} py={1}>
                                    <Markdown
                                        remarkPlugins={[remarkGfm]}
                                        components={{
                                            code(props) {
                                                const {children, className, node, ...rest} = props;
                                                const match = /language-(\w+)/.exec(className || '');
                                                return match ? (
                                                    <Prism withLineNumbers language={match[1]}>
                                                        {String(children).replace(/\n$/, '')}
                                                    </Prism>
                                                ) : (
                                                    <code {...rest} className={className}>
                                                        {children}
                                                    </code>
                                                )
                                            }
                                        }}
                                    >
                                        {entry?.content?.[0].text}
                                    </Markdown>
                                </Box>
                            </Group>
                        </>}
                        {entry?.listing_type && <>
                            <Group>
                                <Avatar color="orange" size="sm">
                                    <IconRobot/>
                                </Avatar>
                                <Text ml={10}>Query Result <Text color="dimmed" span>- {readableListingType(entry.listing_type)}</Text></Text>
                            </Group>
                            <Group mt={5} ml={45}>
                                {Object.entries(entry.filters).map(([key, value]) => (
                                    <Badge key={key} color="violet">{key} = {Array.isArray(value) ? value.join(',') : value}</Badge>
                                ))}
                                {Object.keys(entry.filters).length === 0 && <Badge color="violet">No Filters</Badge>}
                            </Group>
                            <Box
                                style={{background: theme.colors.dark[7], borderRadius: 10, flexGrow: 1}}
                                px={20} my={10} py={1} ml={45}
                            >
                                {entry.listing_type !== 'location_inventory' && <Table striped>
                                    <thead>
                                    <tr>
                                        {Object.keys((entry.data?.[0] ?? {})).map(key => (
                                            <th key={key}>{key}</th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {entry.data.map(datapoint => (
                                        <tr key={datapoint}>
                                            {Object.values(datapoint).map(value => (
                                                <td key={value}>{
                                                    value === null ?
                                                        <Text color="dimmed" style={{fontStyle: 'italic'}}>null</Text>
                                                        : typeof value === 'boolean' ?
                                                            <Text color="yellow">{String(value)}</Text>
                                                            : (isNaN(parseInt(value)) && dayjs(value).isValid()) ? dayjs(value).format('DD.MM.YYYY HH:mm')
                                                                : typeof value === 'object' ? <Tooltip label={<div
                                                                        dangerouslySetInnerHTML={{__html: syntaxHighlightJSON(JSON.stringify(value))}}/>}><Text
                                                                        color="green">Object
                                                                        ({Object.keys(value).length})</Text></Tooltip>
                                                                    : String(value)
                                                }</td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>}
                                {entry.listing_type === 'location_inventory' && <RustyRadeaChart
                                    option={{
                                        title: {
                                            text: 'Location Inventory'
                                        },
                                        tooltip: {
                                            trigger: 'axis',
                                            formatter: function (params) {
                                                params = params[0];
                                                return (
                                                    `${params.marker} ${dayjs(params.name).format('DD.MM.YYYY HH:mm')} - Count: ${params.value[1]}`
                                                );
                                            },
                                            axisPointer: {
                                                animation: false
                                            }
                                        },
                                        xAxis: {
                                            type: 'time',
                                            splitLine: {
                                                show: false
                                            }
                                        },
                                        yAxis: {
                                            type: 'value',
                                            boundaryGap: [0, '10%']
                                        },
                                        series: [
                                            {
                                                data: (entry.data).map(x => ({
                                                    name: x.bucket,
                                                    value: [
                                                        dayjs(x.bucket).format('YYYY/MM/DD HH:mm'),
                                                        x.count__avg
                                                    ]
                                                })),
                                                type: 'line',
                                                showSymbol: false,
                                            }
                                        ]
                                    }}
                                    notMerge={true}
                                    lazyUpdate={true}
                                />}
                            </Box>
                        </>}
                    </>))}
                </Box>
                <form onSubmit={e => {
                    e.preventDefault();
                    sendPrompt();
                }}>
                    <Group>
                        <Tooltip label={"Clear context history. Currently " + history.length + " items"}>
                            <ActionIcon disabled={history.length < 1} size="xl" color={theme.primaryColor}
                                        onClick={() => setHistory([])}>
                                <IconX/>
                            </ActionIcon>
                        </Tooltip>
                        <Textarea
                            onKeyDown={e => {
                                if (e.key === "Enter" && !e.shiftKey) {
                                    e.preventDefault();
                                    e.target.form.requestSubmit();
                                }
                            }}
                            value={prompt}
                            onChange={e => setPrompt(e.target.value)}
                            placeholder="Ask your IDCAT related question here"
                            autosize
                            minRows={2}
                            maxRows={6}
                            style={{flexGrow: 1}}
                        />
                        <Tooltip label="Send prompt">
                            <ActionIcon type="submit" size="xl" color={theme.primaryColor} loading={loading}
                                        disabled={prompt === ''}>
                                <IconSend/>
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                </form>
            </div>
        </Box>
    )
}

function readableListingType(type) {
    if(type === 'tag_listing') return 'Tags'
    else if(type === 'location_listing') return 'Locations'
    else if(type === 'product_listing') return 'Products'
    else if(type === 'location_inventory') return 'Location Inventory'
    else if(type === 'location_inventory_listing') return 'Location Inventory'
    else return type.replace(/_/g, ' ')
}
