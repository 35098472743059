import React from 'react';
import {Button, createStyles, Select, TextInput} from "@mantine/core";
import {useImmer} from "use-immer";
import ENDPOINTS from "../../config/endpoint";
import {showNotification} from "@mantine/notifications";
import {useLocations, useProducts} from "../../hooks/RustyRadeaHooks";

import AppContext from "../../config/AppContext";

const useStyles = createStyles(theme => ({
    formContainer: {
        maxWidth: 1800,
        margin: 'auto'
    },
    formInput: {
        marginTop: 7
    },
    submitButton: {
        marginTop: 30
    }
}));

const AssetForm = props => {
    const config = React.useContext(AppContext);

    const { classes } = useStyles();
    const { locations, groups } = useLocations();
    const { products, update } = useProducts();

    const [formValues, setFormValues] = useImmer({
        epc_code: props.edit?.epc_code ?? (props.defaultEPC ?? ''),
        name: props.edit?.name ?? (props.defaultName ?? ''),
        current_location: props.edit?.current_location ?? (props.defaultLocation ?? null),
        product: props.edit?.product ?? (props.defaultProduct ?? null)
    });

    React.useEffect(() => {
        update();
    }, []);

    const submit = () => {
        if(!props.edit) {
            fetch(ENDPOINTS('assets'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + config.accessToken
                },
                body: JSON.stringify(formValues)
            }).then(response => {
                if(!response.ok) failNotification()
                else {
                    successNotification();
                    props.close();
                }
            }).catch(error => {
                errorNotification();
                console.warn(error);
            });
        } else {
            if(props.edit?.id) {
                fetch(ENDPOINTS('asset_detail', {id: props.edit.id}), {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + config.accessToken
                    },
                    body: JSON.stringify(formValues)
                }).then(response => {
                    if(!response.ok) failNotification()
                    else {
                        successNotification();
                        props.close();
                    }
                }).catch(error => {
                    errorNotification();
                    console.warn(error);
                });
            } else console.warn('Improperly configured asset edit form')
        }
    };
    const failNotification = () => showNotification({
        title: 'Failed to add/edit asset',
        message: 'Adding asset failed. Please check your inputs and try again',
        color: 'red'
    });
    const successNotification = () => showNotification({
        title: 'Added/Edited asset',
        color: 'green'
    });
    const errorNotification = () => showNotification({
        title: 'Something went wrong in the request',
        message: 'Please wait a while and try again. If the problem persists, please contact support',
        color: 'red'
    });

    return (
            <div className={classes.formContainer}>
                <TextInput
                    value={formValues.name}
                    onChange={e => setFormValues(draft => {draft.name = e.target.value})}
                    placeholder="Asset Name"
                    label="Asset Name"
                    className={classes.formInput}
                />
                <TextInput
                    value={formValues.epc_code}
                    onChange={e => setFormValues(draft => {draft.epc_code = e.target.value})}
                    placeholder="Asset Tag EPC"
                    label="Asset Tag EPC"
                    description="Electronic product code assigned to the tag attached to this asset"
                    withAsterisk={!props.edit}
                    className={classes.formInput}
                />
                <Select
                    value={formValues.current_location}
                    onChange={value => setFormValues(draft => {draft.current_location = value})}
                    placeholder="Current Location"
                    label="Current Location"
                    data={locations.map(location => ({label: location.name, value: location.location_uuid, group: groups.find(e => e.id === location.location_group)?.name ?? 'Root'}))}
                    description="What is the current location of this asset"
                    className={classes.formInput}
                />
                <Select
                    value={formValues.product}
                    onChange={value => setFormValues(draft => {draft.product = value})}
                    placeholder="Product"
                    label="Product"
                    data={products.map(product => ({label: product.name ?? product.sku, value: product.sku}))}
                    className={classes.formInput}
                />
                <div className={classes.submitButton}>
                    <Button radius="xs" fullWidth onClick={submit}>Submit</Button>
                </div>
            </div>
            )
};
export default AssetForm;