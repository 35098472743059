import React from 'react';
import {CreateEditModal, HorizontalSeparator, ListTopActions} from "../../components/RustyRadeaComponents";
import {ActionIcon, LoadingOverlay, Menu, Table, useMantineTheme} from "@mantine/core";
import {useProducts} from "../../hooks/RustyRadeaHooks";
import {IconCaretRight, IconRefresh} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";
import ProductForm from "./ProductForm";
import {useDebouncedState} from "@mantine/hooks";

const ProductList = props => {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const { products, update, loadAttempted } = useProducts();
    // eslint-disable-next-line no-unused-vars
    const [search, setSearch] = useDebouncedState('', 400);
    const [showCreate, setShowCreate] = React.useState(false);

    React.useEffect(() => {
        let filters = {};
        if(search) filters.search = search;
        update(filters, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <div>
            <HorizontalSeparator size="lg" text="Product Listing" color="primary" top={0} />
            <ListTopActions
                onSearch={value => setSearch(value)}
                createText="Add A New Product"
                onCreate={() => setShowCreate(true)}
                extraMethods={<Menu.Dropdown>
                    <Menu.Label>General</Menu.Label>
                    <Menu.Item icon={<IconRefresh />} onClick={() => update()} color="blue">Update Data</Menu.Item>
                </Menu.Dropdown>}
            />
            <div style={{ position: 'relative', maxWidth: '1800px', margin: 'auto' }}>
                <LoadingOverlay visible={!loadAttempted} overlayBlur={2} />
                <Table striped highlightOnHover>
                    <thead>
                        <tr><th>Name</th><th>SKU</th><th>Total Count</th><th></th></tr>
                    </thead>
                    <tbody>
                        {(products ?? []).map(product => (
                            <tr key={product.sku}>
                                <td>{product.name}</td><td>{product.sku}</td><td>{product.count?.[0]?.count ?? 0}</td>
                                <td className="tableActions">
                                    <ActionIcon variant="light" color={theme.primaryColor} onClick={() => navigate(product.sku)}><IconCaretRight /></ActionIcon>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <CreateEditModal open={showCreate} close={() => {setShowCreate(false); update();}} modalTitle="Create A New Product">
                <ProductForm close={() => {setShowCreate(false); update();}} />
            </CreateEditModal>
        </div>
    );
}
export default ProductList;
