import React from 'react';
import {Button, ColorInput, createStyles, Select, TextInput} from "@mantine/core";
import {useLocations} from "../../hooks/RustyRadeaHooks";
import {useImmer} from "use-immer";
import ENDPOINTS from "../../config/endpoint";
import {showNotification} from "@mantine/notifications";

import AppContext from "../../config/AppContext";

const useStyles = createStyles(theme => ({
    formContainer: {
        maxWidth: 1800,
        margin: 'auto'
    },
    formInput: {
        marginTop: 7
    },
    submitButton: {
        marginTop: 30
    }
}));

const LocationGroupForm = props => {
    const config = React.useContext(AppContext);

    const { classes } = useStyles();
    const { groups } = useLocations();

    const [formValues, setFormValues] = useImmer({
        name: '',
        parent: null,
        color: '#000000'
    })

    const submit = () => {
        fetch(ENDPOINTS('locationgroups'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.accessToken
            },
            body: JSON.stringify(formValues)
        }).then(response => {
            if(!response.ok) {
                showNotification({
                    title: 'Failed to add location group',
                    message: 'Adding location group failed. Please check your inputs and try again',
                    color: 'red'
                });
            } else {
                showNotification({
                    title: 'Added location group',
                    color: 'green'
                });
                props.close();
            }
        }).catch(error => {
            showNotification({
                title: 'Something went wrong in the request',
                message: 'Please wait a while and try again. If the problem persists, please contact support',
                color: 'red'
            });
            console.warn(error);
        })
    };

    return (
            <div className={classes.formContainer}>
                <TextInput
                    value={formValues.name}
                    onChange={e => setFormValues(draft => {draft.name = e.target.value})}
                    placeholder="Location Name"
                    label="Location Name"
                    withAsterisk={!props.edit}
                    className={classes.formInput}
                />
                <Select
                    value={formValues.parent}
                    onChange={value => setFormValues(draft => {draft.parent = value})}
                    placeholder="Parent Group"
                    label="Parent Group"
                    data={groups.map(group => ({label: group.name, value: group.id, group: groups.find(e => e.id === group.parent)?.name ?? 'Root'}))}
                    clearable
                    description="The higher-level group this group is a part of"
                    className={classes.formInput}
                />
                <ColorInput label="Group Color" placeholder="Group Color" value={formValues.color} onChange={color => setFormValues(draft => {draft.color = color})} />
                <div className={classes.submitButton}>
                    <Button radius="xs" fullWidth onClick={submit}>Submit</Button>
                </div>
            </div>
            )
};
export default LocationGroupForm;