import {Tabs} from "@mantine/core";
import {Route, Routes} from "react-router-dom";
import ConfigurationValues from "./ConfigurationValues";
import Pipelines from "./Pipelines";

const ConfigurationMaster = props => {
    return (
        <Routes>
            <Route index element={<Tabs defaultValue="configuration">
                <Tabs.List>
                    <Tabs.Tab value="configuration">Configuration</Tabs.Tab>
                    <Tabs.Tab value="pipelines">Pipelines</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="configuration"><ConfigurationValues /></Tabs.Panel>
                <Tabs.Panel value="pipelines"><Pipelines /></Tabs.Panel>
            </Tabs>} />
        </Routes>
    )
};
export default ConfigurationMaster;
