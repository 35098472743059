const SERVER_ADDRESS = process.env?.REACT_APP_SERVER_ENDPOINT ?? 'http://localhost:8080/';
const ENDPOINT_LIST = {
    // Platform data
    // Config
    configlist: 'platform-data/configuration',
    edit_config: 'platform-data/configuration/:config_key',
    // Location groups
    locationgroups: 'platform-data/locationgroups',
    // Locations
    locations: 'platform-data/locations',
    location_detail: 'platform-data/locations/:location_uuid',
    location_hospitality: 'platform-data/locations/:location_uuid/tag-hospitality',
    location_hospitality_aggregates: 'platform-data/locations/hospitality-aggregates',
    // Readers
    reader_list: 'platform-data/readers',
    reader_detail: 'platform-data/readers/:reader_uuid',
    reader_configuration: 'platform-data/readers/:reader_uuid/configuration',
    // Floorplans
    floorplan_list: 'platform-data/floor-plans',
    floorplan_locations: 'platform-data/floor-plans/:floorplan_id/locations',
    floorplan_location_detail: 'platform-data/floor-plans/locations/:fplocation_id',

    // Asset data
    // Products
    products: 'asset-data/products',
    product_detail: 'asset-data/products/:sku',
    productlocations: 'asset-data/products/locations',
    // Tags
    assets: 'asset-data/rfid-tags',
    asset_detail: 'asset-data/rfid-tags/:id',
    asset_hospitality: 'asset-data/rfid-tags/:epc_code/tag-hospitality',
    asset_history: 'asset-data/rfid-tags/tag-history',

    // Inventory data
    inventory: 'inventory-data/inventory',
    productinventory: 'inventory-data/products',
    locationinventory: 'inventory-data/locations',

    // Externals
    // UWB Positioning
    uwb_locations: 'externals/uwb/locations',
    uwb_tags: 'externals/uwb/tags',
    uwb_alerting_zones: 'externals/uwb/alerting-zones',
    uwb_alerting_zone_detail: 'externals/uwb/alerting-zones/:id',
    uwb_location_assignment: 'externals/uwb/locations/:id/assign',
    // Tag positioning buckets
    uwb_positioning_buckets: 'externals/uwb/positioning-buckets',
    // Impinj reader stuff
    impinj_sc_config_list: 'externals/impinj/configuration',
    impinj_sc_config_detail: 'externals/impinj/configuration/:id',
    // Zebra reader stuff
    zebra_config_list: 'externals/zebra/configuration',
    zebra_config_detail: 'externals/zebra/configuration/:id',
    // RADEA.IO
    radea_locations: 'externals/radea/locations',

    // WEBSOCKETS (Remember to manually replace https with wss or http with ws
    inventory_updates_ws: 'ws/inventory-updates',
    tag_updates_ws: 'ws/tag-updates',
    uwb_location_updates_ws: 'ws/uwb-updates',
    uwb_alerts_ws: 'ws/uwb-alerts',
    
    // Data entry
    send_processlist: 'process-data/processlist',

    // STATIC
    floorplans: 'static/floor_plans/:fs_name',
    images: 'static/images/:image_name'
}

const ENDPOINTS = (endpoint, path_params={}) => {
    if (!ENDPOINT_LIST[endpoint]) {
        console.warn('Endpoint requested does not exist!');
        return null;
    }
    let address = SERVER_ADDRESS + ENDPOINT_LIST[endpoint];
    Object.entries(path_params).forEach(([key, value]) => {
        address = address.replace(`:${key}`, value);
    });
    return address;
}
export default ENDPOINTS;
