import React from 'react';
import {CreateEditModal, HorizontalSeparator, ListTopActions} from "../../components/RustyRadeaComponents";
import {ActionIcon, LoadingOverlay, Menu, Table, Modal, useMantineTheme, JsonInput, Button, Tabs} from "@mantine/core";
import {useReaders, useLocations} from "../../hooks/RustyRadeaHooks";
import {IconId, IconRefresh, IconSettings, IconTrash} from "@tabler/icons-react";
import ReaderForm from "./ReaderForm";
import {useDebouncedState} from "@mantine/hooks";
import { syntaxHighlightJSON } from "../../config/method";
import ENDPOINTS from "../../config/endpoint";
import {showNotification} from "@mantine/notifications";
import {useImmer} from "use-immer";

import AppContext from "../../config/AppContext";
import ImpinjScConfigurationForm from "./ImpinjScConfigurationForm";
import ZebraZIoTCConfigurationForm from "./ZebraZIoTCConfigurationForm";

const AssetList = props => {
    const config = React.useContext(AppContext);

    const { locations } = useLocations();
    const { readers, fetch, loadAttempted, fetchImpinj, impinjScConfigs, fetchZebra, zebraConfigs } = useReaders();
    const theme = useMantineTheme();

    const [search, setSearch] = useDebouncedState('', 400);
    const [showDetail, setShowDetail] = React.useState(false);
    const [showCreate, setShowCreate] = React.useState(false);
    const [changeConfiguration, setChangeConfiguration] = useImmer([false, ""]);
    
    const [showImpinjCreate, setShowImpinjCreate] = React.useState(false);
    const [showZebraCreate, setShowZebraCreate] = React.useState(false);

    React.useEffect(() => {
        let filters = {};
        if(search) filters.search = search;
        fetch(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);
    React.useEffect(() => {
        if(config.config.find(e => e.key === 'impinj_sc_integration_enabled')?.value === "1") {
            fetchImpinj();
        } if(config.config.find(e => e.key === 'zebra_ziotc_integration_enabled')?.value === "1") {
            fetchZebra();
        }
    }, []);
    
    const delete_reader = (reader_uuid) => {
        window.fetch(ENDPOINTS('reader_detail', {reader_uuid: reader_uuid}), {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.accessToken
            }
        }).then(response => {
            if(!response.ok) showNotification({
                title: 'Failed to delete reader',
                message: 'Deleting reader failed. Please refresh the page and try again',
                color: 'red'
            });
                else {
                    showNotification({
                        title: 'Deleted reader',
                        color: 'green'
                    });
                    fetch();
                }
        }).catch(error => {
            console.warn(error);
        });
    }
    const change_reader_configuration = () => {
        window.fetch(ENDPOINTS('reader_configuration', {reader_uuid: changeConfiguration[0]}), {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.accessToken
            },
            body: changeConfiguration[1]
        }).then(response => {
            if(!response.ok) showNotification({
                title: 'Failed to update reader configuration',
                message: 'Updating reader configuration failed. Please refresh the page and try again',
                color: 'red'
            });
            else {
                showNotification({
                    title: 'Updated reader configuration',
                    color: 'green'
                });
                fetch();
            }
            setChangeConfiguration([false, ""]);
        }).catch(error => {
            console.warn(error);
        });
    }

    const delete_impinj_configuration = (rc_id) => {
        window.fetch(ENDPOINTS('impinj_sc_config_detail', {id: rc_id}), {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.accessToken
            }
        }).then(response => {
            if(!response.ok) showNotification({
                title: 'Failed to delete Impinj reader configuration',
                message: 'Deleting configuration failed. Please refresh the page and try again',
                color: 'red'
            });
            else {
                showNotification({
                    title: 'Deleted Impinj reader configuration',
                    color: 'green'
                });
                fetchImpinj();
            }
        }).catch(error => {
            console.warn(error);
        });
    }
    
    const delete_zebra_configuration = (rc_id) => {
        window.fetch(ENDPOINTS('zebra_config_detail', {id: rc_id}), {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.accessToken
            }
        }).then(response => {
            if(!response.ok) showNotification({
                title: 'Failed to delete Zebra reader configuration',
                message: 'Deleting configuration failed. Please refresh the page and try again',
                color: 'red'
            });
            else {
                showNotification({
                    title: 'Deleted Zebra reader configuration',
                    color: 'green'
                });
                fetchZebra();
            }
        }).catch(error => {
            console.warn(error);
        });
    }

    return (
        <div>
            <Tabs defaultValue="brady">
                <Tabs.List mb={10}>
                    <Tabs.Tab value="brady">Brady Readers</Tabs.Tab>
                    {config.config.find(e => e.key === 'impinj_sc_integration_enabled')?.value === "1" && <Tabs.Tab value="impinj">Impinj Readers</Tabs.Tab>}
                    {config.config.find(e => e.key === 'zebra_ziotc_integration_enabled')?.value === "1" && <Tabs.Tab value="zebra">Zebra Readers</Tabs.Tab>}
                </Tabs.List>
                
                <Tabs.Panel value="brady">
                    <HorizontalSeparator size="lg" text="Reader Listing" color="primary" top={0} />
                    <ListTopActions
                        onSearch={value => setSearch(value)}
                        createText="Add A New Reader"
                        onCreate={() => setShowCreate(true)}
                        onFilters={() => null}
                        extraMethods={<Menu.Dropdown>
                            <Menu.Label>Reader List Actions</Menu.Label>
                            <Menu.Item icon={<IconRefresh size={14} />}>Update Data</Menu.Item>
                        </Menu.Dropdown>}
                    />
                    <div style={{ position: 'relative', maxWidth: '1800px', margin: 'auto' }}>
                        <LoadingOverlay visible={!loadAttempted} overlayBlur={2} />
                        <Table striped highlightOnHover>
                            <thead>
                            <tr>
                                <th>S/N</th><th>Name</th><th>Model</th><th>Location</th><th>MAC</th><th>IP</th><th>Local URL</th><th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {(readers ?? []).map(reader => (
                                <tr key={reader.reader_uuid}>
                                    <td>{reader.serial_number}</td><td>{reader.name}</td><td>{reader.reader_model}</td>
                                    <td>{locations.find(e => e.location_uuid === reader.configuration?.location)?.name ?? 'Not set'}</td>
                                    <td>{reader.mac_address}</td><td>{reader.ip_address}</td><td>{reader.local_url}</td>
                                    <td className="tableActions">
                                        <div style={{display: 'flex', justifyContent: 'flex-end', gap: 15}}>
                                            <ActionIcon variant="light" color={theme.primaryColor} onClick={() => setShowDetail(reader.reader_uuid)}><IconId /></ActionIcon>
                                            <ActionIcon variant="light" color={theme.primaryColor} onClick={() => setChangeConfiguration([reader.reader_uuid, JSON.stringify(reader.configuration, null, 2)])}><IconSettings /></ActionIcon>
                                            <ActionIcon variant="light" color="red" onClick={() => delete_reader(reader.reader_uuid)}><IconTrash /></ActionIcon>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </Tabs.Panel>
                
                <Tabs.Panel value="impinj">
                    <HorizontalSeparator size="lg" text="Impinj SC Configuration Listing" color="primary" top={0} />
                    <ListTopActions
                        createText="Add A New Impinj SC Configuration"
                        onCreate={() => setShowImpinjCreate(true)}
                        extraMethods={<Menu.Dropdown>
                            <Menu.Label>Impinj SC Configuration List Actions</Menu.Label>
                            <Menu.Item onClick={fetchImpinj} icon={<IconRefresh size={14} />}>Update Data</Menu.Item>
                        </Menu.Dropdown>}
                    />
                    <div style={{ position: 'relative', maxWidth: '1800px', margin: 'auto' }}>
                        <Table striped highlightOnHover>
                            <thead>
                            <tr>
                                <th>Reader Name</th><th>Description</th><th>Antenna Port</th><th>Location</th><th>Latest Data</th><th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {(impinjScConfigs ?? []).map(impinjconfig => (
                                <tr key={impinjconfig.id}>
                                    <td>{impinjconfig.reader_name}</td><td>{impinjconfig.description}</td><td>{impinjconfig.antenna_port}</td>
                                    <td>{locations.find(e => e.location_uuid === impinjconfig.location)?.name ?? 'Not set'}</td>
                                    <td>{new Date(impinjconfig.latest_data_ts).toLocaleString('de-DE')}</td>
                                    <td className="tableActions">
                                        <div style={{display: 'flex', justifyContent: 'flex-end', gap: 15}}>
                                            <ActionIcon variant="light" color="red" onClick={() => delete_impinj_configuration(impinjconfig.id)}><IconTrash /></ActionIcon>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </Tabs.Panel>
                
                <Tabs.Panel value="zebra">
                    <HorizontalSeparator size="lg" text="Zebra ZIoTC Configuration Listing" color="primary" top={0} />
                    <ListTopActions
                        createText="Add A New ZIoTC Configuration"
                        onCreate={() => setShowZebraCreate(true)}
                        extraMethods={<Menu.Dropdown>
                            <Menu.Label>ZIoTC Configuration List Actions</Menu.Label>
                            <Menu.Item onClick={fetchImpinj} icon={<IconRefresh size={14} />}>Update Data</Menu.Item>
                        </Menu.Dropdown>}
                    />
                    <div style={{ position: 'relative', maxWidth: '1800px', margin: 'auto' }}>
                        <Table striped highlightOnHover>
                            <thead>
                            <tr>
                                <th>Reader Name</th><th>Description</th><th>Antenna Port</th><th>Location</th><th>Latest Data</th><th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {(zebraConfigs ?? []).map(config => (
                                <tr key={config.id}>
                                    <td>{config.reader_name}</td><td>{config.description}</td><td>{config.antenna_port}</td>
                                    <td>{locations.find(e => e.location_uuid === config.location)?.name ?? 'Not set'}</td>
                                    <td>{new Date(config.latest_data_ts).toLocaleString('de-DE')}</td>
                                    <td className="tableActions">
                                        <div style={{display: 'flex', justifyContent: 'flex-end', gap: 15}}>
                                            <ActionIcon variant="light" color="red" onClick={() => delete_zebra_configuration(config.id)}><IconTrash /></ActionIcon>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </Tabs.Panel>
            </Tabs>

            <CreateEditModal open={showCreate} close={() => setShowCreate(false)} modalTitle="Create A New Reader">
                <ReaderForm close={() => {setShowCreate(false); fetch();}} />
            </CreateEditModal>
            <CreateEditModal open={showImpinjCreate} close={() => setShowImpinjCreate(false)} modalTitle="Create A New Impinj SC Configuration">
                <ImpinjScConfigurationForm close={() => {setShowImpinjCreate(false); fetchImpinj();}} />
            </CreateEditModal>
            <CreateEditModal open={showZebraCreate} close={() => setShowZebraCreate(false)} modalTitle="Create A New Zebra ZIoTC Configuration">
                <ZebraZIoTCConfigurationForm close={() => {setShowZebraCreate(false); fetchZebra();}} />
            </CreateEditModal>
            <Modal opened={showDetail !== false} onClose={() => setShowDetail(false)} title="Reader Detail" size="80%">
                <div style={{overflowX: 'auto'}}><pre><code>
                    <div dangerouslySetInnerHTML={{__html: syntaxHighlightJSON(JSON.stringify(readers.find(e => e.reader_uuid === showDetail) ?? '{}', null, 4))}} />
                </code></pre></div>
            </Modal>
            <Modal opened={changeConfiguration[0] !== false} onClose={() => setChangeConfiguration([false, ""])} title="Change Reader Configuration" size="80%">
                <JsonInput
                    autosize
                    minRows={5}
                    value={changeConfiguration[1]}
                    onChange={e => setChangeConfiguration(draft => {draft[1] = e})}
                    placeholder="New Configuration"
                    label="New Reader Configuration"
                />
                <Button onClick={change_reader_configuration}>Submit</Button>
            </Modal>
        </div>
        );
}
export default AssetList;
