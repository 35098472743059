import React from 'react';
import {TimescaleChartControl} from "../TimescaleChartControl";
import {createStyles, LoadingOverlay} from "@mantine/core";
import {RustyRadeaChart} from "../RustyRadeaChart";
import dayjs from "dayjs";
import {useLocations} from "../../hooks/RustyRadeaHooks";
import {useImmer} from "use-immer";
import {useAssets} from "../../hooks/useAssets";
import {humanizeSeconds} from "../../config/method";

const useStyles = createStyles(theme => ({
    chartContainer: {
        paddingTop: 10
    }
}));

/**
* TagHospitalityGraph component produces an inventory graph utilizing apache echarts using the provided filters either
* for product per location, location or product basis
* @param {Object} props.location                    Location UUID to use in the call to inventory data
* @param {String} props.tag                         Tag ID to use in the call to inventory data
* @param {String} props.title                       Title of the graph shown above the graph
* @param {String} props.defaultGranularity          Initial granularity of the buckets in data fetched
* @param {String} props.defaultRange                Initial timerange of the data fetched
*
* @returns {React.ReactElement} The TagHospitalityGraph component
*/
export const TagHospitalityGraph = props => {
    const {classes} = useStyles();
    const {hospitality: assetHospitality, fetch, loadAttempted} = useAssets();
    const {hospitality, getHospitality, hospitalityLoadAttempted} = useLocations();
    const [timescaleRanges, setTimescaleRanges] = useImmer({granularity: null, timerange: null});

    React.useEffect(() => {
        if(timescaleRanges.granularity && props.location) getHospitality(props.location, {
            bucket_period: timescaleRanges.granularity.time,
            bucket_size: timescaleRanges.granularity.count,
            interval_count: timescaleRanges.timerange
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.location, timescaleRanges]);
    React.useEffect(() => {
        if(timescaleRanges.granularity && props.tag) fetch({
            tag: props.tag,
            bucket_period: timescaleRanges.granularity.time,
            bucket_size: timescaleRanges.granularity.count,
            interval_count: timescaleRanges.timerange
        }, 'hospitality');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tag, timescaleRanges]);

    return (
        <div>
            <div style={{position: 'relative'}}>
                <LoadingOverlay visible={(props.location && !hospitalityLoadAttempted) || (props.tag && !loadAttempted)} overlayBlur={2} />
                <TimescaleChartControl
                    availableTypes={props.availableTypes}
                    defaultType={props.defaultType}
                    defaultRange={props.defaultRange}
                    defaultGranularity={props.defaultGranularity}
                    onConfirm={(_, granularity, timerange) => setTimescaleRanges(draft => {draft.granularity = granularity; draft.timerange = timerange})}
                />
                <div className={classes.chartContainer}>
                    <RustyRadeaChart
                        option={{
                        title: {
                            text: props.title ?? ''
                        },
                            tooltip: {
                                trigger: 'axis',
                                    axisPointer: {
                                    animation: false
                                    },
                                    formatter: function (params) {
                                        return `<div>${dayjs(params[0].value[0]).format('DD.MM.YYYY HH:mm')}</div>` + params.map(param => (`
                                            <div>
                                                ${param.marker} ${param.seriesId.slice(0, -1)}: ${humanizeSeconds(param.value[1], true)}
                                            </div>
                                        `)).join('')
                                    },
                            },
                            legend: {
                                data: ['Average Time Spent', 'Median Time Spent']
                            },
                            xAxis: {
                                type: 'time',
                                    splitLine: {
                                    show: false
                                }
                            },
                            yAxis: {
                                type: 'value',
                                boundaryGap: [0, '30%'],
                                axisLabel: {
                                    formatter: value => value < 60 ? value : `${dayjs.duration(value, "seconds").humanize()}`
                                }
                            },
                            series: [
                                {
                                    name: 'Average Time Spent',
                                    data: (((props.location && hospitality) || (props.tag && assetHospitality)) ?? []).map(x => ({
                                        name: x.bucket,
                                        value: [
                                            dayjs(x.bucket).format('YYYY/MM/DD HH:mm'),
                                            x.avg_hospitality
                                        ]
                                    })),
                                    type: 'line'
                                },
                                {
                                    name: 'Median Time Spent',
                                    data: (((props.location && hospitality) || (props.tag && assetHospitality)) ?? []).map(x => ({
                                        name: x.bucket,
                                        value: [
                                            dayjs(x.bucket).format('YYYY/MM/DD HH:mm'),
                                            x.med_hospitality
                                        ]
                                    })),
                                    type: 'line'
                                }
                            ]
                        }}
                        notMerge={true}
                        lazyUpdate={true}
                    />
                </div>
            </div>
        </div>
    )
}