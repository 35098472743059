import PropTypes from 'prop-types';
import {Text, createStyles, useMantineTheme} from "@mantine/core";

const useStyles = createStyles((theme) => ({
    separatorWrapper: {
        borderBottom: 'thin solid',
        borderColor: theme.colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.13)' : 'rgba(0, 0, 0, 0.5)'
    },
    separatorText: {
        letterSpacing: '2px',
        fontWeight: 'light',
        fontSize: '0.85rem',
        paddingLeft: 25
    },
    smSeparatorText: {
        fontSize: theme.fontSizes.sm
    },
    mdSeparatorText: {
        fontSize: theme.fontSizes.xl
    },
    lgSeparatorText: {
        fontSize: 28
    }
}));

/**
 * HorizontalSeparator component to visually separate horizontal blocks of content.
 * @param {String | node}  props.text                      The string to show on top of the horizontal line separator
 * @param {boolean} [props.alignRight=false]        Whether to align the text right (by default aligns left)
 * @param {String} [props.size=small]               Size of the text on the separator
 * @param {String} [props.color=primary]            Color of the separator text
 * @param {String | number} [props.top=7]           Top margin applied to the element (either number in pixels or valid CSS string)
 *
 * @returns {React.ReactElement} The HorizontalSeparator component
*/
export const HorizontalSeparator = props => {
    const { classes, cx } = useStyles();
    const theme = useMantineTheme();

    let styleObj = {
        textAlign: props.alignRight ? 'right' : 'left',
        color: props.color === 'primary' ? theme.colorScheme === 'dark' ? theme.colors[theme.primaryColor][2] : theme.colors[theme.primaryColor][9] :
               props.color === 'dimmedPrimary' ? theme.colorScheme === 'dark' ? theme.colors[theme.primaryColor][2] + '91' : theme.colors[theme.primaryColor][9] + '91' :
               props.color === 'dimmed' ? theme.colorScheme === 'dark' ? theme.colors.gray[4] + '91' : theme.colors.gray[9] + '91' :
               props.color === 'success' ? theme.colorScheme === 'dark' ? theme.colors.green[4] : theme.colors.green[9] :
               props.color === 'warning' ? theme.colorScheme === 'dark' ? theme.colors.yellow[4] : theme.colors.yellow[9] :
               props.color === 'error' ? theme.colorScheme === 'dark' ? theme.colors.red[4] : theme.colors.red[9] : 'inherit'
    };
    if(props.top) styleObj.marginTop = props.top;

    return (
        <div className={classes.separatorWrapper}>
            <Text
                className={cx(classes.separatorText, classes[props.size + 'SeparatorText'])}
                color="dimmed"
                style={styleObj}
            >
                {props.text}
            </Text>
        </div>
    )
};
/**
 * Property validator for HorizontalSeparator component
*/
HorizontalSeparator.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    alignRight: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    color: PropTypes.oneOf(['primary', 'dimmed', 'dimmedPrimary', 'default', 'success', 'warning', 'error']),
    top: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};
/**
 * Default property declarator for HorizontalSeparator component
*/
HorizontalSeparator.defaultProps = {
    alignRight: false,
    size: 'md',
    color: 'primary',
    top: 7
};
