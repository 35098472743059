import React from 'react';
import {Button, createStyles, Select, TextInput} from "@mantine/core";
import {useLocations} from "../../hooks/RustyRadeaHooks";
import {useImmer} from "use-immer";
import ENDPOINTS from "../../config/endpoint";
import {showNotification} from "@mantine/notifications";

import AppContext from "../../config/AppContext";

const useStyles = createStyles(theme => ({
    formContainer: {
        maxWidth: 1800,
        margin: 'auto'
    },
    formInput: {
        marginTop: 7
    },
    submitButton: {
        marginTop: 30
    }
}));

const LocationForm = props => {
    const config = React.useContext(AppContext);

    const { classes } = useStyles();
    const { groups } = useLocations();

    const [formValues, setFormValues] = useImmer({
        name: props.edit?.name ?? '',
        role: props.edit?.role ?? '',
        location_group: props.edit?.location_group ?? null
    })

    const submit = () => {
        if(!props.edit) {
            fetch(ENDPOINTS('locations'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + config.accessToken
                },
                body: JSON.stringify(formValues)
            }).then(response => {
                if(!response.ok) failNotification()
                else {
                    successNotification();
                    props.close();
                }
            }).catch(error => {
                errorNotification();
                console.warn(error);
            });
        } else {
            if(props.edit?.location_uuid) {
                fetch(ENDPOINTS('location_detail', {location_uuid: props.edit.location_uuid}), {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + config.accessToken
                    },
                    body: JSON.stringify(formValues)
                }).then(response => {
                    if(!response.ok) failNotification()
                    else {
                        successNotification();
                        props.close();
                    }
                }).catch(error => {
                    errorNotification();
                    console.warn(error);
                });
            } else console.warn('Improperly configured location edit form')
        }
    };
    const failNotification = () => showNotification({
        title: 'Failed to add/edit location',
        message: 'Adding location failed. Please check your inputs and try again',
        color: 'red'
    });
    const successNotification = () => showNotification({
        title: 'Added/Edited location',
        color: 'green'
    });
    const errorNotification = () => showNotification({
        title: 'Something went wrong in the request',
        message: 'Please wait a while and try again. If the problem persists, please contact support',
        color: 'red'
    });

    return (
        <div className={classes.formContainer}>
            <TextInput
                value={formValues.name}
                onChange={e => setFormValues(draft => {draft.name = e.target.value})}
                placeholder="Location Name"
                label="Location Name"
                withAsterisk={!props.edit}
                className={classes.formInput}
            />
            <Select
                value={formValues.role}
                onChange={value => setFormValues(draft => {draft.role = value})}
                placeholder="Location Role"
                label="Location Role"
                data={[
                    {value: 'concrete', label: 'Concrete'},
                    {value: 'removal', label: 'Removal'},
                    {value: 'unassigned', label: 'Unassigned'},
                    {value: 'sales', label: 'Sales'},
                ]}
                description="Role of the location determines how the data is processed and separated"
                withAsterisk={!props.edit}
                className={classes.formInput}
            />
            <Select
                value={formValues.location_group}
                onChange={value => setFormValues(draft => {draft.location_group = value})}
                placeholder="Location Group"
                label="Location Group"
                data={groups.map(group => ({label: group.name, value: group.id, group: groups.find(e => e.id === group.parent)?.name ?? 'Root'}))}
                clearable
                description="The group this location is a part of"
                className={classes.formInput}
            />
            <div className={classes.submitButton}>
                <Button radius="xs" fullWidth onClick={submit}>Submit</Button>
            </div>
        </div>
    )
};
export default LocationForm;