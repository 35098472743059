import React from 'react';
import {Button, createStyles, Select, TextInput} from "@mantine/core";
import {useImmer} from "use-immer";
import ENDPOINTS from "../../config/endpoint";
import {showNotification} from "@mantine/notifications";
import {useLocations} from "../../hooks/RustyRadeaHooks";

import AppContext from "../../config/AppContext";

const useStyles = createStyles(theme => ({
    formContainer: {
        maxWidth: 1800,
        margin: 'auto'
    },
    formInput: {
        marginTop: 7
    },
    submitButton: {
        marginTop: 30
    }
}));

const ReaderForm = props => {
    const config = React.useContext(AppContext);

    const { classes } = useStyles();
    const { locations, groups } = useLocations();

    const [formValues, setFormValues] = useImmer({
        serial_number: props.edit?.serial_number ?? (props.defaultSerial ?? ''),
        name: props.edit?.name ?? (props.defaultName ?? ''),
        reader_model: props.edit?.model ?? (props.defaultModel ?? ''),
        in_use: props.edit?.in_use ?? (props.defaultInUse ?? true),
        mac_address: props.edit?.mac_address ?? (props.defaultMacAddress ?? null),
        ip_address: props.edit?.ip_address ?? (props.defaultIpAddress ?? null),
        local_url: props.edit?.local_url ?? (props.defaultURL ?? null),
        configuration: props.edit?.configuration ?? (props.defaultConfiguration ?? {"format": "trace|goodsin", "location": null}),
        
        reader_password: ''
    });
    
    const submit = () => {
        if(!props.edit) {
            fetch(ENDPOINTS('reader_list'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + config.accessToken
                },
                body: JSON.stringify(formValues)
            }).then(response => {
                if(!response.ok) failNotification()
                else {
                    successNotification();
                    props.close();
                }
            }).catch(error => {
                errorNotification();
                console.warn(error);
            });
        } else {
            if(props.edit?.reader_uuid) {
                fetch(ENDPOINTS('reader_list', {reader_uuid: props.edit.reader_uuid}), {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + config.accessToken
                    },
                    body: JSON.stringify(formValues)
                }).then(response => {
                    if(!response.ok) failNotification()
                    else {
                        successNotification();
                        props.close();
                    }
                }).catch(error => {
                    errorNotification();
                    console.warn(error);
                });
            } else console.warn('Improperly configured reader edit form')
        }
    };
    const failNotification = () => showNotification({
        title: 'Failed to add/edit reader',
        message: 'Adding reader failed. Please check your inputs and try again',
        color: 'red'
    });
    const successNotification = () => showNotification({
        title: 'Added/Edited reader',
        color: 'green'
    });
    const errorNotification = () => showNotification({
        title: 'Something went wrong in the request',
        message: 'Please wait a while and try again. If the problem persists, please contact support',
        color: 'red'
    });

    return (
        <div className={classes.formContainer}>
            <TextInput
                value={formValues.serial_number}
                onChange={e => setFormValues(draft => {draft.serial_number = e.target.value})}
                placeholder="Reader serial number"
                label="Reader serial number"
                description="The serial number of the reader found in the reader software and the reader box"
                withAsterisk={!props.edit}
                className={classes.formInput}
            />
            <TextInput
                value={formValues.name}
                onChange={e => setFormValues(draft => {draft.name = e.target.value})}
                placeholder="Reader Name"
                label="Reader Name"
                description="Friendly name for the reader to distuinquish it from other readers"
                className={classes.formInput}
            />
            <TextInput
                value={formValues.reader_model}
                onChange={e => setFormValues(draft => {draft.reader_model = e.target.value})}
                placeholder="Reader Model"
                label="Reader Model"
                className={classes.formInput}
            />
            <TextInput
                value={formValues.mac_address}
                onChange={e => setFormValues(draft => {draft.mac_address = e.target.value})}
                placeholder="Reader MAC address"
                label="Reader MAC address"
                className={classes.formInput}
            />
            <TextInput
                value={formValues.ip_address}
                onChange={e => setFormValues(draft => {draft.ip_address = e.target.value})}
                placeholder="Reader IP address"
                label="Reader IP address"
                className={classes.formInput}
            />
            <TextInput
                value={formValues.local_url}
                onChange={e => setFormValues(draft => {draft.local_url = e.target.value})}
                placeholder="Reader local network URL"
                label="Reader local network URL"
                className={classes.formInput}
            />
            <Select
                value={formValues.configuration?.location ?? null}
                onChange={value => setFormValues(draft => {draft.configuration.location = value})}
                placeholder="Location of reading"
                label="Location of reading"
                data={locations.map(location => ({label: location.name, value: location.location_uuid, group: groups.find(e => e.id === location.location_group)?.name ?? 'Root'}))}
                description="Source this reader sets into the processlists"
                className={classes.formInput}
            />
            <TextInput
                value={formValues.configuration?.format ?? null}
                onChange={e => setFormValues(draft => {draft.configuration.format = e.target.value})}
                placeholder="Format of reader processlists"
                label="Format of reader processlists"
                className={classes.formInput}
            />
            <TextInput
                value={formValues.reader_password ?? null}
                onChange={e => setFormValues(draft => {draft.reader_password = e.target.value})}
                placeholder="Password of the reader"
                label="Password of the reader"
                description="Password used to connect to the reader and set it up remotely to send data to the data consolidation platform"
                className={classes.formInput}
            />
            <div className={classes.submitButton}>
                <Button radius="xs" fullWidth onClick={submit}>Submit</Button>
            </div>
        </div>
        )
};
export default ReaderForm;