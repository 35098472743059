import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {
    MantineProvider,
    ColorSchemeProvider,
} from '@mantine/core';
import {NotificationsProvider} from '@mantine/notifications';
import {ModalsProvider} from '@mantine/modals';
import {useImmer} from "use-immer";

import App from './App';
import Loading from "./general/Loading";
import Login from "./general/Login";
import AppContext from "./config/AppContext";

import ENDPOINTS from "./config/endpoint";

const Provider = (props) => {
    let themeObject = window.localStorage.getItem('idcat_configuration');
    if(themeObject) themeObject = JSON.parse(themeObject);
    const [colorScheme, setColorScheme] = React.useState(themeObject?.colorScheme ? themeObject.colorScheme : 'dark');
    const [primaryColor, setPrimaryColor] = React.useState(themeObject?.primaryColor ? themeObject.primaryColor : 'nordic');

    const [loading, setLoading] = React.useState(true);
    const [loggedIn, setLoggedIn] = React.useState(false);

    const [config, setConfig] = useImmer([]);
    const [locations, setLocations] = React.useState([]);
    const [accessToken, setAccessToken] = React.useState(window.localStorage.getItem('idcat_access_token') ?? 'a');

    const toggleColorScheme = () => setColorScheme(colorScheme === 'dark' ? 'light' : 'dark');

    React.useEffect(() => {
        get_configuration();
        }, [accessToken]);
    const get_configuration = async () => {
        let configreq = await fetch(ENDPOINTS('configlist'), {
            headers: {'Authorization': 'Bearer ' + accessToken}
        }).catch(e => console.log(e));
        if(configreq?.ok) {
            setLoggedIn(true);
            let configdata = await configreq.json().catch(e => console.log(e));
            window.localStorage.setItem('idcat_access_token', accessToken);
            if(configdata) setConfig(configdata);
            setLoading(false);
        } else {
            if(configreq?.status === 401) {
                setLoading(false);
                setLoggedIn(false);
            }
        }
    };
    const attemptLogin = (at) => {
        setAccessToken(at);
    }

    return (
        <BrowserRouter>
            <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
                <MantineProvider
                    withNormalizeCSS
                    withGlobalStyles
                    theme={{
                    colorScheme: colorScheme,
                        primaryColor: primaryColor,
                        colors: {
                        'nordic': ["#fff7e7", "#FFD687", "#FFC85C", "#FFBB36", "#FFAE1A", "#F99D1C", "#FF9600", "#FF8900", "#E87B00", "#D16F00", '#110a01'],
                            'brady': ["#e5f4ff", "#6BC2FF", "#2CA8FF", "#0076FF", "#006BFE", "#0061E7", "#0058D2", "#0050BF", "#0049AA", "#024387", '#020515'],
                            'nature': ['#ebfbee', '#d3f9d8', '#b2f2bb', '#8ce99a', '#69db7c', '#51cf66', '#40c057', '#37b24d', '#2f9e44', '#2b8a3e', '#010f04'],
                            'princess': ['#fff0f6', '#ffdeeb', '#fcc2d7', '#faa2c1', '#f783ac', '#f06595', '#e64980', '#d6336c', '#c2255c', '#a61e4d', '#130007'],
                            'prince': ["#F9F9FE", "#D7D4F9", "#B4AEF9", "#8F85FF", "#786DF4", "#665BE6", "#584DD7", "#4D42C8", "#4940B2", "#49429B", "#0D0B1B"]
                        },
                        breakpoints: {
                        'md': 930
                        },
                        globalStyles: (theme) => ({
                            // Mantine overrides
                            'div.mantine-Menu-dropdown': {
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : '#ffffff'
                            },
                            'table.mantine-Table-root[data-striped] thead tr': {
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] + '00' : '#ffffff'
                            },
                            'table.mantine-Table-root[data-striped] tbody tr:nth-of-type(2n+1)': {
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : '#ffffff'
                            },
                            'table.mantine-Table-root[data-striped] tbody tr:nth-of-type(2n+1):hover': {
                                backgroundColor: theme.colorScheme === 'dark' ? '#0d0d0d' : theme.colors.gray[0]
                            },
                            'table.mantine-Table-root[data-striped] tbody tr:hover': {
                                backgroundColor: theme.colorScheme === 'dark' ? '#000000' : theme.colors.gray[0]
                            },

                            // Custom components
                            '.tableActions': {
                                width: 50
                            },
                            '.jsonKey': {
                                color: theme.colorScheme === 'dark' ? theme.colors.orange[3] : theme.colors.orange[8]
                            },
                            '.jsonString': {
                                color: theme.colorScheme === 'dark' ? theme.colors.green[3] : theme.colors.green[8]
                            },
                            '.jsonNull': {
                                color: theme.colorScheme === 'dark' ? theme.colors.cyan[3] : theme.colors.cyan[8]
                            },
                            '.jsonNumber': {
                                color: theme.colorScheme === 'dark' ? theme.colors.blue[3] : theme.colors.blue[8]
                            },
                        })
                    }}
                >
                    <ModalsProvider>
                        <NotificationsProvider>
                            <AppContext.Provider value={{accessToken: accessToken, locations: locations, setLocations: setLocations, config: config, setConfig: setConfig}}>
                                {loading && <Loading />}
                                {!loading && !loggedIn && <Login attemptLogin={attemptLogin} />}
                                {!loading && loggedIn && <App primaryColor={primaryColor} setPrimaryColor={setPrimaryColor}/>}
                            </AppContext.Provider>
                        </NotificationsProvider>
                    </ModalsProvider>
                </MantineProvider
>
            </ColorSchemeProvider>
        </BrowserRouter>
    );
}
export default Provider;
