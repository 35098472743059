import React from 'react';
import {Link, useParams} from "react-router-dom";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
    DetailViewHeader,
    StatsCard,
    HorizontalSeparator,
    DetailViewTabContainer,
    TagHospitalityGraph, CreateEditModal
} from "../../components/RustyRadeaComponents";
import {createStyles, Menu, SimpleGrid, Tabs, Text, useMantineTheme} from "@mantine/core";
import {
    IconBraces, IconInfoCircle,
    IconLocation,
    IconPencil,
    IconRefresh,
    IconTrash
} from "@tabler/icons-react";
import {useViewportSize} from "@mantine/hooks";
import {useAssets, useLocations} from "../../hooks/RustyRadeaHooks";
import {syntaxHighlightJSON} from "../../config/method";
import AssetHistory from "./AssetHistory";
import AssetForm from "./AssetForm";

const useStyles = createStyles(theme => ({
    pageWrapper: {
        maxWidth: 1800,
        margin: 'auto'
    },
    dataWrapper: {
        paddingTop: 15
    },
    dataBlockWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'

    },
    dataBlock: {
        width: '50%',
        minWidth: 450,
        boxSizing: 'border-box',
        border: 'thin solid',
        borderColor: theme.colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.13)' : 'rgba(0, 0, 0, 0.5)',
        padding: 10,

        ['@media(max-width: ' + theme.breakpoints.md + 'px)']: {
            '&': {
                width: '100%'
            }
        }
    },
    blockHeaderText: {
        letterSpacing: 1
    },
    chartWrapper: {
        marginTop: 15,
        backgroundColor: theme.colorScheme === 'dark' ? '#000' : '#fff'
    }
}));

const AssetDetail = props => {
    dayjs.extend(duration);
    dayjs.extend(relativeTime);
    const theme = useMantineTheme();
    const {epc} = useParams();
    const {assets, hospitality, fetch} = useAssets();
    const {locations} = useLocations();
    const {classes} = useStyles();
    const {width} = useViewportSize();

    const [assetData, setAssetData] = React.useState({});
    const [showEdit, setShowEdit] = React.useState(false);

    React.useEffect(() => {
        if(epc) fetch({code: epc});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [epc]);
    React.useEffect(() => {
        setAssetData(assets.find(e => e.epc_code === epc) ?? {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assets]);
    React.useEffect(() => {
        if(assetData.id) fetch({
            tag: assetData.id,
            bucket_period: 'day',
            bucket_size: 7,
            bucket_interval: 14
        }, 'hospitality');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetData]);

    return(
            <div className={classes.pageWrapper}>
                <DetailViewHeader
                    type={'Asset'}
                    headerText={assetData.name ?? <Text color={theme.primaryColor} style={{opacity: '0.7'}}>{width > theme.breakpoints.md ? 'Unnamed Product' : 'EPC: ' + assetData.epc_code}</Text>}
                    rightText={assetData.epc_code}
                    actions={<>
                    <Menu.Label>Data</Menu.Label>
                    <Menu.Item icon={<IconRefresh size={18}/>} color="cyan">Update Data</Menu.Item>
                    <Menu.Item icon={<IconPencil size={18}/>} color={theme.colorScheme === 'dark' ? "orange.3" : "orange.6Dt"} onClick={() => setShowEdit(true)}>Edit</Menu.Item>

                    <Menu.Divider/>

                    <Menu.Label>Danger Zone</Menu.Label>
                    <Menu.Item icon={<IconTrash size={18}/>} color="red">Delete</Menu.Item>
                    </>}
                />
                <div className={classes.dataWrapper}>
                    <Link to={`/locations/${assetData.current_location}?from=tag&id=${assetData.epc_code}`} style={{textDecoration: 'none'}}>
                        <StatsCard
                            title="Location"
                            formattedValue={locations.find(e => e.location_uuid === assetData.current_location)?.name ?? 'Unknown location'}
                            showDiff={false}
                            interval="Click to view location details"
                        />
                    </Link>
                    <Link to={`/products/${assetData.product}?from=tag&id=${assetData.epc_code}`} style={{textDecoration: 'none'}}>
                        <StatsCard
                            title="Product"
                            formattedValue={assetData.product ?? 'Unassigned'}
                            showDiff={false}
                            interval="Click to view product details"
                        />
                    </Link>
                </div>
                <div className={classes.dataWrapper}>
                    <div className={classes.dataBlockWrapper}>
                        <div className={classes.dataBlock}>
                            <Text size="sm" color="dimmed" className={classes.blockHeaderText}>Time Spent In Locations</Text>
                            <SimpleGrid cols={2}>
                                <StatsCard
                                    title="Average Time Spent"
                                    formattedValue={hospitality[0] ? dayjs.duration(hospitality[0].avg_hospitality, "seconds").humanize() : 'N/A'}
                                    value={hospitality[0]?.avg_hospitality ?? 0}
                                    prevValue={(hospitality[1] || hospitality[0])?.avg_hospitality ?? 0}
                                    interval="last week"
                                />
                                <StatsCard
                                    title="Median Time Spent"
                                    formattedValue={hospitality[0] ? dayjs.duration(hospitality[0].med_hospitality, "seconds").humanize() : 'N/A'}
                                    value={hospitality[0]?.med_hospitality ?? 0}
                                    prevValue={(hospitality[1] || hospitality[0])?.med_hospitality ?? 0}
                                    interval="last week"
                                />
                            </SimpleGrid>
                            <div className={classes.chartWrapper}>
                                <TagHospitalityGraph tag={assetData.id} availableTypes={['Timescale']} defaultType="Timescale" />
                            </div>
                        </div>
                        <div className={classes.dataBlock}>
                            <Text size="sm" color="dimmed" className={classes.blockHeaderText}>Tag Metadata</Text>
                            <Tabs defaultValue="raw" orientation={'horizontal'}>
                                <Tabs.List>
                                    <Tabs.Tab value="parsed" icon={<IconInfoCircle size={16}/>}>Parsed</Tabs.Tab>
                                    <Tabs.Tab value="raw" icon={<IconBraces size={16}/>}>Raw</Tabs.Tab>
                                </Tabs.List>

                                <Tabs.Panel value="parsed">
                                    <DetailViewTabContainer>
                                        Metadata view
                                    </DetailViewTabContainer>
                                </Tabs.Panel>
                                <Tabs.Panel value="raw">
                                    <DetailViewTabContainer>
                                        <Text size="sm"><pre><code><div dangerouslySetInnerHTML={{__html: syntaxHighlightJSON(JSON.stringify(assetData.metadata, null, 4))}} /></code></pre></Text>
                                    </DetailViewTabContainer>
                                </Tabs.Panel>
                            </Tabs>
                        </div>
                    </div>
                    <HorizontalSeparator text="Related Data" top={15}/>
                    <Tabs defaultValue="history" orientation={width > theme.breakpoints.md ? 'vertical' : 'horizontal'}>
                        <Tabs.List>
                            <Tabs.Tab value="history" icon={<IconLocation size={16}/>}>Location History</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="history">
                            <AssetHistory tag={assetData.id} />
                        </Tabs.Panel>
                    </Tabs>
                </div>
                <CreateEditModal open={showEdit} close={() => setShowEdit(false)} modalTitle="Edit Location">
                    <AssetForm edit={assetData} close={() => {setShowEdit(false); fetch({code: epc});}} />
                </CreateEditModal>
            </div>
            )
};
export default AssetDetail;