import {Route, Routes} from "react-router-dom";
import AssetList from "./AssetList";
import AssetDetail from "./AssetDetail";

const AssetMaster = props => {
    return (
            <Routes>
                <Route path="/:epc" element={<AssetDetail />} />
                <Route index element={<AssetList />} />
            </Routes>
            )
};
export default AssetMaster;
