import {Card, createStyles} from "@mantine/core";
import IdcatLogo from "./RustyRadeaLogo";

const useStyles = createStyles((theme) => ({
    cardWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 60px)',
        width: '100%',
        padding: "0 30px"
    },
    mainCard: {
        width: 500,
        maxWidth: '90%',
        background: theme.colorScheme === 'dark' ? theme.colors.dark[9] : '#fff',
        borderRadius: 8,
        padding: 16
    },
    cardHeader: {
        fontSize: '1.6rem',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 16
    },
    cardBody: {
        textAlign: 'center',
        width: '90%',
        margin: 'auto'
    },
    locationCode: {
        minWidth: '30%',
        textAlign: 'center',
        fontSize: '0.8rem',
        marginTop: 16,
        marginBottom: 20
    },
    homeButton: {
        alignSelf: 'flex-end',
        marginRight: 30,
        marginTop: 30,
        marginBottom: 15
    },

    highligher: {
        color: theme.colorScheme === 'dark' ? theme.colors[theme.primaryColor][2] : theme.colors[theme.primaryColor][8]
    }
}));

const Loading = _ => {
    const { classes } = useStyles();

    return (
        <div className={classes.cardWrapper}>
            <Card shadow="lg" className={classes.mainCard}>
                <div className="centeredContent">
                    <IdcatLogo />
                    <header className={classes.cardHeader}>Application is loading...</header>
                    <p className={classes.cardBody}>
                        If the page does not load, please contact administrator
                    </p>
                </div>
            </Card>
        </div>
    )
};
export default Loading;